import React, {ReactElement, useContext} from 'react';
import {ArrowTopRightOnSquareIcon, CheckIcon} from "@heroicons/react/24/solid";
import {Button} from "shared/src/components/ui";
import {AdminContext} from "shared/src/contexts/AdminContext";
import AdminOnly from "../AdminOnly";
import TippyContent from "../../../../shared/src/components/TippyContent";
import Tippy from "@tippyjs/react";

type EasyVoteButtonProps = {
  text?: string,
  className?: string,
  eventToSend: string,
  disabled?: boolean,
}

export default function EasyVoteButton({eventToSend, className, text, disabled = false}: EasyVoteButtonProps) {
  const {isAdmin} = useContext(AdminContext);

  function sendEvent() {
    window.chrome.webview.postMessage(eventToSend);
  }

  if (!isAdmin) return null;

  const displayText = text || 'EasyVote';

  return (
    <AdminOnly>
      <Tippy content={(
        <TippyContent classes='max-w-48'>
          { disabled ? 'This resource cannot be viewed' : 'View this resource in EasyVote'}
        </TippyContent>
      )}>
        <div>
          <Button variant='secondary' className={className} disabled={disabled} title={`${displayText} in EasyVote`} onClick={sendEvent}>
            <CheckIcon className="h-4 w-4 text-ev-red -mr-1" />
            <div>{displayText}</div>
            <ArrowTopRightOnSquareIcon className="h-4 w-4 text-gray-900" />
          </Button>
        </div>
      </Tippy>
    </AdminOnly>
  );
}

export function EasyAdminButton({onClick, label, className, icon}: {icon: ReactElement, label: string, className?: string, onClick: () => void}) {
  return (
    <AdminOnly>
      <Button className={`px-1 py-0.5 ${className}`} onClick={onClick}>
        <CheckIcon className="size-4 -mr-1" />
        <div>{label}</div>
        {icon}
      </Button>
    </AdminOnly>
  )
}
