import React, { useState } from "react";
import { useLocation } from "wouter";
import { login } from "../../fetchers";
import Login from "shared/src/components/Login";
import handleZumoAuthFactory from "../../utils/auth";
import * as Sentry from 'shared/src/setupSentry';

const handleZumoAuth = handleZumoAuthFactory(window.localStorage);

export default function EasyOrderLogin() {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [, navigate] = useLocation();

  function loginHandler(username: string, password: string) {
    return login(username, password).then(([success, data]) => {
      if (!success) {
        return setErrorMessage(data);
      }

      Sentry.identifyLoggedInUser(
        data.userInfo.id,
        data.userInfo.userId,
        data.userInfo.emailAddress,
        data.customerInfo.id,
        data.customerInfo.customerName,
        data.customerInfo.state
      );

      handleZumoAuth(data.zumoToken);
      navigate('/store');

      return [success, data];
    })
    .catch((_err) => {
      setErrorMessage('We weren\'t able to contact the server. Please try again.');
    });
  }

  return (
    <Login errorMessage={errorMessage} login={loginHandler} />
  );
}
