import {useMemo, useState} from "react";
import {OrderConfig} from "../types/User";
import {getCountyConfigs} from "../fetchers";
import {Order} from "../types/Order";

export default function useCountyConfig(order: Order | undefined) {
  const [countyConfig, setCountyConfig] = useState<OrderConfig>();

  useMemo(() => {
    if (!order || !!countyConfig) return;

    getCountyConfigs()
      .then(({data}) => {
        const config = data.find((c: OrderConfig) => c.keyCustomerId === order.keyCustomerId);
        setCountyConfig(config);
      });
  }, [order]);

  return countyConfig;
}
