import React, {ReactNode, useEffect, useState} from 'react';
import handleZumoAuthFactory from "../../utils/auth";
import {useDispatch, useSelector} from "react-redux";
import {getConsumables} from "../../fetchers";
import Cache, {imageCacheKey} from "../../utils/cache";
import {AppState} from "../../reducers";
import DisabledAppCoverall from "../DisabledAppCoverall";
import useNativeMessageListenerEffect from "shared/src/hooks/useNativeMessageListenerEffect";
import {classNames} from "shared/src/utils/classNames";
import {Flexor, Loading} from "shared/src/components";
import useFindFeature from "../../hooks/useFindFeature";
import useHasFeature from "../../hooks/useHasFeature";
import Feature from "../../types/Feature";
import * as Sentry from 'shared/src/setupSentry';
import {Button} from "shared/src/components/ui";
import { toast, ToastOptions } from "react-toastify";

const handleZumoAuth = handleZumoAuthFactory(window.sessionStorage);

type AdminAppProps = {
  children: ReactNode,
  checkFeatureEnabled?: Feature,
  featureDisabledMessage?: string,
  fullscreen?: boolean,
};

export const FEATURES_IDS = {
  EASY_ORDER: 102,
  MODERN_POLLWORKER: 104,
  MODERN_REPORTS: 103,
  MODERN_CAMPAIGN_FINANCE: 105,
  MODERN_INVENTORY: 106,
  MODERN_POLLWORKER_SCHEDULER: 107,
} as const;

export const EASYORDER_STORE_URI = '/app/store';

export function RequiresFeature({children, checkFeatureEnabled}: Partial<AdminAppProps>) {
  const isEnabled = useHasFeature(checkFeatureEnabled);

  if (!isEnabled || !children) return null;

  return (
    <>
      {children}
    </>
  );
}

const storeURL = window.location.protocol + '//' + window.location.host + EASYORDER_STORE_URI;

export default function AdminApp({
  fullscreen = false,
  checkFeatureEnabled,
  featureDisabledMessage,
  children
}: AdminAppProps) {
  const user = useSelector((state: AppState) => state.user);
  const [disabledMessage, setDisabledMessage] = useState<string>();
  const [showApp, setShowApp] = useState<boolean>(false);
  const targetFeature = useFindFeature(checkFeatureEnabled);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.account) return;

    checkIfEasyOrderIsEnabled();
    checkIfFeatureIsEnabled();
    setShowApp(true);

    if (user.user) {
      Sentry.identifyLoggedInUser(
        user.user.id,
        user.user.userId,
        user.user.emailAddress,
        user.account.id,
        user.account.customerName,
        user.account.state
      );
    }
  }, [user]);

  useEffect(() => {
    // This will prompt the native app to respond with the System:Auth event, giving us the JWT (zumo) token
    // this is to ensure that if a Zumo token isn't present in the query string, then the native app will
    // respond with to auth token.
    //
    // The native app responds to all messages, first, with the most up-to-date zumo token
    window.chrome.webview.postMessage(`System:Init:WillAckWithAuth`);
  }, []);

  // If the containing native Dialog is listening for a close event, and this feature is disabled, this will close
  // the dialog after a set amount of time. It is possible the dialog doesn't have its own close button
  useEffect(() => {
    if (disabledMessage) {
      setTimeout(() => {
        window.chrome.webview.postMessage(`Dialog:Self:Close`);
      }, 3000);
    }
  }, [disabledMessage]);

  useNativeMessageListenerEffect((from: string, eventName: string, eventValue: string, payload: string) => {
    if (from === 'Dialog') {
      if (eventValue === 'Ok') {
        if (payload) {
          Cache.bust(imageCacheKey(payload));
        }

        // @ts-ignore
        dispatch(getConsumables());
      }
    } else if (from === 'System') {
      switch (eventName) {
        case 'AuthToken':
          handleZumoAuth(eventValue);
          break;
        case 'Pollworker':
          if (eventValue === 'ModuleInfo') {
            console.debug('Module Info', JSON.parse(payload));
          }
          break;
      }
    }
  });

  function checkIfFeatureIsEnabled() {
    if (isEasyOrder() || !checkFeatureEnabled) return;

    if (!targetFeature || !targetFeature.enabled) {
      setDisabledMessage(featureDisabledMessage || `This feature${targetFeature ? ` (${targetFeature.feature})` : ''} is not enabled for your account.`);
    }
  }

  function isEasyOrder() {
    return checkFeatureEnabled === FEATURES_IDS.EASY_ORDER;
  }

  function checkIfEasyOrderIsEnabled() {
    if (!user) return;

    // user.enabled is a boolean that represents the fact that both the current (state) account has the feature
    // enabled, AND, this account's parent account.
    if (isEasyOrder()) {
      if (!user.enabled) {
        setDisabledMessage("This feature must be enabled by EasyVote support.");
      } else if (!user.account.isState) {
        setDisabledMessage("County Level customers cannot use administrative features.");
      }
    }
  }

  if (!user || !showApp) {
    return (
      <div className='h-full w-full flex flex-col justify-center'>
        <div className="mx-auto">
          <Loading loadingMessage="Loading..."/>
        </div>
      </div>
    )
  }

  return (
    <div className={classNames(fullscreen ? 'h-full' : 'mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5')}>
      {
        disabledMessage ? (
          <DisabledAppCoverall message={disabledMessage}>
            <div className='space-y-2 mt-2'>
              <div>
                If you’re trying to order supplies, please log in to {storeURL}
              </div>
              <Flexor justify='center'>
                <Button variant='primary' onClick={async () => {
                  const toastOptions = {autoClose: 3000, position: 'bottom-center'} as ToastOptions;

                  try {
                    await navigator.clipboard.writeText(storeURL);
                    toast.success('Link copied to clipboard', toastOptions);
                  } catch (error) {
                    toast.warning('Link could not be copied, select text and copy manually', toastOptions);
                  }
                }}>
                  Copy Link
                </Button>
              </Flexor>
            </div>
          </DisabledAppCoverall>
        ) : (
          <>{children}</>
        )
      }
    </div>
  );
}

