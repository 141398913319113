import { SectionSubSubHeading, Text } from "shared/src/components/SectionHeading";
import {useSelector} from "react-redux";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {
  deleteDocument,
  getDocumentsForPollworker,
  getFileFromSecureStorage,
} from "admin/src/fetchers";
import {AppState} from "admin/src/reducers";
import {
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
  EyeIcon,
  NoSymbolIcon, TrashIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/16/solid";
import { Flexor } from "shared/src/components"
import Spinner from "shared/src/components/Spinner";
import {classNames} from "shared/src/utils/classNames";
import {Menu, Transition} from "@headlessui/react";
import {EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import { FileIcon, defaultStyles, DefaultExtensionType } from 'react-file-icon';
import dayjs from "dayjs";
import {Button, Input} from "shared/src/components/ui";
import {uploadDocument} from "./utils/documentUpload";
import {PollworkerDocument} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import PanelModal from "./ui/PanelModal";
import PanelHeader from "./ui/PanelHeader";
import { toast } from "react-toastify";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "shared/src/components/ui/Dialog";
import useSelectedPollworker from "../../../hooks/useSelectedPollworker";

const IMAGE_MIME_TYPES: {[key: string]: string} = {
  '.png': 'image/png',
  '.jpg': 'image/jpg',
}

const EXTENSION_TO_MIME_TYPE: {[key: string]: string} = {
  ...IMAGE_MIME_TYPES,
  '.pdf': 'application/pdf',
};

function FileUploadForm({onUpload, onCancel}: {onUpload: (file: File, fileName: string) => Promise<any>, onCancel: () => void}) {
  const [fileName, setFileName] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const fileInput = useRef<HTMLInputElement>(null);

  function upload() {
    if (!file) return;

    setUploading(true);

    onUpload(file, fileName).then(() => {
      setUploading(false);
    }).catch(setError);
  }

  return (
    <div className="space-y-5">
      <Input ref={fileInput} type="file" className="hidden" onChange={(event) => {
        const files = event.target.files || [];
        setFile(files[0]);
        if (!fileName) setFileName(files[0]?.name);
      }} />
      <Input
        type='text'
        placeholder="File name"
        onChange={({target: {value}}) => setFileName(value)}
        value={fileName}
        id="file_name"
        name="file_name"
      />
      <div>
        <Button className="w-full" onClick={() => fileInput.current?.click()}>
          <PlusCircleIcon className="w-4 h-4" />
          <span>{file ? 'Change' : 'Choose'} file</span>
        </Button>
        {file ? <Text className="text-right mt-2">A file has been selected</Text> : null}
        {error ? <Text variant='error' className="text-right mt-2">{error}</Text> : null}
      </div>
      <div className="border-t border-gray-300 pt-5 flex justify-between items-center">
        <button onClick={onCancel} className="text-sm font-semibold">Cancel</button>
        <Button onClick={upload} disabled={uploading || !fileName || !file} className="justify-center">
          {!uploading ? <ArrowUpOnSquareIcon className="w-5 h-5" /> : <Spinner show />}
          <span>Upload</span>
        </Button>
      </div>
    </div>
  );
}

export default function PollworkerDocumentsPanel() {
  const [documents, setDocuments] = useState<PollworkerDocument[]>([]);
  const [documentToView, setDocumentToView] = useState<{ext?:string, data?: string, document?: PollworkerDocument}>({data: undefined, document: undefined, ext: undefined});
  const [showDocumentViewer, setShowDocumentViewer] = useState<boolean>(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState<boolean>(false);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const pollworker = useSelectedPollworker();

  useEffect(() => {
    if (!moduleInfo || !pollworker) return;

    loadDocuments();
  }, [moduleInfo, pollworker]);

  function loadDocuments() {
    if (!pollworker) return;

    setLoadingDocuments(true);
    getDocumentsForPollworker(pollworker.keyEVUserId)
    .then(({data}) => {
      const docs = JSON.parse(atob(data)).map((d: PollworkerDocument) => {
        const mime = EXTENSION_TO_MIME_TYPE[`.${getDocumentExtension(d)}`];
        return {...d, mime};
      }).sort((a: PollworkerDocument, b: PollworkerDocument) => {
        return dayjs(a.dateCreated) < dayjs(b.dateCreated) ? 1 : -1;
      });

      setDocuments(docs);
    })
    .finally(() => setLoadingDocuments(false));
  }

  function downloadDocumentToFs(doc: PollworkerDocument) {
    downloadDocument(doc).then(([success, ext, dataUrl]) => {
      if (!success || !dataUrl) return;

      const download = `${doc.documentName}.${ext}`;

      const link = document.createElement('a');
      link.href = dataUrl;

      link.download = download;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  function delDocument(document: PollworkerDocument) {
    if (!window.confirm('Are you sure you want to delete this document forever?')) return;

    deleteDocument(document.id).finally(loadDocuments);
  }

  function downloadDocument(doc: PollworkerDocument): Promise<[boolean, string?, string?]> {
    return getFileFromSecureStorage(doc.url).then((resp) => {
      if (!resp.success) {
        toast.error(resp.errorMessage);
        return [false];
      }

      const ext = doc.url.split('.').pop() as string;
      const mime = EXTENSION_TO_MIME_TYPE[`.${ext}`];
      const dataUrl = `data:${mime};base64,${JSON.parse(atob(resp.data)).data}`;
      return [true, ext, dataUrl];
    });
  }

  function getDocumentExtension(document?: PollworkerDocument) {
    if (!document) return;

    return document.url.split('.').pop();
  }

  function upload(file: File, fileName: string): Promise<any> {
    if (!pollworker) return Promise.reject('We could not find the associated pollworker');

    return uploadDocument(pollworker, pollworker.keyEVUserId, file, fileName).then(() => {
      loadDocuments();
      setShowFileUploadModal(false);
    });
  }

  const initLoadingDocuments = loadingDocuments && !documents.length;
  const documentName = documentToView.document?.documentName;

  return (
    <div>
      <PanelHeader title='Documents' loading={loadingDocuments} reload={loadDocuments} onAdd={() => setShowFileUploadModal(true)}/>
      <PanelModal title={'Upload a document'} show={showFileUploadModal} hide={() => setShowFileUploadModal(false)}>
        <FileUploadForm onUpload={upload} onCancel={() => setShowFileUploadModal(false)}/>
      </PanelModal>
      <Dialog size={documentToView.ext === 'pdf' ? 'full' : 'lg'} open={showDocumentViewer} onOpenChange={setShowDocumentViewer}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className='font-semibold'>
              {documentName || 'Preview'}
            </DialogTitle>
          </DialogHeader>
          <>
            {
              documentToView.ext === 'pdf' ? (
                <iframe data-testid='iframe-preview' title={`Document ${documentName}`} className="w-full h-full" src={documentToView.data}/>
              ) : (
                <img data-testid='img-preview' alt={`Document ${documentName}`} src={documentToView.data} className='pt-5'/>
              )
            }
          </>
        </DialogContent>
      </Dialog>
      <div className="px-2">
        {
          initLoadingDocuments ? (
            <Flexor justify="center" className="space-x-2 mt-10">
              <Spinner show />
              <SectionSubSubHeading>Loading documents...</SectionSubSubHeading>
            </Flexor>
          ) : (
            <>
              {
                !documents.length ? (
                  <Flexor justify="center" className="space-x-2 mt-10">
                    <NoSymbolIcon className="h-6 w-6" />
                    <SectionSubSubHeading>No documents found</SectionSubSubHeading>
                  </Flexor>
                ) : null
              }
              <ul role="list" className={classNames(loadingDocuments ? 'opacity-50' : '', 'transition-opacity divide-y divide-gray-100')}>
                {
                  documents.map((document) => {
                    const ext = getDocumentExtension(document) as DefaultExtensionType;

                    return (
                      <li key={document.id} className="flex items-center justify-between gap-x-6 py-5">
                        <Flexor className="space-x-2">
                          <div className="h-6 w-6">
                            <FileIcon extension={getDocumentExtension(document)} {...(ext ? defaultStyles[ext] : {})} />
                          </div>
                          <div className="min-w-0">
                            <div className="flex items-start gap-x-3">
                              <p className="text-sm font-semibold leading-6 text-gray-900">{document.documentName}</p>
                            </div>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                              <p className="whitespace-nowrap">
                                Uploaded at <time dateTime={document.dateCreated}>{dayjs(document.dateCreated).format('MM-DD-YYYY hh:mm')}</time>
                              </p>
                            </div>
                          </div>
                        </Flexor>
                        <div className="flex flex-none items-center gap-x-4">
                          <Menu as="div" className="relative flex-none">
                            <Menu.Button data-testid={`menu-button-${document.id}`} className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                              <span className="sr-only">Open options</span>
                              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true"/>
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                <Menu.Item data-testid={`view-document-btn-${document.id}`} disabled={!EXTENSION_TO_MIME_TYPE[ext ? `.${ext}` : '']}>
                                  {({
                                    active,
                                  }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'flex items-center space-x-2 px-3 py-1 text-sm leading-6 text-gray-900 aria-disabled:text-gray-400'
                                      )}
                                      onClick={() => {
                                        downloadDocument(document).then(([success, ext, dataUrl]) => {
                                          if (!success) return;

                                          setShowDocumentViewer(true);
                                          setDocumentToView({
                                            data: dataUrl,
                                            document,
                                            ext
                                          });
                                        });
                                      }}
                                    >
                                      <EyeIcon className="h-5 w-5"/>
                                      <span>View<span className="sr-only">, {document.documentName}</span></span>
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      data-testid={`download-btn-${document.id}`}
                                      className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'flex items-center space-x-2 px-3 py-1 text-sm leading-6 text-gray-900'
                                      )}
                                      onClick={() => downloadDocumentToFs(document)}
                                    >
                                      <ArrowDownOnSquareIcon className="h-5 w-5"/>
                                      <span>Download<span className="sr-only">, {document.documentName}</span></span>
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active ? 'bg-ev-red text-white' : '',
                                        'flex items-center space-x-2 px-3 py-1 text-sm leading-6 text-gray-900 transition-colors'
                                      )}
                                      onClick={() => {
                                        delDocument(document);
                                      }}
                                    >
                                      <TrashIcon className="h-5 w-5"/>
                                      <span>Delete<span className="sr-only">, {document.documentName}</span></span>
                                    </a>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </li>
                    )
                    }
                  )}
              </ul>
            </>
          )
        }
      </div>
    </div>
  )
}
