import React, { FC, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Route, Router } from 'wouter';
import EasyOrderLogin from './screens/user/Login';
import { UserApp } from "./screens/user/UserApp";
import AdminDashboard from "./screens/admin/Dashboard";
import { Provider, useDispatch, useSelector } from 'react-redux';
import Store from "./screens/store/Store";
import AdminApp, { FEATURES_IDS } from './screens/admin/AdminApp';
import NestedRoutes from 'shared/src/components/NestedRoutes';
import Toaster from './components/Toaster';
import store from "./store";
import Orders from "./screens/user/Orders";
import Checkout from "./screens/user/Checkout";
import { AdminContext } from "shared/src/contexts/AdminContext";
import ItemDetails from "./screens/admin/ItemDetails";
import { ErrorBoundary } from "shared/src/components";
import Pollworker from "./components/Pollworker/Pollworker";
import Reports from "./screens/admin/SetupAndAdmin/Reports";
import { AppState } from "./reducers";
import { getUser } from "./fetchers";
import InventoryLevelsDashboard from "./screens/admin/Inventory";
import EasyOrderShell from "./screens/admin/EasyOrderShell";
import CampaignFinance from "./components/CampaignFinance/CampaignFinance";
import ContainerCentered from "./screens/admin/ContainerCentered";
import InventoryReports from "./components/Inventory/Reports";
import DeploymentsDashboard from "./components/Inventory/DeploymentsDashboard";
import SetupAndAdmin from "./screens/admin/SetupAndAdmin/SetupAndAdmin";
import FormEditor from "./screens/admin/SetupAndAdmin/FormEditor";
import Forms from "./screens/admin/SetupAndAdmin/Forms";
import NavBar, { NavigationItem } from "./components/ui/NavBar";
import PollworkerApplicationSubmissionViewer from "./screens/admin/Forms/PollworkerApplicationSubmissionViewer";
import { ToastContainer } from "react-toastify";
import { UserType } from "./enums/userType";
import RouteRequiredUserType from "./components/RouteRequiredUserType";
import { ChartBarSquareIcon, Cog6ToothIcon, DocumentIcon, InformationCircleIcon, KeyIcon, LanguageIcon, PlayIcon, PresentationChartLineIcon, UsersIcon } from "@heroicons/react/24/outline";
import Playground from "./screens/admin/Playground";
import BasicInfo from "./screens/admin/SetupAndAdmin/BasicInfo";
import Nomenclature from "./screens/admin/SetupAndAdmin/Nomenclature";
import Security from "./screens/admin/SetupAndAdmin/Security";
import FilerImport from "./components/CampaignFinance/FilerImport";
import PollworkerVotingLocationScheduler from "./components/Pollworker/Panels/PollworkerVLScheduler";
import * as Sentry from "shared/src/setupSentry";

import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import Utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import './index.css';

Sentry.initialSetup();
dayjs.extend(Utc);
dayjs.extend(LocalizedFormat);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function AdminContextStore(props: { children: ReactNode }) {
  const user = useSelector((state: AppState) => state.user);
  const [isAdmin, setAdmin] = useState<boolean>(true);
  const dispatch = useDispatch();

  const auth = window.sessionStorage.getItem('zumo');

  useEffect(() => {
    if (user && user.loading) return;

    //@ts-ignore
    dispatch(getUser());
  }, [auth]);

  return (
    <AdminContext.Provider value={{isAdmin, toggleAdmin: () => setAdmin(!isAdmin)}}>
      {props.children}
    </AdminContext.Provider>
  )
}

function withNavBar(Component: FC, navigation: NavigationItem[]) {
  return () => (
    <div className='flex flex-col justify-start h-full'>
      <NavBar navigation={navigation} />
      <Component />
    </div>
  )
}

const setupAndAdminNavigationItems = [
  {
    icon: <InformationCircleIcon className="size-5"/>,
    name: 'Info',
    href: '/info',
    meta: 'info basic',
    show: () => process.env.NODE_ENV !== 'production',
  },
  {
    icon: <UsersIcon className="size-5"/>,
    href: '/',
    name: 'Users',
    meta: 'users sso auth new',
  },
  {
    icon: <DocumentIcon className='size-5' />,
    href: '/forms',
    name: 'Forms',
    meta: 'application',
  },
  {
    icon: <KeyIcon className="size-5"/>,
    name: 'Security',
    href: '/security',
    meta: 'security safety key level permissions settings',
    show: () => process.env.NODE_ENV !== 'production',
  },
  {
    icon: <LanguageIcon className="size-5"/>,
    name: 'Nomenclature',
    href: '/nomenclature',
    meta: 'terminology terms naming',
    show: () => process.env.NODE_ENV !== 'production',
  },
  {
    icon: <PresentationChartLineIcon className="size-5"/>,
    name: 'Reports',
    href: '/reports',
    show: () => process.env.NODE_ENV !== 'production',
  },
  {
    icon: <Cog6ToothIcon className="size-5"/>,
    name: 'Settings',
    href: '/settings',
    show: () => process.env.NODE_ENV !== 'production',
  },
  {
    icon: <PlayIcon className="size-5"/>,
    name: 'Playground',
    href: '/playground',
    show: () => process.env.NODE_ENV !== 'production',
  },
] as NavigationItem[];

const campaignfinanceNavigationItems = [
  {
    icon: <PresentationChartLineIcon className="size-5"/>,
    name: 'Document Filing Report',
    href: '/'
  },
  {
    icon: <UsersIcon className="size-5"/>,
    name: 'New Filer Import',
    href: '/filerimport'
  },
] as NavigationItem[];

root.render(
  <Router>
    <ErrorBoundary>
      <NestedRoutes base="/app">
        <Provider store={store}>
          <Route path="/login" component={EasyOrderLogin} />
          <UserApp>
            <Route path="/store" component={Store} />
            <Route path="/orders" component={Orders} />
            <Route path="/checkout" component={Checkout} />
          </UserApp>
          <Toaster />
        </Provider>
      </NestedRoutes>
      <NestedRoutes base="/admin">
        <ToastContainer />
        <Provider store={store}>
          <AdminContextStore>
            <NestedRoutes base="/form_submission">
              <AdminApp fullscreen>
                <Route path='/:applicantId' component={PollworkerApplicationSubmissionViewer} />
              </AdminApp>
            </NestedRoutes>
            <NestedRoutes base="/setupandadmin">
              <AdminApp fullscreen>
                <Route path="/" component={withNavBar(SetupAndAdmin, setupAndAdminNavigationItems)} />
                <RouteRequiredUserType requiredUserType={UserType.Employee} path="/forms" component={withNavBar(Forms, setupAndAdminNavigationItems)} />
                <Route path="/forms/:formId" component={FormEditor} />
                <Route path="/info" component={withNavBar(BasicInfo, setupAndAdminNavigationItems)} />
                <Route path="/security" component={withNavBar(Security, setupAndAdminNavigationItems)} />
                <Route path="/nomenclature" component={withNavBar(Nomenclature, setupAndAdminNavigationItems)} />
                <Route path="/reports" component={withNavBar(Reports, setupAndAdminNavigationItems)} />
                <Route path="/settings" component={withNavBar(() => <div>/settings</div>, setupAndAdminNavigationItems)} />
                <Route path="/playground" component={withNavBar(Playground, setupAndAdminNavigationItems)} />
              </AdminApp>
            </NestedRoutes>
            <NestedRoutes base="/inventory">
              <AdminApp fullscreen checkFeatureEnabled={FEATURES_IDS.MODERN_INVENTORY} featureDisabledMessage="This functionality is not currently active.">
                <Route path="/" component={InventoryReports} />
                <Route path="/dashboard" component={DeploymentsDashboard} />
                <Route path="/reports" component={InventoryReports} />
              </AdminApp>
            </NestedRoutes>
            <NestedRoutes base="/campaignfinance">
              <AdminApp fullscreen>
                <Route path="/" component={withNavBar(CampaignFinance, campaignfinanceNavigationItems)} />
                <Route path="/filerimport" component={withNavBar(FilerImport, campaignfinanceNavigationItems)} />
              </AdminApp>
            </NestedRoutes>
            <NestedRoutes base="/pollworker">
              <AdminApp fullscreen checkFeatureEnabled={FEATURES_IDS.MODERN_POLLWORKER} featureDisabledMessage="This account doesn't have Modern PollWorker enabled.">
                <Route path="/" component={Pollworker} />
                <Route path="/scheduler/:viewType?/:votingLocationId?" component={PollworkerVotingLocationScheduler} />
              </AdminApp>
            </NestedRoutes>
            <NestedRoutes base="/reports">
              <AdminApp checkFeatureEnabled={FEATURES_IDS.MODERN_REPORTS} featureDisabledMessage="This account doesn't have Modern Reports enabled.">
                <Route path="/" component={Reports} />
              </AdminApp>
            </NestedRoutes>
            <NestedRoutes base="/easyorder">
              <AdminApp fullscreen checkFeatureEnabled={FEATURES_IDS.EASY_ORDER}>
                <Route path="/dashboard">
                  <EasyOrderShell>
                    <ContainerCentered>
                      <AdminDashboard />
                    </ContainerCentered>
                  </EasyOrderShell>
                </Route>
                <Route path="/inventory">
                  <EasyOrderShell>
                    <InventoryLevelsDashboard />
                  </EasyOrderShell>
                </Route>
                <Route path="/store">
                  <EasyOrderShell>
                    <div className="p-4">
                      <Store />
                    </div>
                  </EasyOrderShell>
                </Route>
                <Route path="/store/:itemId" component={(props: any) => (
                  <div className="px-4 mt-3">
                    <ItemDetails showItemName {...props} />
                  </div>
                )} />
              </AdminApp>
            </NestedRoutes>
            <Toaster />
          </AdminContextStore>
          {process.env.NODE_ENV === 'development' ? <Route path="/playground" component={withNavBar(Playground, setupAndAdminNavigationItems)} /> : null}
        </Provider>
      </NestedRoutes>
    </ErrorBoundary>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
