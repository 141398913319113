import {suspensify} from "suspensify";
import {InventoryImageData} from "../types/InventoryImageTypes";
import {getImageData} from "../fetchers";
import {useSelector} from "react-redux";
import {AppState} from "../reducers";

export const useSuspendedImageData = suspensify((inventoryItemId?: string, updatedAt?: string, customerIdOverride?: string, skipCache= false, entityType?: string): Promise<InventoryImageData> => {
  const customerId = useSelector((state: AppState) => state.user?.parentAccount?.id || state.user?.account?.id);
  return getImageData(inventoryItemId, customerIdOverride || customerId, entityType, skipCache);
});
