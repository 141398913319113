import React, { ReactNode, useEffect, useState } from "react";
import {createPortal} from "react-dom";
import { useDispatch } from "react-redux";
import {XMarkIcon} from "@heroicons/react/24/outline";
import { Flexor } from "shared/src/components"
import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import { popDetailUi, pushDetailUi } from "../../../../reducers/pollworker/detailsUiStack";

export default function PanelModal({title, show, children, hide}: {title: string, show: boolean, children: ReactNode, hide: () => void}) {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open && show) {
      dispatch(pushDetailUi('modal'));
      setOpen(true);
    }

    if (open && !show) {
      dispatch(popDetailUi('modal'));
      setOpen(false);
    }
  }, [show, open]);

  return createPortal(
    <>
      {
        show ? (
          <>
            <div className="h-full bg-black/50 w-[33.4%] absolute top-0 right-0 z-30"></div>
            <div className="fixed top-0 right-0 mx-auto flex flex-col items-center justify-center h-full py-10 z-40 w-[33.4%]">
              <div className="w-5/6 bg-white p-3">
                <Flexor>
                  <SectionSubSubHeading>{title}</SectionSubSubHeading>
                  <button onClick={hide}>
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </Flexor>
                {children}
              </div>
            </div>
          </>
        ) : null
      }
    </>,
    document.body
  )
}
