type CacheType = {
  [key: string]: Object;
}

let CACHE: CacheType = {};

const Cache = {
  get: (key: string) => {
    // console.debug(`Cache hit: ${!!CACHE[key] ? 'Yes' : 'No'}`);
    return CACHE[key];
  },
  set: (key: string, value: any) => {
    CACHE[key] = value;
    // console.debug(`Setting cache: ${key}`, value);
    return value;
  },
  bust: (key: string) => {
    // console.debug(`Busting cache item ${key} with old value -> ${CACHE[key]}`);
    delete CACHE[key];
  },
  all: () => {
    return CACHE;
  },
  flush: () => {
    CACHE = {};
  }
}

export default Cache;

export function imageCacheKey(id: string) {
  return `getImageData/${id}`;
}
