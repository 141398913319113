import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {GridSelectedPollworkerRow} from "../../components/Pollworker/types";
import {
  Party,
  PollworkerApiResponse, PollworkerWorkHistory, Precinct,
  RoleInfo, Schedule,
  UserInfo
} from "../../types/Pollworker/PollworkerWorkHistory";

export type GridState = {
  parties: Party[],
  precincts: Precinct[],
  roleTypes: RoleInfo[],
  selectedRows: GridSelectedPollworkerRow[];
  selectedRowsPollworkerIds: string[];
  selectedVotingLocationId?: string;
  pollworkers: PollworkerApiResponse;
  reportsTo: UserInfo[];
  customFields: any[];
  pollworkerCustomFieldsData: any[],
  pollworkerWorkAssignmentConfirmationsForElection: any[],
  quickFilter: string | undefined,
  pollworkerToEdit?: { pollworker: PollworkerWorkHistory, column: string },
  showPollworkerDetailsId?: string,
  displayedRowCount: number,
  isFiltered: boolean,
  loadingPollworkerData: boolean,
}

const defaultGridReducer: GridState = {
  parties: [],
  precincts: [],
  roleTypes: [],
  selectedRows: [],
  selectedRowsPollworkerIds: [],
  selectedVotingLocationId: undefined,
  pollworkers: {workerList: [], precinctList: [], locationList: []},
  reportsTo: [],
  customFields: [],
  pollworkerCustomFieldsData: [],
  pollworkerWorkAssignmentConfirmationsForElection: [],
  quickFilter: undefined,
  pollworkerToEdit: undefined,
  showPollworkerDetailsId: undefined,
  displayedRowCount: 0,
  isFiltered: false,
  loadingPollworkerData: false,
};

const gridSlice = createSlice({
  name: 'grid',
  initialState: defaultGridReducer,
  reducers: {
    setShowPollworkerDetailsId: (state, action) => {
      state.showPollworkerDetailsId = action.payload;
    },
    setPollworkerToEdit: (state, action) => {
      state.pollworkerToEdit = action.payload;
    },
    updatePollworkerLocation: (state, action: PayloadAction<Schedule>) => {
      const locIndex = state.pollworkers.locationList.findIndex(loc => loc.id === action.payload.id);
      if (locIndex > -1) state.pollworkers.locationList[locIndex] = action.payload;
    },
    updatePollworker: (state, action: PayloadAction<PollworkerWorkHistory>) => {
      const idx = state.pollworkers.workerList.findIndex((history: PollworkerWorkHistory) => history.id === action.payload.id);
      if (idx > -1) state.pollworkers.workerList[idx] = action.payload;
    },
    setParties: (state, action: PayloadAction<Party[]>) => {
      state.parties = action.payload;
    },
    setPrecincts: (state, action: PayloadAction<Precinct[]>) => {
      state.precincts = action.payload;
    },
    setSelectedRowsPollworkerIds: (state, action: PayloadAction<string[]>) => {
      state.selectedRowsPollworkerIds = action.payload;
    },
    setSelectedVotingLocation: (state, action: PayloadAction<string>) => {
      state.selectedVotingLocationId = action.payload;
    },
    setReportsTo: (state, action: PayloadAction<UserInfo[]>) => {
      state.reportsTo = action.payload;
    },
    setCustomFields: (state: GridState, action: PayloadAction<any[]>) => {
      state.customFields = action.payload;
    },
    setPollworkerCustomFieldsData: (state, action: PayloadAction<UserInfo[]>) => {
      state.pollworkerCustomFieldsData = action.payload;
    },
    setPollworkerWorkAssignmentConfirmationsForElection: (state, action: PayloadAction<UserInfo[]>) => {
      state.pollworkerWorkAssignmentConfirmationsForElection = action.payload;
    },
    clearSelectedRows: (state, action) => {
      state.selectedRowsPollworkerIds = [];
    },
    setPollworkers: (state, action: PayloadAction<PollworkerApiResponse>) => {
      state.pollworkers = action.payload;
    },
    setRoleTypes: (state, action: PayloadAction<RoleInfo[]>) => {
      state.roleTypes = action.payload;
    },
    setQuickFilter: (state, action) => {
      state.quickFilter = action.payload;
    },
    setDisplayedRowCount: (state, action) => {
      state.displayedRowCount = action.payload;
    },
    setIsFiltered: (state, action) => {
      state.isFiltered = action.payload;
    },
    setLoadingPollworkerData(state, action) {
      state.loadingPollworkerData = action.payload;
    },
  }
});

export const {
  setCustomFields,
  updatePollworker,
  setParties,
  setPrecincts,
  setSelectedRowsPollworkerIds,
  setSelectedVotingLocation,
  setReportsTo,
  setPollworkerCustomFieldsData,
  setPollworkerWorkAssignmentConfirmationsForElection,
  setPollworkers,
  setRoleTypes,
  setQuickFilter,
  setPollworkerToEdit,
  updatePollworkerLocation,
  setShowPollworkerDetailsId,
  clearSelectedRows,
  setDisplayedRowCount,
  setIsFiltered,
  setLoadingPollworkerData,
} = gridSlice.actions;

export default gridSlice.reducer;
