import React from "react";
import logov from "admin/src/logo-v.png";
import { classNames } from "shared/src/utils/classNames";

export default function LoadingOverlay({infoText, animate, isPreview = true}: { infoText: string, animate: boolean, isPreview?: boolean }) {
  return (
    <div className="cursor-wait space-y-8">
      {
        isPreview ? (
          <div className='flex justify-center -mb-4'>
            <div className='inline-block mx-auto text-white uppercase px-2 py-1 rounded-full font-semibold bg-ev-blue'>Preview</div>
          </div>
        ) : null
      }
      <div className='h-[9em] w-[7em] scale-90 mx-auto'>
        <img className={classNames(animate ? "animate-heartbeat" : '')} src={logov} alt='EasyVote check logo' />
      </div>
      <div className="text-md font-semibold">{infoText}</div>
    </div>
  );
}
