import React, { Fragment, useEffect, useState } from 'react';
import {Dialog as Slideover, Menu, Transition} from '@headlessui/react'
import {GlobeAmericasIcon, MapIcon, MapPinIcon, PencilSquareIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {classNames} from "shared/src/utils/classNames";
import { Flexor, ScreenReaderHidden, ScreenReaderOnly } from "shared/src/components";
import {getCountyConfigs, upsertCountyConfig} from "../../fetchers";
import { OrderConfig } from '../../types/User';
import Spinner from "../../../../shared/src/components/Spinner";
import {showToast} from "../../actions/toast";
import {useDispatch} from "react-redux";
import DialogPanelModal from '../modals/DialogPanelModal';
import { Button, Input, Label, Switch } from 'shared/src/components/ui';

const tabs = [
  { name: 'All', href: '#', current: true },
];

export default function SlideoutCountySettings({show, onClose}: {show: boolean, onClose: Function}) {
  const [open, setOpen] = useState<boolean>(show);
  const [loading, setLoading] = useState<boolean>(show);
  const [saving, setSaving] = useState<boolean>(false);
  const [countyCustomers, setCountyCustomers] = useState<OrderConfig[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [configToEdit, setConfigToEdit] = useState<OrderConfig>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) loadCountyConfigs();
  }, [show]);

  function loadCountyConfigs() {
    setLoading(true);
    getCountyConfigs()
      .then(({data, success}) => {
        setCountyCustomers(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function updateConfig(key:string, value:any) {
    if (configToEdit) {
      setConfigToEdit({...configToEdit, [key]: value})
    }
  }

  function saveConfig() {
    setSaving(true);

    let configToUpdate = configToEdit;
    if (configToEdit?.id) configToUpdate = {...configToEdit, id: configToEdit.id};
    if (configToEdit?.customer?.id) configToUpdate = {...configToEdit, keyCustomerId: configToEdit?.customer?.id};

    upsertCountyConfig(configToUpdate)
      .then(({_data, success}) => {
        if (success) {
          closeEditModal();
          dispatch(showToast({content: 'County settings saved!', undoAction: undefined}));
        }
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function closeEditModal() {
    setConfigToEdit(undefined);
    loadCountyConfigs();
  }

  // @ts-ignore
  return (
    <Transition.Root show={show} as={Fragment}>
      <Slideover as="div" className="relative z-50" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Slideover.Panel className="pointer-events-auto w-screen max-w-md">
                  <DialogPanelModal show={!!configToEdit} title={`${configToEdit?.id ? 'Edit' : 'Add'} Shipping Address`} onClose={() => {
                    closeEditModal();
                  }} icon={<MapIcon className="h-5 w-5"/>}>
                    <div className="space-y-3 divide-y-1 divide-gray-100">
                      <div className="w-full">
                        <Label htmlFor="name">Name</Label>
                        <Flexor justify="between" className="space-x-2">
                          <Input
                            type="text"
                            id="name"
                            autoComplete="none"
                            value={configToEdit?.name || ''}
                            onChange={({ target: { value } }) => updateConfig('name', value)}
                            placeholder="Simple description..."
                          />
                          <Flexor className="space-x-2 shrink">
                            <Label htmlFor="isActive">Active</Label>
                            <Switch id="isActive" checked={configToEdit?.enabled} onCheckedChange={(checked: boolean) => updateConfig('enabled', checked)}/>
                          </Flexor>
                        </Flexor>
                      </div>
                      <div>
                        <Label htmlFor="address1">Address</Label>
                        <Input
                          type="text"
                          id="address1"
                          autoComplete="none"
                          value={configToEdit?.shippingAddress || ''}
                          onChange={({ target: { value } }) => updateConfig('shippingAddress', value)}
                          placeholder="Street address"
                        />
                      </div>
                      <div>
                        <Label htmlFor="address2">Address 2</Label>
                        <Input
                          type="text"
                          id="address2"
                          autoComplete="none"
                          value={configToEdit?.shippingAddress2 || ''}
                          onChange={({ target: { value } }) => updateConfig('shippingAddress2', value)}
                          placeholder="Optional"
                        />
                      </div>
                      <Flexor className="space-x-3">
                        <div className="w-3/5">
                          <Label htmlFor="city">City</Label>
                          <Input
                            id="city"
                            type="text"
                            autoComplete="none"
                            value={configToEdit?.shippingCity || ''}
                            onChange={({ target: { value } }) => updateConfig('shippingCity', value)}
                            placeholder="City"
                          />
                        </div>
                        <div className="w-1/5">
                          <Label htmlFor="state">State</Label>
                          <Input
                            max={2}
                            min={2}
                            id="state"
                            type="text"
                            autoComplete="none"
                            value={configToEdit?.shippingState || ''}
                            onChange={({ target: { value } }) => updateConfig('shippingState', value)}
                            placeholder="State"
                          />
                        </div>
                        <div className="w-1/5">
                          <Label htmlFor="zip">Zip</Label>
                          <Input
                            id="zip"
                            max={5}
                            min={5}
                            type="text"
                            autoComplete="none"
                            value={configToEdit?.shippingZipCode || ''}
                            onChange={({ target: { value } }) => updateConfig('shippingZipCode', value)}
                            placeholder="Zip"
                          />
                        </div>
                      </Flexor>
                      <hr />
                      <Button disabled={saving} className='w-full' onClick={() => saveConfig()}>
                        <Spinner show={saving} light />
                        <span>Save</span>
                      </Button>
                    </div>
                  </DialogPanelModal>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl border-l border-gray-200">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Flexor className="space-x-1">
                          <GlobeAmericasIcon className="h-5 w-5 "/>
                          <Slideover.Title className="text-base font-semibold leading-6 text-gray-900">County Settings</Slideover.Title>
                        </Flexor>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-ev-blue"
                            onClick={() => onClose()}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200">
                      <div className="px-6">
                        <nav className="-mb-px flex space-x-6">
                          {tabs.map((tab) => (
                            <a
                              key={tab.name}
                              href={tab.href}
                              className={classNames(
                                tab.current
                                  ? 'border-ev-blue text-ev-blue'
                                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                              )}
                            >
                              {tab.name}
                              <span className="inline-flex items-center ml-1 rounded-full bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-800">
                                {countyCustomers.length}
                              </span>
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                    <div className="px-5 py-6 pb-0">
                      <Input
                        type="text"
                        value={searchTerm}
                        onChange={({target: {value}}) => setSearchTerm(value.toLowerCase())}
                        placeholder="Search"
                      />
                    </div>
                    {
                      loading ? (
                        <Flexor justify="center" className="mt-10 space-x-2 text-center text-md font-medium animate-pulse text-gray-700">
                          <Spinner light={false} />
                          <span>Getting county settings...</span>
                        </Flexor>
                      ) : (
                        <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto mt-4">
                          { countyCustomers.map((config: OrderConfig) => {
                            if (!config.customer?.customerName?.toLowerCase()?.includes(searchTerm)) return null;

                            return (
                              <li key={config.id}>
                                <div className="group relative flex items-center px-5 py-6">
                                  <a className="-m-1 block flex-1 p-1">
                                    <div className="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true"/>
                                    <div className="relative flex min-w-0 flex-1 items-center">
                                  <span className="relative inline-block flex-shrink-0">
                                    <MapPinIcon className="h-10 w-10"/>
                                    <span
                                      className={classNames(
                                        config.enabled ? 'bg-green-400' : 'bg-gray-500',
                                        'absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white'
                                      )}
                                      aria-hidden="true"
                                    />
                                  </span>
                                      <div className="ml-4 truncate">
                                        <p className="truncate text-sm font-medium text-gray-900">{config.customer?.customerName}</p>
                                        <p className="truncate text-sm text-gray-500">EasyOrder is <b>{config.enabled ? 'active' : 'inactive'}</b></p>
                                      </div>
                                    </div>
                                  </a>
                                  <Menu as="div" className="relative ml-2 inline-block flex-shrink-0 text-left">
                                    <ScreenReaderOnly>Open options menu</ScreenReaderOnly>
                                    <ScreenReaderHidden>
                                      <Button variant='tertiary' onClick={() => setConfigToEdit(config)}>
                                        <PencilSquareIcon className="shrink-0 size-6" />
                                      </Button>
                                    </ScreenReaderHidden>
                                  </Menu>
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      )
                    }
                  </div>
                </Slideover.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Slideover>
    </Transition.Root>
  );
}
