import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Flexor } from "shared/src/components";
import { popDetailUi, pushDetailUi } from "../../../../reducers/pollworker/detailsUiStack";
import { useDispatch } from "react-redux";

export function PanelSlideoutHeader({children, close}: {children: ReactNode, close: () => void}) {
  return (
    <div className="sticky top-0 z-50 bg-gray-50 px-4 py-3 sm:px-3 border-b border-gray-200">
      <Flexor>
        {children}
        <div className="flex h-7 items-center">
          <button
            type="button"
            className="relative text-gray-400 hover:text-gray-500"
            onClick={() => close()}
          >
            <span className="sr-only">Close panel</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </Flexor>
    </div>
  )
}


export default function PanelSlideout({children, show = false}: {children: ReactNode, show: boolean}) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open && show) {
      dispatch(pushDetailUi('slideout'));
      setOpen(true);
    }

    if (open && !show) {
      dispatch(popDetailUi('slideout'));
    }
  }, [show]);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="transform transition ease-in-out duration-300"
        enterFrom="translate-x-full"
        enterTo="-translate-x-0"
        leave="transform transition ease-in-out duration-300"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        afterLeave={() => setOpen(false)}
      >
        <div className={`w-[33.4%] z-50 fixed top-0 right-0 h-full overflow-y-auto bg-white border-l-2 border-gray-300`}>
          {open ? children : null}
        </div>
      </Transition.Child>
    </Transition.Root>
  );
}
