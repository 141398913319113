import React, { useEffect, useState } from 'react';
import InventoryNavBar from './InventoryNavBar';
import {fetchActiveDeploymentsForCustomer, fetchDeploymentEquipmentSummary, getCountyConfigs} from 'admin/src/fetchers';
import { Dropdown, Label } from 'shared/src/components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCounty } from 'admin/src/selectors/inventory/ui';
import { setSelectedCounty } from 'admin/src/actions/inventory';
import { CustomerInfo } from 'admin/src/types/CustomerInfo';
import { Deployment, DeploymentDashboardEquipmentSummary } from 'admin/src/types/Inventory';
import DeploymentEquipmentSummaryTable from './DeploymentEquipmentSummaryTable';
import DeploymentEquipmentSummaryChart from './DeploymentEquipmentSummaryChart';
import DeploymentChainOfCustodyEquipmentSummaryChart from './DeploymentChainOfCustodyEquipmentSummaryChart';
import { OrderConfig } from 'admin/src/types/User';

export default function DeploymentsDashboard() {
  const dispatch = useDispatch();
  const [counties, setCounties] = useState<CustomerInfo[]>([]);
  const [countiesLoading, setCountiesLoading] = useState<boolean>(false);
  const [deployments, setDeployments] = useState<Deployment[]>([]);
  const [deploymentsLoading, setDeploymentsLoading] = useState<boolean>(false);
  const [hasCoCPackages, setHasCoCPackages] = useState<boolean>(false);
  const [hasNonCoCPackages, setHasNonCoCPackages] = useState<boolean>(false);
  const [deploymentSummaries, setDeploymentSummaries] = useState<DeploymentDashboardEquipmentSummary[]>([]);
  const [deploymentSummariesLoading, setDeploymentSummariesLoading] = useState<boolean>(false);
  const [selectedDeployment, setSelectedDeployment] = useState<string>('');
  const selectedCounty = useSelector(selectSelectedCounty);

  const onSelectedCountyChange = (newCountyId: string) => {
    dispatch(setSelectedCounty(newCountyId));
  };

  const onSelectedDeploymentChange = (newDeploymentId: string) => {
    setSelectedDeployment(newDeploymentId);
  };

  useEffect(() => {
    setCountiesLoading(true);
    getCountyConfigs()
      .then(countyConfigs => {
        const sortedCountyConfigs = countyConfigs.data
          .filter((config: OrderConfig) => !!config.customer)
          .map((config: OrderConfig): CustomerInfo => config.customer as CustomerInfo)
          .sort((countyA: CustomerInfo, countyB: CustomerInfo) => countyA.customerName.localeCompare(countyB.customerName));

        setCounties(sortedCountyConfigs);
        if (sortedCountyConfigs.length > 0) {
          onSelectedCountyChange(sortedCountyConfigs[0].id);
        }
      })
      .finally(() => setCountiesLoading(false));
  }, []);

  useEffect(() => {
    if (selectedCounty) {
      setDeploymentsLoading(true);
      fetchActiveDeploymentsForCustomer(selectedCounty)
        .then(activeDeployments => {
          setDeployments(activeDeployments.data);

          if (activeDeployments.data.length > 0) {
            setSelectedDeployment(activeDeployments.data[0].id);
          }
        })
        .finally(() => setDeploymentsLoading(false));
    }
  }, [selectedCounty]);

  useEffect(() => {
    if (selectedDeployment) {
      setDeploymentSummariesLoading(true);
      fetchDeploymentEquipmentSummary(selectedCounty, selectedDeployment)
        .then(deploymentSummaries => {
          setDeploymentSummaries(deploymentSummaries);
          setDeploymentSummariesLoading(false);
          setHasCoCPackages(!!deploymentSummaries.find(summary => summary.requiresChainOfCustody));
          setHasNonCoCPackages(!!deploymentSummaries.find(summary => !summary.requiresChainOfCustody));
        });
    }
  }, [selectedDeployment]);

  return (
    <div className="h-full flex flex-col">
      <InventoryNavBar />
      <div className="flex flex-col grow">
        <div className="flex m-4 gap-4">
          <Dropdown
            autoFocus
            className="w-1/4"
            role='county-select'
            id='county-select'
            name='county-select'
            selectedValue={selectedCounty}
            options={counties.map((county) => ({ label: county.customerName, value: county.id }))}
            onChange={({target: {value}}) => {
              onSelectedCountyChange(value)
            }}
            emptyPlaceholder={countiesLoading ? 'Loading...' : 'No sub-customers found...'}
          />
          <div className='flex items-center'>
            <Label>Deployment</Label>
            <Dropdown
              autoFocus
              className='ml-2'
              role='deployment-select'
              id='deployment-select'
              name='deployment-select'
              selectedValue={selectedDeployment}
              options={deployments.map((deployment) => ({ label: deployment.deploymentName, value: deployment.id }))}
              onChange={({target: {value}}) => {
                onSelectedDeploymentChange(value)
              }}
              emptyPlaceholder={deploymentsLoading ? 'Loading...' : 'No active deployments found'}
            />
          </div>
        </div>
        <div className="flex grow max-h-48 justify-evenly m-4">
          {!deploymentSummariesLoading && hasNonCoCPackages && <DeploymentEquipmentSummaryChart summaries={deploymentSummaries} />}
          {!deploymentSummariesLoading && hasCoCPackages && <DeploymentChainOfCustodyEquipmentSummaryChart summaries={deploymentSummaries} />}
        </div>
        <DeploymentEquipmentSummaryTable className="grow" loading={deploymentSummariesLoading} summaries={deploymentSummaries} />
      </div>
    </div>
  );
}
