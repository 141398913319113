import { PayloadAction } from '@reduxjs/toolkit';

import { FEATURE_FLAG_ACTION_TYPES } from 'admin/src/actions/featureFlags';
import { FeatureFlagCheck } from 'admin/src/types/FeatureFlagCheck';


export type FeatureFlagState = {
  [flag: string]: FeatureFlagCheck
}

const defaultFeatureFlagState = {};

export default function featureFlagReducer(state: FeatureFlagState = defaultFeatureFlagState, action: PayloadAction<FeatureFlagCheck>) {
  switch(action.type) {
    case FEATURE_FLAG_ACTION_TYPES.SET:
      return {
        ...state,
        [action.payload.flag]: action.payload
      };
    case FEATURE_FLAG_ACTION_TYPES.CLEAR:
      return {
        ...state,
        [action.payload.flag]: null
      };
    default:
      return state;
  }
}
