import {combineReducers} from 'redux';
import cartReducer from "./cart";
import toastReducer from "./toast";
import {CartItem} from "../types/CartItem";
import {OrderState} from "../types/Order";
import ordersReducer from "./order";
import consumablesReducer from './consumable';
import {ConsumableState} from "../types/ConsumableItem";
import {Login} from "../types/User";
import userReducer from "./user";
import {Theme} from "../types/Theme";
import themeReducer from "./theme";
import modalReducer from "./modal";
import pollworkerReducer, {PollworkerAppState} from "./pollworker";
import pollworkerPublicReducer, { PollworkerPublicState } from "../../../pollworker/src/state/reducers";
import {MODALS} from "../actions/modal";
import {Toast} from "../actions/toast";
import inventoryReducer, { InventoryState } from "./inventory";
import {FeatureFlagState, default as featureFlagsReducer} from "./featureFlags";

export type AppState = {
  cart: CartItem[];
  consumables: ConsumableState;
  featureFlags: FeatureFlagState;
  inventory: InventoryState;
  orders: OrderState;
  modals: { open: typeof MODALS[keyof typeof MODALS] }
  user: Login | undefined;
  pollworker: PollworkerAppState,
  pollworkerPublic: PollworkerPublicState,
  theme: Theme;
  toast: Toast;
}

export default combineReducers({
  cart: cartReducer,
  consumables: consumablesReducer,
  featureFlags: featureFlagsReducer,
  inventory: inventoryReducer,
  modals: modalReducer,
  orders: ordersReducer,
  pollworker: pollworkerReducer,
  pollworkerPublic: pollworkerPublicReducer,
  user: userReducer,
  theme: themeReducer,
  toast: toastReducer,
});
