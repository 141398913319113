import {AnyAction} from "@reduxjs/toolkit";
import {User, USER_ACTION_TYPES} from "../actions/user";
import {Login} from "../types/User";

const defaultUserState = {
  user: undefined,
  account: undefined,
  enabled: false,
  orderConfigs: [],
  loading: false,
  features: [],
}

export default function userReducer(state: Login | null, action: AnyAction): User {
  state = state || null;
  switch (action.type) {
    case USER_ACTION_TYPES.LOADING_USER:
      return {...state, loading: action.payload};
    case USER_ACTION_TYPES.LOGOUT_USER:
      return defaultUserState;
    case USER_ACTION_TYPES.SET_USER:
      return {...defaultUserState, ...action.payload};
    default:
      return state;
  }
}
