import {Order, OrderHistory} from "../types/Order";

export function getOrderId(order: Order | OrderHistory) {
  return order.id.substring(0,7);
}

export function orderStates(order: Order | OrderHistory) {
  const isCartOrder = order.status === -1;
  const canEdit = order.status === 1;

  return {
    canFulfil: order.status === 1,
    canEdit,
    status: {
      enRoute: order.status === 2,
    },
    isCartOrder,
    canCancel: canEdit && !isCartOrder,
    isActive: order.status < 3 && !isCartOrder,
    isPast: order.status > 2 && !isCartOrder,
    items: {
      canRemove: order.orderItems.length > 1,
    }
  }
}

export function getOrderStatusName(orderStatus?: number) {
  switch (orderStatus) {
    case 0:
      return 'Pending';
    case 1:
      return 'Submitted';
    case 2:
      return 'En Route';
    case 3:
      return 'Delivered';
    case 4:
      return 'Canceled';
    case -1:
      return 'Cart';
    default:
      return 'Unknown';
  }
}
