import React, {useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux';
import { debounce } from "lodash";
import { createSelector } from 'reselect';

import { fetchEmployees } from '../../../fetchers';
import { classNames } from 'shared/src/utils/classNames';
import { getAccount } from '../../../selectors';
import DeprecatedModal from 'shared/src/components/DeprecatedModal';
import AddEditUser from './AddEditUser';
import { ScreenReaderOnly } from 'shared/src/components';
import { Button } from 'shared/src/components/ui';

export default function SetupAndAdmin() {
  const [showAddEditDialog, setShowAddEditDialog] = useState(false);
  const [addEditDialogModalTitle, setAddEditDialogModalTitle] = useState('');
  const [editUser, setEditUser] = useState(null as any);

  function onExitAddEditDialog() {
    setShowAddEditDialog(false);
  }

  function onStartAddUser() {
    setEditUser(null);
    setAddEditDialogModalTitle('Add User');
    setShowAddEditDialog(true);
  }

  function onStartEditUser(user: any) {
    setEditUser(user);
    setAddEditDialogModalTitle('Edit User');
    setShowAddEditDialog(true);
  }

  return (
    <div className="p-4">
      <UserGrid showAddEditDialog={showAddEditDialog} onStartAddUser={onStartAddUser} onStartEditUser={onStartEditUser} />
      <DeprecatedModal
        title={addEditDialogModalTitle}
        open={showAddEditDialog}
        onClose={onExitAddEditDialog}
        size='3xl'
        >
        <AddEditUser evUserId={editUser?.keyEVUserId} onComplete={onExitAddEditDialog} />
      </DeprecatedModal>
    </div>
  );
}

function UserGrid({ showAddEditDialog, onStartAddUser, onStartEditUser }: { showAddEditDialog?: boolean, onStartAddUser?: any, onStartEditUser?: any}) {
  const ssoEnabled = useSelector(selectCustomerHasSSO);
  const [usersData, setUsersData]: [any[], (user: any[]) => void] = React.useState([] as any[]);
  const [filteredUsers, setFilteredUsers]: [any[], (user: any[]) => void] = React.useState([] as any[]);

  useEffect(() => {
    if (showAddEditDialog) return;

    fetchEmployees().then((data) => {
      // TODO might need to add some state to track the `search` text and filter when this returns, or block the UI from rendering until this is loaded to prevent weird cases of users filtering before the response
      setUsersData(data);
      setFilteredUsers(data);
    });
  }, [showAddEditDialog]);

  const debouncedOnSearchChange = useMemo(() => {
    return debounce((search: string) => {
      if (!search.length) {
        return setFilteredUsers(usersData);
      }

      setFilteredUsers(usersData.filter((user) => {
        return fullName(user)
          .toLowerCase()
          .includes(search.toLowerCase()) ||
          user.evUser?.email?.toLowerCase()
          .includes(search.toLowerCase()) ||
          user.evUser?.userId?.toLowerCase()
          .includes(search.toLowerCase());
      }));
    }, 500);
  }, [usersData]);

  const fullName = (employee: any): string => {
    return `${employee.evUser?.firstName} ${employee.evUser?.lastName}`;
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
          <input type="text"
            placeholder="Search..."
            className="w-1/2 bg-white rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
            onChange={event => debouncedOnSearchChange(event.target.value)}
          />
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button
            type="button"
            onClick={onStartAddUser}
          >
            Add user
          </Button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
              <tr>
                <TableHeader>
                  User ID
                </TableHeader>
                <TableHeader>
                  Name
                </TableHeader>
                <TableHeader>
                  Security Role
                </TableHeader>
                <TableHeader>
                  Cell #
                </TableHeader>
                <TableHeader>
                  Email
                </TableHeader>
                <TableHeader>
                  Title
                </TableHeader>
                {ssoEnabled && (
                  <TableHeader>
                    SSO Enabled
                  </TableHeader>
                )}
                <TableHeader>
                  <ScreenReaderOnly>Edit</ScreenReaderOnly>
                </TableHeader>
              </tr>
              </thead>
              <tbody className="max-h-32 divide-y divide-gray-200">
                {
                  filteredUsers.map((employee) => (
                    <tr key={employee.id}>
                      <TableCell>
                        {employee.evUser.userId}
                      </TableCell>
                      <TableCell>
                        {employee.evUser.firstName} {employee.evUser.lastName}
                      </TableCell>
                      <TableCell>{employee?.securityLevel?.securityLevelName}</TableCell>
                      <TableCell>{formatPhone(employee.evUser.cellPhone)}</TableCell>
                      <TableCell>{employee.evUser?.emailAddress}</TableCell>
                      <TableCell>{employee.title}</TableCell>
                      {ssoEnabled && (
                        <TableCell>
                          {employee.evUser?.ssoUser?.ssoState ? 'Yes' : 'No'}
                        </TableCell>
                      )}
                      <TableCell>
                        <Button onClick={() => onStartEditUser(employee)}>
                          Edit<ScreenReaderOnly>, {employee.evUser.firstName}</ScreenReaderOnly>
                        </Button>
                      </TableCell>
                    </tr>
                  )
                )
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const selectCustomerHasSSO = createSelector(getAccount, (account: any) => account?.ssoEnabled ?? false);

interface TableProps {
  className?: string | undefined;
  children: React.ReactNode;
}

const TableHeader = ({
  className,
  children
}: TableProps) => {
  return (
    <th scope="col" className={classNames(className, "py-3.5 pl-4 pr-3 px-4 text-left text-sm font-semibold text-gray-900")}>
      {children}
    </th>
  );
};

const TableCell = ({
  className,
  children
}: TableProps) => {
  return (
    <td className={classNames(className, "whitespace-nowrap py-4 px-4 text-sm text-left")}>
      {children}
    </td>
  );
};

function formatPhone(phone: string) {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return null;
}
