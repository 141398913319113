import {AnyAction} from "@reduxjs/toolkit";
import {Theme} from "../types/Theme";
import {THEME_ACTION_TYPES} from "../actions/theme";

const defaultThemeState = {logo: null};

export default function themeReducer(state: Theme = defaultThemeState, action: AnyAction): Theme {
  switch (action.type) {
    case THEME_ACTION_TYPES.SET_LOGO:
      return {...state, logo: action.payload ? `data:image/png;base64,${action.payload}` : null};
    default:
      // console.log('Theme reducer: Missed action', action);
      return state;
  }
}
