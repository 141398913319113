import { useMemo } from "react";
import { getDatesBetween } from "../components/Pollworker/Panels/pollworkerVotingLocationDetailsUtils";
import { useSelector } from "react-redux";
import { AppState } from "../reducers";

export function useElectionDates() {
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);

  return useMemo(() => {
    if (!moduleInfo || !moduleInfo?.Election) return [];
    return getDatesBetween(moduleInfo?.Election?.DateStart, moduleInfo?.Election?.DateEnd);
  }, [moduleInfo?.Election]);
}

