import {fetchApi, getHeaders} from 'shared/src/fetchers';

export function getDocumentFilingProgressReport(documentFilingId: string) {
  return fetchApi(`/api/DocumentFilingApi/GetDocumentFilingsProgressReport/${documentFilingId}`, {
    method: 'GET',
    headers: getHeaders()
  }).then((response: Response) => response.json())
}

export function getCFDocuments() {
  return fetchApi('/api/CFDocumentApi/GetDocumentsForCustomer', {
    method: 'GET',
    headers: getHeaders()
  }).then((response: Response) => response.json())
}

export function uploadImportExcelFile(data: string) {
  return fetchApi('/api/CFFilerImportToolApi/UploadAndValidateFilerImport', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Data: data, Compress: false}),
  })
    .then((resp) => resp.json());
}

export function processImportExcelFile(data: string) {
  return fetchApi('/api/CFFilerImportToolApi/ImportFilersFromFile', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Data: data, Compress: false}),
  })
    .then((resp) => resp.json());
}
