import {PollworkerRequirementClass, PollworkerWorkHistory} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import {useState} from "react";
import {getRequirementClasses} from "admin/src/fetchers";

export default function useRequirementClasses(): { loadingRequirementClasses: boolean, loadRequirementClasses: (selectedPollworker: PollworkerWorkHistory) => Promise<PollworkerRequirementClass[]>, requirementClasses: PollworkerRequirementClass[] } {
  const [requirementClasses, setRequirementClasses] = useState<PollworkerRequirementClass[]>([]);
  const [loadingRequirementClasses, setLoadingRequirementClasses] = useState<boolean>(false);

  function loadRequirementClasses(selectedPollworker: PollworkerWorkHistory) {
    if (!selectedPollworker.userInfo.keyCustomerId) return Promise.reject();

    setLoadingRequirementClasses(true);

    return getRequirementClasses(selectedPollworker.userInfo.keyCustomerId).then((data) => {
      setRequirementClasses(data);
      return data;
    }).finally(() => {
      setLoadingRequirementClasses(false);
    });
  }

  return {loadRequirementClasses, loadingRequirementClasses, requirementClasses};
}
