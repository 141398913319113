import React, {useEffect, useState, Fragment, ReactNode, Suspense} from 'react';
import {useLocation, Link} from "wouter";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  CheckIcon, PencilSquareIcon,
  ShoppingCartIcon, TrashIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import {NavItem} from "../../components/NavItem";
import logo from '../../logo-v.png';
import { CartItem } from '../../types/CartItem';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../reducers";
import {logoutUser} from "../../actions/user";
import {classNames} from "shared/src/utils/classNames";
import {createOrder, getCart, getCartItems, getCustomerLogo, getUser, removeFromCart} from "../../fetchers";
import StoreItemImage, {StoreItemImageFallback} from "../store/StoreItemImage";
import { Flexor } from "shared/src/components"
import {OrderConfig} from "../../types/User";
import DisabledAppCoverall from "../DisabledAppCoverall";
import StoreItemAddToCartForm from "../store/StoreItemAddToCartForm";
import ButtonGoToStore from "../../components/ButtonGoToStore";
import {Button} from "shared/src/components/ui";
import UserOnly from "../../components/UserOnly";
import {CustomerInfo} from "../../types/CustomerInfo";
import {getParentAccountName} from "../../selectors/selectors";
import * as Sentry from 'shared/src/setupSentry';

export function UserApp({children}: {children: ReactNode}) {
  const [showApp, setShowApp] = useState(false);
  const [location, navigate] = useLocation();
  const [disabledMessage, setDisabledMessage] = useState<string>('');
  const cartItems = useSelector((state: AppState) => state.cart);
  const [itemToEdit, setItemToEdit] = useState<CartItem>();
  const user = useSelector((state: AppState) => state.user);
  const theme = useSelector((state: AppState) => state.theme);
  const storeName = useSelector(getParentAccountName);
  const dispatch = useDispatch();

  const lsToken = window.localStorage.getItem('zumo');

  useEffect(() => {
    if (!lsToken) return navigate('/login');

    // @ts-ignore
    dispatch(getUser())
    .then(({orderConfigs, hasFeatureEnabled, parentCustomerInfo}: {hasFeatureEnabled: boolean, orderConfigs: OrderConfig[], parentCustomerInfo: CustomerInfo}) => {
      if (!hasFeatureEnabled) {
        setDisabledMessage('EasyOrder has not been enabled for your parent account.');
        setShowApp(false);
        return;
      }

      if (!orderConfigs.find((config) => config.enabled)) {
        setDisabledMessage('Your account has not been configured for EasyOrder.');
        setShowApp(false);
        return;
      }

      // @ts-ignore
      Promise.all([getCart(), dispatch(getCartItems()), createOrder(), dispatch(getCustomerLogo(parentCustomerInfo.id))]).then(() => {
        setShowApp(true);
      });
    })
    .catch(logout);
  }, [lsToken]);

  useEffect(() => {
    if (user && user.user && user.account) {
      Sentry.identifyLoggedInUser(
        user.user.id,
        user.user.userId,
        user.user.emailAddress,
        user.account.id,
        user.account.customerName,
        user.account.state
      );
    }
  }, [user])

  function logout() {
    window.localStorage.removeItem('zumo');
    window.localStorage.removeItem('userId');
    window.localStorage.removeItem('customerId');
    setShowApp(false);
    dispatch(logoutUser());
    navigate('/login');
  }

  if (!!disabledMessage) {
    return (
      <Flexor className="flex-col">
        <DisabledAppCoverall message={disabledMessage}>
          <UserOnly>
            <Button variant='secondary' className="mt-5" onClick={() => {
              setDisabledMessage('');
              logout();
            }}>
              <ArrowLeftOnRectangleIcon className="h-5 w-5" />
              <span>Sign out</span>
            </Button>
          </UserOnly>
        </DisabledAppCoverall>
      </Flexor>
    )
  }

  if (!showApp || !lsToken) return null;

  return (
    <>
      <div className="text-xs p-2 bg-white relative border-b border-gray-100">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <Flexor>
            {/* TODO: "Alan County (TEST) has a account type of "0" which is unknown. */}
            {/* Normally this would be state. Negating ternary below to testing */}
            <span>{user?.account?.isCounty && `${user?.parentAccount?.customerName} / `}{user?.account?.customerName}</span>
            <span>{user?.user?.userId}</span>
          </Flexor>
        </div>
      </div>
      <Disclosure as="nav" className="bg-white shadow relative z-10">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <div className="-ml-2 mr-2 flex items-center md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ev-red">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex flex-shrink-0 items-center">
                    {
                      theme.logo ? (
                        <img
                          className="block h-14 mr-3 p-1 w-auto"
                          src={theme.logo}
                          alt={storeName + ' logo'}
                        />
                      ) : (
                        <img
                          className="p-0.5 h-10 mr-4 w-auto block"
                          src={logo}
                          alt="EasyVote, 'check' logo"
                        />
                      )
                    }
                  </div>
                  <div className="hidden md:ml-3 md:flex md:space-x-8">
                    <Link to="/store"><NavItem $active={location === '/store'}>Store</NavItem></Link>
                    <Link to="/orders"><NavItem $active={location === '/orders'}>Orders</NavItem></Link>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <ButtonGoToStore />
                  </div>
                  <div className="md:ml-2 flex flex-shrink-0 items-center">
                    <Menu as="div" className="relative ml-3 space-x-3">
                      <div>
                        <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-ev-red focus:ring-offset-2">
                          <span className="sr-only">View shopping cart</span>
                          <ShoppingCartIcon className="relative z-10 h-6 w-6 z-20" aria-hidden="true" />
                          <div className={`${classNames(cartItems.length ? 'visible' : 'invisible')} transition-all absolute z-20 scale-[90%] -bottom-2.5 -right-3 rounded-full min-w-4 px-1 py-0 m-0 p-0 bg-ev-red text-xs text-white font-bold pb-1`}>
                            <div className="pt-[1px] min-w-3 h-4">{cartItems.length}</div>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="overflow-y-auto absolute right-0 z-10 mt-5 max-h-96 w-72 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {
                            cartItems.map((item: CartItem, idx: number) => {
                              const editingItem = itemToEdit?.id === item.id;

                              return (
                                <Menu.Item key={`cart_item_${item.id}_${idx}`}>
                                  {({ active }) => (
                                    <div
                                      onClick={(e) => e.preventDefault()}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'flex flex-col justify-center h-20 block px-4 py-2 text-sm text-gray-700'
                                      )}
                                    >
                                      <div className="flex justify-between w-full">
                                        <Flexor className="space-x-4 shrink truncate">
                                          <div className="flex flex-col justify-center shrink-0">
                                            <Suspense fallback={<StoreItemImageFallback />}>
                                              <StoreItemImage item={item.inventoryEquipType} className="h-12 w-12 rounded-md" />
                                            </Suspense>
                                          </div>
                                          <div className="shrink truncate">
                                            {
                                              editingItem ? (
                                                <StoreItemAddToCartForm onItemUpdated={() => setItemToEdit(undefined)} item={itemToEdit.inventoryEquipType} mini />
                                              ) : (
                                                <>
                                                  <div className="text-md font-bold truncate" title={item.inventoryEquipType.equipTypeName}>{item.inventoryEquipType.equipTypeName}</div>
                                                  <div className="text-sm">Quantity: {item.quantity}</div>
                                                </>
                                              )
                                            }
                                          </div>
                                        </Flexor>
                                        <div className="flex items-center space-x-2 grow-1">
                                          {
                                            editingItem ? (
                                              <a onClick={() => setItemToEdit(undefined)} className="flex flex-col justify-center">
                                                <CheckIcon className="text-gray-400 hover:text-blue-600 transition-colors w-6 h-6" />
                                              </a>
                                            ) : (
                                              <a onClick={() => setItemToEdit(item)} className="flex flex-col justify-center">
                                                <PencilSquareIcon className="text-gray-400 hover:text-blue-600 transition-colors w-6 h-6" />
                                              </a>
                                            )
                                          }
                                          {/* @ts-ignore */}
                                          <a href="#" className="flex flex-col justify-center" onClick={() => dispatch(removeFromCart(item))}>
                                            <TrashIcon className="text-gray-400 hover:text-red-600 transition-colors w-6 h-6" />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Menu.Item>
                              )
                            })
                          }
                          {
                            !cartItems.length && (
                              <p className="text-gray-500 px-7 my-10 text-center">
                                You have not added any items to your cart yet!
                              </p>
                            )
                          }
                          <Menu.Button onClick={() => navigate('/checkout')} className="sticky mb-1 mt-3 active:ring-2 active:ring-ev-red border-2 border-white left-0 bottom-0 shadow-sm w-[95%] mx-auto rounded-md text-white flex items-center justify-center font-bold h-12 bg-ev-red">Checkout</Menu.Button>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <Menu as="div" className="relative ml-5">
                      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-ev-red focus:ring-offset-2">
                        <span className="sr-only">View notifications</span>
                        <ArrowLeftOnRectangleIcon className="h-6 w-6 z-20" aria-hidden="true" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-20 mt-5 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                                onClick={logout}
                              >
                                Sign out
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 pt-2 pb-3">
                {/* Current: "bg-ev-red border-ev-red text-ev-red", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
                >
                  Store
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6"
                >
                  Orders
                </Disclosure.Button>
              </div>
              <div className="border-t border-gray-200 pb-3">
                <div className="mt-3 space-y-1">
                  <Disclosure.Button
                    as="a"
                    href="#"
                    onClick={() => logout()}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                  >
                    Sign out
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
        {showApp ? children : null}
        <p className="w-full text-center text-xs mt-20 pb-10 text-gray-400">EasyOrder powered by EasyVote Solutions, Inc. © 2023 | Build: {process.env.NODE_ENV === 'development' ? 'DEV' : process.env.REACT_APP_VERSION?.substring(0, 7)}</p>
      </div>
    </>
  );
}
