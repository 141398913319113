import React, { forwardRef, InputHTMLAttributes, Suspense, useEffect, useRef, useState } from "react";
import { loadCustomerPermissions } from "admin/src/fetchers/setupAndAdmin";
import { FEATURES_IDS } from "../AdminApp";
import { getCustomerLogo } from "../../../fetchers";
import { useAppSelector } from "../../../hooks";
import { BlobImage, ImageFromFileReader } from "../../store/StoreItemImage";
import { Flexor, Spinner } from "shared/src/components";
import { AlertDescription, Alert, Switch, Button, Label, Input, Dropdown } from "shared/src/components/ui";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Heading, HeadingSub } from "shared/src/components/SectionHeading";
import { STATES } from "shared/src/utils/data/usStates";
import PhoneInput from "react-phone-number-input/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "shared/src/components/ui/Form";

type FeaturePermission = {
  enabled: boolean
  feature: typeof FEATURES_IDS[keyof typeof FEATURES_IDS]
  keyCustomerId: string
  module: number
  createdAt: string
  deleted: boolean
  id: string
  updatedAt: string
}

type ModulePermissions = {
  [key: string]: boolean
  easyAbsenteeClient: boolean
  easyChatClient: boolean
  easyCheckInClient: boolean
  easyFileClient: boolean
  easyFocusClient: boolean
  easyInventoryClient: boolean
  easyPollworkerClient: boolean
  easyShareClient: boolean
  voterServicesAppClient: boolean
}

const basicInfoSchema = z.object({
  name: z.string().min(2, {
    message: "Name must be at least 2 characters.",
  })
  .max(30, {
    message: "Name must not be longer than 30 characters.",
  })
});

export default function BasicInfo() {
  const [featurePermissions, setFeaturePermissions] = useState<FeaturePermission[]>([]);
  const [modulePermissions, setModulePermissions] = useState<ModulePermissions>({} as ModulePermissions);
  const { user: { keyCustomerId } } = useAppSelector(state => state.user);
  const [logoFile, setLogoFile] = useState<File>();
  const logoFileRef = useRef<HTMLInputElement>(null);
  const form = useForm<z.infer<typeof basicInfoSchema>>({
    resolver: zodResolver(basicInfoSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (!keyCustomerId) return;

    loadData();
  }, [keyCustomerId]);

  function loadData() {
    getCustomerLogo(keyCustomerId);

    loadCustomerPermissions().then(({ data: rawData }) => {
      const data = JSON.parse(atob(rawData));
      setFeaturePermissions(data.featurePermissions);
      setModulePermissions(data.modulePermissions);
    });
  }

  return (
    <>
      <div className='relative'>
        <Form {...form}>
          <form>
            <div className="mx-auto py-5 max-w-4xl space-y-6">
              <Alert>
                <AlertDescription>
                  <ul className='list-disc pl-5'>
                    <li>Finish converting the form to use <pre className='inline'>{"<Form />"}</pre></li>
                    <li>Get <pre className='inline'>StaticGlobals.EmployeeDetails.SecurityLevelInfo</pre> from the native app to determine if the user can change Subscriptions</li>
                    <li>Save the data</li>
                  </ul>
                </AlertDescription>
              </Alert>
              <div className="border-b border-gray-900/10 pb-12 w-3/4">
                <Heading size={4}>Information</Heading>
                <HeadingSub size={3}>Information describing your organization</HeadingSub>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                  <div className="sm:col-span-4 max-h-48">
                    <Label htmlFor="name">
                      Logo
                    </Label>

                    <Flexor>
                      <Suspense fallback={(<Spinner large={false} show/>)}>
                        {
                          logoFile ? (
                            <ImageFromFileReader alt='Selected expense receipt to upload' file={logoFile} classes='w-32 max-h-32 aspect-auto'/>
                          ) : (
                            <BlobImage className='w-32' skipCache customerId={keyCustomerId} id='logo.png' alt="Customer Logo"/>
                          )
                        }
                      </Suspense>
                      <input
                        type="file"
                        ref={logoFileRef}
                        className="hidden"
                        onChange={({ target: { files } }) => files?.length && setLogoFile(files[0])}
                      />
                      <Button type='button' onClick={() => logoFileRef.current?.click()} variant='primary'>
                        <PencilIcon className='size-4 mr-2'/>
                        Edit
                      </Button>
                    </Flexor>
                  </div>

                  <div className="sm:col-span-4">
                    <FormLabel htmlFor="name">
                      Organization name
                    </FormLabel>
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormControl>
                              <Input
                                variant="new"
                                type="text"
                                placeholder="My Great State"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )
                      }}
                    />
                  </div>

                  <div className="sm:col-span-4">
                    <div>
                      <Label htmlFor="address1">
                        Address 1
                      </Label>
                      <Input
                        variant="new"
                        type="text"
                        name="address1"
                        id="address1"
                        autoComplete="address-level1"
                        placeholder="123 Courthouse Way"
                      />
                    </div>
                    <div>
                      <Label htmlFor="address2">
                        Address 2
                      </Label>
                      <Input
                        variant="new"
                        type="text"
                        name="address2"
                        id="address2"
                        autoComplete="address-level2"
                        placeholder="P.0. Box #"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6 grid grid-cols-6 gap-6">
                    <div className="sm:col-span-2">
                      <Label htmlFor="city">
                        City
                      </Label>
                      <Input
                        variant="new"
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="city"
                        placeholder="Wilmington"
                      />
                    </div>
                    <div className="sm:col-span-1">
                      <Label htmlFor="state">
                        State
                      </Label>
                      <Dropdown
                        onChange={console.log}
                        options={STATES.map((state) => ({
                          label: state.state,
                          value: state.abbr
                        }))}
                        name="state"
                        variant="new"
                        id="state"
                        autoComplete="state"
                        placeholder="State"
                        emptyPlaceholder="No states"
                      />
                    </div>
                    <div className="sm:col-span-1">
                      <Label htmlFor="zip">
                        Zip
                      </Label>
                      <Input
                        variant="new"
                        type="number"
                        minLength={5}
                        maxLength={5}
                        name="zip"
                        id="zip"
                        autoComplete="postal-code"
                        placeholder="12345"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6 grid grid-cols-6 gap-6">
                    <div className="sm:col-span-2">
                      <Label htmlFor="mainPhone">
                        Main phone
                      </Label>
                      <PhoneInput
                        country="US"
                        placeholder="(___)-___-____"
                        id="mainPhone"
                        withCountryCallingCode={false}
                        inputComponent={forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
                          return <Input variant="new" ref={ref} {...props} />;
                        })}
                        onChange={console.log}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <Label htmlFor="smsPhone">
                        SMS Phone #
                      </Label>
                      <PhoneInput
                        country="US"
                        placeholder="(___)-___-____"
                        id="smsPhone"
                        withCountryCallingCode={false}
                        inputComponent={forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
                          return <Input variant="new" ref={ref} {...props} />;
                        })}
                        onChange={console.log}
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <Label htmlFor="faxPhone">
                        Fax
                      </Label>
                      <PhoneInput
                        country="US"
                        placeholder="(___)-___-____"
                        id="faxPhone"
                        withCountryCallingCode={false}
                        inputComponent={forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
                          return <Input variant="new" ref={ref} {...props} />;
                        })}
                        onChange={console.log}
                      />
                    </div>
                  </div>


                  <div className="sm:col-span-6 grid grid-cols-6 gap-6">
                    <div className="sm:col-span-2">
                      <Label htmlFor="fullStateName">
                        Full State Name
                      </Label>
                      <Input
                        variant="new"
                        type="text"
                        name="fullStateName"
                        id="fullStateName"
                        placeholder=""
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <Label htmlFor="fullStateName">
                        Registration code
                      </Label>
                      <Input
                        variant="new"
                        type="text"
                        name="fullStateName"
                        id="fullStateName"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-b border-gray-900/10 pb-12">
                <Heading size={4}>Subscription Administration</Heading>
                <HeadingSub size={3}>Enable customer feature subscriptions</HeadingSub>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-6 space-y-4">
                    {
                      modulePermissions ? Object.keys(modulePermissions)
                      .filter(p => p.startsWith('easy'))
                      .map((permissionKey) => {
                        const name = permissionKey.replace(/easy|Client/g, '').match(/[A-Z][a-z]*/g);

                        if (!name) return null;

                        const qualifiedName = name.join('-');

                        return (
                          <Flexor key={qualifiedName} justify='start' className='space-x-2'>
                            <Switch disabled id={qualifiedName} defaultChecked={modulePermissions[permissionKey]}/>
                            <Label htmlFor={qualifiedName}>Easy {qualifiedName}</Label>
                          </Flexor>
                        )
                      }) : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 sticky left-0 px-5 py-3 bg-white bottom-0 w-full">
              <Button variant='tertiary'>Cancel</Button>
              <Button variant='primary' type="submit">Save</Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  )
}
