import {createAction} from "@reduxjs/toolkit";
import {Login} from "../types/User";

export const USER_ACTION_TYPES = {
  LOADING_USER: 'LOADING_USER',
  SET_USER: 'SET_USER',
  LOGOUT_USER: 'LOGOUT_USER',
};

export type User = any;

export const loadingUser = createAction<boolean>(USER_ACTION_TYPES.LOADING_USER);
export const setUser = createAction<Login | undefined>(USER_ACTION_TYPES.SET_USER);
export const logoutUser = createAction<Login | undefined>(USER_ACTION_TYPES.LOGOUT_USER);
