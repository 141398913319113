import { Alert, AlertDescription, Button, Input } from 'shared/src/components/ui';
import React, {useRef, useState} from "react";
import {Text} from "shared/src/components/SectionHeading";
import { ArrowUpOnSquareIcon, DocumentIcon } from "@heroicons/react/24/outline";
import Spinner from "shared/src/components/Spinner";
import {uploadImportExcelFile, processImportExcelFile} from "../../fetchers/campaignFinance";
import {AgGridReact} from "ag-grid-react";
import LoadingOverlay from "../Pollworker/utils/LoadingOverlay";
import {RowGroupingModule} from "@ag-grid-enterprise/row-grouping";
import { Flexor } from "shared/src/components";
import { Dialog, DialogClose, DialogContent, DialogTrigger,DialogTitle } from 'shared/src/components/ui/Dialog';

const fileImporterColDef = [
  {
    field: 'userId',
    headerName: 'User ID',
  },
  {
    field: 'lastName',
  },
  {
    field: 'firstName',
  },
  {
    field: 'departmentBoardCommission',
  },
  {
    field: 'jobPosition',
  },
  {
    field: 'jobTitle'
  },
  {
    field: 'addressLine1',
  },
  {
    field: 'addressLine2',
  },
  {
    field: 'city',
  },
  {
    field: 'state',
  },
  {
    field: 'zip',
  },
  {
    field: 'emailAddress',
  },
  {
    field: 'status',
  },
  {
    field: 'mobileNumber',
  },
  {
    field: 'secondaryNumber',
  },
  {
    field: 'primaryNumber',
  },
];

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  enableCellChangeFlash: true,
};

export default function FilerImport() {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [showFileSelectionDialog, setShowFileSelectionDialog] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const [file, setFile] = useState<File>();
  const fileInput = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [fileValidationResult, setFileValidationResult] = useState<boolean>(false);
  const [fileValidationResultsMessage, setFileValidationResultsMessage] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [processResultsMessage, setProcessResultsMessage] = useState<string>('');

  function upload() {
    if (!file) return;
    setFileValidationResult(false);
    setFileValidationResultsMessage("");
    setRowData(null);

    setUploading(true);
    uploadImportFile(file).then(() => {
      setUploading(false);
      setShowFileSelectionDialog(false);
    }).catch(setError);
  }

  function process() {
    if (!file) return;
    setProcessing(true);
    setProcessResultsMessage("Please wait for the import process to complete");
    processImportFile(file).catch(setError)
  }

  function resetData() {
    setFile(undefined);
    setRowData(null);
  }

  function processImportFile(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        if (!event || !event.target) return;

        const arrayBuffer = event.target.result;

        if (!arrayBuffer) return;

        var fileToProcess;
        if (typeof arrayBuffer === "string") {
          fileToProcess = arrayBuffer.split(',').pop();
        }
        if (!fileToProcess) return;
        const processFileResponse = await processImportExcelFile(fileToProcess);
        const data = JSON.parse(atob(processFileResponse.data));
        setProcessing(false);
        if (processFileResponse.success) {
          setFileValidationResultsMessage('Import processed successfully');
        } else {
          setFileValidationResultsMessage(processFileResponse.errorMessage);
        }
        setProcessResultsMessage(processFileResponse.errorMessage);
        resolve({processFileResponse, file});
      }
      reader.readAsDataURL(file);
    });
  }

  function uploadImportFile(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        if (!event || !event.target) return;

        const arrayBuffer = event.target.result;

        if (!arrayBuffer) return;

        var fileToProcess;
        if (typeof arrayBuffer === "string") {
          fileToProcess = arrayBuffer.split(',').pop();
        }
        if (!fileToProcess) return;
        const uploadFileResponse = await uploadImportExcelFile(fileToProcess);

        const data = JSON.parse(atob(uploadFileResponse.data));
        setRowData(data)
        if (uploadFileResponse.success) {
          setFileValidationResultsMessage('Import file is valid for processing');
        } else {
          setFileValidationResultsMessage(uploadFileResponse.errorMessage);
        }
        setFileValidationResult(uploadFileResponse.success);
        resolve({uploadFileResponse, file});
      }
      reader.readAsDataURL(file);
    });
  }

  function onCancel() {
    setShowFileSelectionDialog(false);
  }
  return (
    <>
      {
        file ? (
          <div className="flex flex-col justify-between grow h-full">
            {
              fileValidationResultsMessage && !processing  && !fileValidationResult ?(
                <div className="p-4">
                  <Alert variant="error" data-testid='validation-failed-alert'>
                    <AlertDescription>{fileValidationResultsMessage}</AlertDescription>
                  </Alert>
                </div>
              ) : null
            }
            {
              fileValidationResultsMessage && !processing && fileValidationResult ?(
                <div className="p-4">
                  <Alert variant="success" data-testid='validation-success-alert'>
                    <AlertDescription>{fileValidationResultsMessage}</AlertDescription>
                  </Alert>
                </div>
              ) : null
            }
            {
              processResultsMessage && !processing ? (
                <div className="p-4">
                  <Alert variant="error" data-testid='process-failed-alert'>
                    <AlertDescription>{processResultsMessage}</AlertDescription>
                  </Alert>
                </div>
              ) : null
            }
            <div className="ag-theme-balham grow overflow-y-auto">
              <AgGridReact
                ref={gridRef}
                gridId="filingsProgressReport"
                getRowId={params => params.data.id}
                loadingOverlayComponent={() => <LoadingOverlay isPreview={false} animate infoText="Loading Filings Data..."/>}
                enableRangeSelection
                allowContextMenuWithControlKey
                animateRows={false}
                rowData={rowData}
                modules={[RowGroupingModule]}
                noRowsOverlayComponent={() => (<div>No data to display, choose a document type</div>)}
                columnDefs={fileImporterColDef}
                enableBrowserTooltips
                defaultColDef={defaultColDef}
              />
            </div>
            <Flexor className="space-x-2 p-4" justify="end">
              <Button variant="quaternary" onClick={() => resetData()}>Change file</Button>
              <Button
                type="button"
                disabled={!fileValidationResult || processing}
                onClick={process}
                className="space-x-2"
                data-testid='import-data-button'
              >
                {!processing ? null : <Spinner light show />}
                <span>Import User Data</span>
              </Button>
            </Flexor>
          </div>
        ) : (
          <div className="text-center fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <DocumentIcon className="mx-auto h-12 w-12 text-gray-400"/>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">No file selected</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by selecting a file from your computer.</p>
              <Button className="flex justify-center translate-x-2/3" type="button" data-testid='select-file'
              onClick={() => setShowFileSelectionDialog(true)}>Select Import File</Button>
          </div>
        )
      }
      <div>
      <Dialog open={showFileSelectionDialog} onOpenChange={setShowFileSelectionDialog}>
        <DialogContent>
          <DialogTitle>File Selection</DialogTitle>
          <div>
            <Input ref={fileInput} type="file" className="hidden" data-testid='file-selector'
              onChange={(event) => {
              const files = event.target.files || [];
              setFile(files[0]);
              setFileName(files[0]?.name);
            }}/>
            <Input className="-top-1" disabled type="text" placeholder="File name" value={fileName} id="file_name" name="file_name" />
            <div>
              <Button className="w-full" onClick={() => fileInput.current?.click()}>
                <span>{file ? 'Change' : 'Choose'} file</span>
              </Button>
              {file ? <Text className="text-right mt-2">A file has been selected</Text> : null}
              {error ? <Text variant='error' className="text-right mt-2">{error}</Text> : null}
            </div>
            <div className="border-t border-gray-300 pt-5 flex justify-between items-center">
              <DialogClose asChild>
                <button className="text-sm font-semibold">Cancel</button>
              </DialogClose>
              <Button data-testid='validate-file' onClick={upload} disabled={uploading || !fileName || !file} className="justify-center">
                {!uploading ? <ArrowUpOnSquareIcon className="w-5 h-5"/> : <Spinner show />}
                <span>Validate</span>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      </div>
    </>
  )
}

