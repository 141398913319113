import {combineReducers} from "redux";

import {PollworkerModuleInfo} from "../../types/Pollworker/PollworkerModuleInfo";
import gridReducer, { GridState } from './grid';
import schedulerReducer, { SchedulerState } from './scheduler';
import moduleInfoReducer from "./moduleInfo";
import settingsReducer, {SettingsState} from "./settings";
import detailsUiStackReducer, { DetailsUiStack } from "./detailsUiStack";

export type PollworkerAppState = {
  grid: GridState,
  scheduler: SchedulerState,
  moduleInfo: PollworkerModuleInfo | null,
  settings: SettingsState,
  detailsUiStack: DetailsUiStack,
}

export default combineReducers({
  grid: gridReducer,
  scheduler: schedulerReducer,
  settings: settingsReducer,
  moduleInfo: moduleInfoReducer,
  detailsUiStack: detailsUiStackReducer,
});
