import {fetchApi} from 'shared/src/fetchers';
import { Deployment, DeploymentDashboardEquipmentSummary } from '../types/Inventory';
import { DataburstResponse } from '../types/DataburstResponse';

export const fetchActiveDeployments = async (): Promise<DataburstResponse<Deployment[]>> => {
  const response = await fetchApi('/api/DeploymentApi/LoadActiveDeploymentsForCustomer', {
    method: 'POST'
  });

  const responseBody = await response.json();

  return {
    ...responseBody,
    data: responseBody.data ? JSON.parse(atob(responseBody.data)) : []
  } as DataburstResponse<Deployment[]>;
};

export const fetchActiveDeploymentsForCustomer = async (customerId: string): Promise<DataburstResponse<Deployment[]>> => {
  const response = await fetchApi(`/api/DeploymentApi/LoadActiveDeploymentsForCustomer/${customerId}`, {
    method: 'POST'
  });

  const responseBody = await response.json();

  return {
    ...responseBody,
    data: responseBody.data ? JSON.parse(atob(responseBody.data)) as Deployment[] : []
  } as DataburstResponse<Deployment[]>;
};

export const downloadInventorySealReport = async (deploymentId: string) => {
  const response = await fetchApi(`/api/ReportsApi/inventory/seals?deploymentId=${deploymentId}`, {
    method: 'GET'
  });

  return await response.blob();
};

export const fetchDeploymentEquipmentSummary = async (customerId: string, deploymentId: string): Promise<DeploymentDashboardEquipmentSummary[]> => {
  const response = await fetchApi(`/di-api/inventory/deployments/${deploymentId}/summaries/${customerId}`, {
    method: 'GET'
  }).then(httpResponse => httpResponse.json());

  return response;
};
