import React, {useState, useEffect, useMemo} from 'react';
import { Flexor } from "shared/src/components"
import {SectionHeading} from 'shared/src/components/SectionHeading';
import {useDispatch, useSelector} from "react-redux";
import {ConsumableItem} from "../../types/ConsumableItem";
import {
  getCart,
  getCartItems,
  getConsumables,
} from "../../fetchers";
import {getConsumableItems, getParentAccountName} from "../../selectors";
import StoreItem from "./StoreItem";
import StoreItemDetailsSlideUp from "./StoreItemDetailsSlideUp";
import {MagnifyingGlassCircleIcon} from "@heroicons/react/24/outline";
import {AppState} from "../../reducers";
import Loading from "shared/src/components/Loading";
import { Grid, GridCol, Input } from "shared/src/components/ui";

export default function Store() {
  const [itemNameFilter, setItemNameFilter] = useState<string>('');
  const [showItemDetails, setShowItemDetails] = useState<ConsumableItem>();
  const allConsumables = useSelector(getConsumableItems);
  const {fetchStatus: {loading: loadingStoreItems}} = useSelector((state: AppState) => state.consumables);
  const parentAccountName = useSelector(getParentAccountName);
  const dispatch = useDispatch();

  const consumables = useMemo(() => {
    if (!itemNameFilter) return allConsumables;

    return allConsumables.filter((item: ConsumableItem) => {
      if (!item.equipTypeName.toLowerCase().includes(itemNameFilter.toLowerCase())) return false;

      return item;
    });
  }, [itemNameFilter, allConsumables]);

  useEffect(() => {
    // @ts-ignore
    Promise.all([getCart(), dispatch(getCartItems()), dispatch(getConsumables())]);
  }, []);

  return (
    <>
      <div className="mb-4 w-full flex space-y-4 sm:space-y-0 justify-between items-center">
        <SectionHeading>{`${parentAccountName ? parentAccountName + ' ' : ''}`}Store</SectionHeading>
        <div className='w-1/3'>
          <Input
            type="text"
            id="search"
            name="search"
            autoFocus={false}
            placeholder="Search"
            onChange={({target: {value}}) => setItemNameFilter(value)}
          />
        </div>
        {/* Leave for debugging */}
        {/*<Button onClick={() => toggleAdmin()} $primary>Admin Mode: {isAdmin ? 'ON' : 'OFF'}</Button>*/}
      </div>
      <div className="relative">
        {
          loadingStoreItems && !consumables.length ? (
            <div className="absolute h-screen backdrop-blur-md top-0 left-0 w-full flex justify-center items-center">
              <Loading loadingMessage="Getting store items..." />
            </div>
          ) : null
        }
        {
          itemNameFilter && !consumables.length ? (
            <Flexor justify="center" className="pt-[3em] space-x-2 text-gray-800 text-center">
              <MagnifyingGlassCircleIcon className="h-5 w-5" />
              <span>No items found</span>
            </Flexor>
          ) : null
        }
        <Grid cols={1} className='sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4'>
          {
            consumables.map((item: ConsumableItem) => {
              return (
                <StoreItem
                  key={item.id}
                  item={item}
                  onShowItemDetails={() => setShowItemDetails(item)}
                />
              )
            })
          }
        </Grid>
      </div>
      <StoreItemDetailsSlideUp
        onShow={setShowItemDetails}
        showItemDetails={showItemDetails}
      />
    </>
  )
}
