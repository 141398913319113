import { fetchApi } from 'shared/src/fetchers';
import { setFeatureFlagCheck } from 'admin/src/actions/featureFlags';
import { AppDispatch } from 'admin/src/store';

export {
  checkFeatureFlag
}

async function checkFeatureFlag(dispatch: AppDispatch, flagName: string) {
  dispatch(setFeatureFlagCheck({
    loading: true,
    enabled: false,
    flag: flagName
  }));

  try {
    const response = await fetchApi(`/di-api/v1/customers/featureFlags/${flagName}`, {});
    const enabled: boolean = await response.json();

    dispatch(setFeatureFlagCheck({
      loading: true,
      enabled,
      flag: flagName
    }));
  } catch (e) {
    dispatch(setFeatureFlagCheck({
      loading: false,
      enabled: false,
      flag: flagName
    }));
  }
}
