import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "shared/src/utils/classNames";

export default function Loading({
  reload,
  loading
}: { reload: () => void, loading: boolean }) {
  return (
    <button>
      <ArrowPathIcon onClick={reload} className={classNames(loading ? 'animate-spin' : null, 'h-4 w-4')} />
    </button>
  )
}
