import { combineReducers } from 'redux';

import deploymentsReducer, { DeploymentState } from './deployments';
import inventoryUiReducer, { InventoryUiState } from './ui';

export type InventoryState = {
  deployments: DeploymentState,
  ui: InventoryUiState
}

export default combineReducers({
  deployments: deploymentsReducer,
  ui: inventoryUiReducer
})
