import {AnyAction} from "@reduxjs/toolkit";
import {ORDER_ACTION_TYPES} from "../actions/admin/order";
import {Order, OrderState} from "../types/Order";

const defaultOrderState = {
  data: [],
  fetchStatus: { loading: false, error: '' }
};

export default function ordersReducer(state: OrderState = defaultOrderState, action: AnyAction): OrderState {
  switch (action.type) {
    case ORDER_ACTION_TYPES.LOADED_ORDERS:
      return {...state, fetchStatus: {...state.fetchStatus, loading: false}};
    case ORDER_ACTION_TYPES.LOADING_ORDERS:
      return {...state, fetchStatus: {...state.fetchStatus, loading: true}};
    case ORDER_ACTION_TYPES.FAILED_LOADING_ORDERS:
      return {...state, fetchStatus: {...state.fetchStatus, loading: true}};
    case ORDER_ACTION_TYPES.ADD_ORDERS:
      return {...state, data: action.payload};
    case ORDER_ACTION_TYPES.UPDATE_ORDER_ITEM:
      return {...state, data: state.data.map((order) => {
        if (order.id !== action.payload.orderId) return order;

        return {...order, orderItems: order.orderItems.map((orderItem) => {
          if (orderItem.id !== action.payload.orderItemId) return orderItem;

          return {...orderItem, [action.payload.key]: action.payload.value};
        })}
      })};
    case ORDER_ACTION_TYPES.UPDATE_ORDER:
      return {...state, data: state.data.map((order: Order) => {
        if (order.id !== action.payload.id) return order;
        return {...order, ...action.payload}
      })};
    default:
      // console.log('[Orders Reducer] Missed action', action);
      return state;
  }
}
