import {GenericMenuItem} from "./contextMenu";
import {MenuItemDef} from "ag-grid-community";

export function agGridContextMenuAdapter(menuItems: GenericMenuItem[]): (string | MenuItemDef)[] {
  return menuItems.map((item: GenericMenuItem): MenuItemDef | string => {
    if (typeof item === 'string') return item;

    return { ...item, }
  });
}
