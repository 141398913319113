import {
  PollworkerRequirement,
  PollworkerRequirementStatus,
  PollworkerRequirementType
} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import React, { useEffect, useRef, useState } from "react";
import useSelectedPollworker from "admin/src/hooks/useSelectedPollworker";
import { useSelector } from "react-redux";
import { AppState } from "admin/src/reducers";
import dayjs from "dayjs";
import { uploadDocument } from "./utils/documentUpload";
import { savePollworkerRequirement, updatePollworkerRequirement } from "admin/src/fetchers";
import { CheckIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { classNames } from "shared/src/utils/classNames";
import { Flexor } from "shared/src/components";
import { Textarea, Button, Label, Dropdown, Input } from "shared/src/components/ui";
import Spinner from "shared/src/components/Spinner";
import usePollworkerDocuments from "./hooks/usePollworkerClasses";
import useRequirementClasses from "./hooks/usePollworkerDocuments";
import objectSupport from "dayjs/plugin/objectSupport";
import { StickyFooter } from "./ui/StickyFooter";

import './PollworkerRequirementsPanel.css';

dayjs.extend(objectSupport);

type RequirementsEditorSlideoutProps = {
  requirement?: PollworkerRequirement,
  onSave: () => void
}

// Fix: This could be a little odd if it is around midnight :/
const today = dayjs();

export default function RequirementsEditor({requirement, onSave}: RequirementsEditorSlideoutProps) {
  const [requirementId, setRequirementId] = useState<string>();
  const [selectedReqClassId, setSelectedReqClassId] = useState<string>();
  const [requirementType, setRequirementType] = useState<PollworkerRequirementType>(PollworkerRequirementType.TrainingClass);
  const [requirementDocumentId, setRequirementDocumentId] = useState<string>();
  const [requirementDueDate, setRequirementDueDate] = useState<string>();
  const [requirementDescription, setRequirementDescription] = useState<string>('');
  const [requirementNotes, setRequirementNotes] = useState<string>('');
  const [canSave, setCanSave] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const documentUploadRef = useRef<HTMLInputElement>(null);
  const selectedPollworker = useSelectedPollworker();
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const {loadingRequirementClasses, requirementClasses, loadRequirementClasses} = useRequirementClasses();
  const {loadingPollworkerDocuments, pollworkerDocuments, loadPollworkerDocuments} = usePollworkerDocuments();

  useEffect(() => {
    if (requirementType === PollworkerRequirementType.TrainingClass) loadRequirementClasses(selectedPollworker);
    if (requirementType === PollworkerRequirementType.Document) loadPollworkerDocuments(selectedPollworker);
  }, [selectedPollworker, requirementType]);

  useEffect(() => {
    if (!requirement) return;

    const dueDate = dayjs(requirement.dateDue);

    setRequirementId(requirement.id);
    setRequirementType(requirement.requirementType);
    setRequirementDueDate(dayjs(dueDate).format('YYYY-MM-DD'));
    setRequirementDescription(requirement.description);
    setRequirementNotes(requirement.notes);

    if (requirement.requirementType === PollworkerRequirementType.TrainingClass) {
      setSelectedReqClassId(requirement.requiredObjectId);
    }

    if (requirement.requirementType === PollworkerRequirementType.Document) {
      setRequirementDocumentId(requirement.requiredObjectId);
    }
  }, [requirement]);

  useEffect(() => {
    if (saving) return setCanSave(false);

    if (!requirementDescription.length || !requirementDueDate) return setCanSave(false);

    if (requirementType === PollworkerRequirementType.TrainingClass) {
      if (!selectedReqClassId) return setCanSave(false);
    }

    if (requirementType === PollworkerRequirementType.Document) {
      if (!requirementDocumentId) return setCanSave(false);
    }

    setCanSave(true);
  }, [saving, requirementNotes, requirementDescription, requirementDueDate, requirementType, requirementDocumentId, selectedReqClassId]);

  function attachDocument(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.length ? event.target.files[0] : undefined;

    // TODO: Show error to user
    if (!file) return;

    uploadDocument(selectedPollworker, selectedPollworker.pollworkerInfo.id, file).then((response) => {
      loadPollworkerDocuments(selectedPollworker);
      setRequirementDocumentId(response.doc.id);
    });
  }

  function saveRequirement() {
    if (!moduleInfo?.ElectionId) return;

    setSaving(true);

    const requirementToUpsert: Partial<PollworkerRequirement> = {
      requirementType,
      id: requirementId,
      notes: requirementNotes,
      description: requirementDescription,
      keyElectionId: moduleInfo?.ElectionId,
      status: requirement?.status || PollworkerRequirementStatus.NewStatus,
      dateDue: dayjs(requirementDueDate).toISOString(),
      keyEVUserId: selectedPollworker.pollworkerInfo.keyEVUserId,
      pollworkerLevel: selectedPollworker.pollworkerInfo.pollworkerLevel,
      requiredObjectId: requirementType === PollworkerRequirementType.Document ? requirementDocumentId : selectedReqClassId,
    };

    let upsertPromise;

    if (requirementId) {
      upsertPromise = updatePollworkerRequirement(requirementToUpsert);
    } else {
      upsertPromise = savePollworkerRequirement(requirementToUpsert);
    }

    upsertPromise.finally(() => {
      setSaving(false);
      onSave();
    });
  }

  function switchRequirementType(type: PollworkerRequirementType) {
    if (type === PollworkerRequirementType.Document) {
      setRequirementDocumentId(undefined);
      setRequirementType(PollworkerRequirementType.Document);
    } else {
      setSelectedReqClassId(undefined);
      setRequirementType(PollworkerRequirementType.TrainingClass);
    }
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="px-2">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
          >
            Type
          </label>
          <span className="isolate mt-2 inline-flex rounded-md shadow-sm w-full">
            <button
              type="button"
              disabled={!!requirement}
              onClick={() => switchRequirementType(PollworkerRequirementType.TrainingClass)}
              className={classNames(requirementType === PollworkerRequirementType.TrainingClass ? 'bg-green-200 text-green-900' : 'text-gray-900 bg-white hover:bg-gray-200 transition-colors', "disabled:opacity-50 w-full relative inline-flex justify-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10")}
            >
              Class
            </button>
            <button
              type="button"
              disabled={!!requirement}
              onClick={() => switchRequirementType(PollworkerRequirementType.Document)}
              className={classNames(requirementType === PollworkerRequirementType.Document ? 'bg-green-200 text-green-900' : 'text-gray-900 bg-white hover:bg-gray-200 transition-colors', "disabled:opacity-50 w-full relative -ml-px inline-flex justify-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10")}
            >
              Document
            </button>
          </span>
          <div className="border-t border-gray-200 mt-5">
            {
              requirementType === PollworkerRequirementType.TrainingClass ? (
                <div className="py-2 pb-4">
                  <Flexor>
                    <Label htmlFor="requirement_class">Classes</Label>
                    <div className="scale-75"><Spinner show={loadingRequirementClasses} /></div>
                    {!loadingRequirementClasses ? (
                      selectedReqClassId ? <CheckIcon className='text-green-500 h-4 w-4' /> : <span className="text-xs text-ev-red/80 font-semibold">Required</span>
                    ) : null}
                  </Flexor>
                  <div className="sm:col-span-2">
                    <Dropdown
                      id="requirement_class"
                      name="requirement_class"
                      placeholder="Select a class..."
                      emptyPlaceholder="No classes found"
                      disabled={!requirementClasses.length}
                      onChange={({target: {value}}) => setSelectedReqClassId(value)}
                      selectedValue={selectedReqClassId}
                      options={requirementClasses.map((klass) => ({value: klass.id, label: klass.className}))}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              ) : (
                <div className="py-2 pb-4">
                  <Flexor>
                    <label
                      htmlFor="pollworker_documents"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Documents
                    </label>
                    {
                      !loadingPollworkerDocuments ? (
                        <>
                          {
                            !requirementDocumentId ? (
                              <span className="text-xs text-ev-red/80 font-semibold">Required</span>
                            ) : (
                              <>
                                {
                                  documentUploadRef.current?.files?.length ? (
                                    <span className="text-xs text-green-500/80 font-semibold">Attached</span>
                                  ) : (
                                    <CheckIcon className="h-3 w-3 text-green-500" />
                                  )
                                }
                              </>
                            )
                          }
                        </>
                      ) : (
                        <div className="scale-75">
                          <Spinner show={loadingPollworkerDocuments} />
                        </div>
                      )
                    }
                  </Flexor>
                  <Input ref={documentUploadRef} type="file" className="hidden" onChange={attachDocument} />
                  <div className="flex items-center">
                    <div className='w-full'>
                      <Dropdown
                        id="pollworker_documents"
                        name="pollworker_documents"
                        emptyPlaceholder='No documents found, try attaching a new one'
                        options={pollworkerDocuments.map((doc) => ({label: doc.documentName, value: doc.id}))}
                        placeholder='Select a document...'
                        className="w-full rounded-l-md rounded-r-none border border-gray-500 disabled:border-gray-300 ring-0 border-r-0 py-2 pl-3 pr-10 text-gray-900"
                        onChange={({target: {value}}) => setRequirementDocumentId(value)}
                        selectedValue={requirementDocumentId}
                      />
                    </div>
                    <button onClick={() => documentUploadRef.current?.click()} className="rounded-r-md border border-gray-400 text-sm h-full py-2 px-2 flex space-x-1">
                      <PlusCircleIcon className="h-5 w-5" />
                      <span>Attach</span>
                    </button>
                  </div>
                </div>
              )
            }
            <div className="py-2 pb-4">
              <Flexor>
                <Label htmlFor="requirement_description">Description</Label>
                {requirementDescription.length ? <CheckIcon className='text-green-500 h-4 w-4' /> : <span className="text-xs text-ev-red/80 font-semibold">Required</span>}
              </Flexor>
              <div className="sm:col-span-2">
                <Input
                  type='text'
                  placeholder="You must describe the requirement..."
                  onChange={({target: {value}}) => setRequirementDescription(value)}
                  id="requirement_description"
                  name="requirement_description"
                  defaultValue={requirementDescription}
                />
              </div>
            </div>
            <div className="py-2 pb-4">
              <Flexor>
                <Label htmlFor="requirement_notes">Due Date</Label>
                { requirementDueDate ? <CheckIcon className='text-green-500 h-4 w-4'/> : <span className="text-xs text-ev-red/80 font-semibold">Required</span> }
              </Flexor>
              <div className="sm:col-span-2">
                <Input
                  type='date'
                  className='justify-between'
                  defaultValue={requirementDueDate}
                  min={today.format('YYYY-MM-DD')}
                  onChange={({target: {value}}) => setRequirementDueDate(value)}
                />
              </div>
            </div>
            <div className="py-4">
              <Flexor>
                <Label htmlFor="requirement_notes">Notes</Label>
                <span className="text-xs text-gray-500 italic font-semibold">Optional</span>
              </Flexor>
              <Textarea
                id="requirement_notes"
                name="requirement_notes"
                placeholder="Add any extra notes you may have for this requirement..."
                rows={3}
                defaultValue={requirementNotes}
                onChange={({target: {value}}) => setRequirementNotes(value)}
              />
            </div>
          </div>
        </div>
      </div>
      <StickyFooter>
        <Flexor justify='end' className="p-2 bg-gray-50 border-t border-gray-200">
          <Button
            type="submit"
            data-testid='save'
            disabled={!canSave}
            onClick={() => saveRequirement()}
          >
            <Spinner light show={saving} />
            <span>Add</span>
          </Button>
        </Flexor>
      </StickyFooter>
    </div>
  )
}
