import {createAction} from "@reduxjs/toolkit";

export const MODALS = {
  SET_STATUS: 'SET_STATUS',
  SET_SMS: 'SET_SMS',
  SEND_SMS: 'SEND_SMS',
  SET_DISPLAY_FLAGS: 'SET_DISPLAY_FLAGS',
  BULK_EDIT_OPTIONS: 'BULK_EDIT_OPTIONS',
}

export const MODAL_ACTION_TYPES = {
  SHOW: 'SHOW',
  HIDE: 'HIDE'
};

export const showModal = createAction<string>(MODAL_ACTION_TYPES.SHOW);
export const hideModal = createAction<string>(MODAL_ACTION_TYPES.HIDE);
