import {
  addCartItems,
  CART_ACTION_TYPES, clearCart,
  failedLoadingCart,
  loadedCart,
  loadingCart, removeItem
} from "../actions/cart";
import Cache, {imageCacheKey} from "../utils/cache";
import {ConsumableItem} from "../types/ConsumableItem";
import {showToast} from "../actions/toast";
import {addOrders, failedLoadingOrders, loadedOrders, loadingOrders} from "../actions/admin/order";
import {addConsumables, failedLoadingConsumables, loadedConsumables, loadingConsumables} from "../actions/consumable";
import {loadingUser, setUser} from "../actions/user";
import {Order, OrderHistoryPayload, OrderItem} from "../types/Order";
import {CartItem} from "../types/CartItem";
import {OrderConfig} from "../types/User";
import {
  AssignPollworkersToElectionPayload,
  PollworkerApiResponse,
  PollworkerContact,
  PollworkerLog,
  PollworkerRequirement,
  PollworkerTimeClockAction,
  PollworkerWorkAssignmentConfirmationsForElection,
  PollworkerWorkHistory,
  Schedule,
  UserInfo,
  VotingLocationSchedule,
} from "../types/Pollworker/PollworkerWorkHistory";
import {setLogo} from "../actions/theme";
import {WorkConfirmationStatusValue} from "../components/Pollworker/WorkConfirmationStatusEnum";
import {ApiResponse} from "../types/Fetch";
import {Expense, PollworkerReimbursementStatus} from "../types/Pollworker/PollworkerModuleInfo";
import { fetchApi, fetchApiWithAbort, getHeaders } from 'shared/src/fetchers';
import { fetchEmployees } from './setupAndAdmin';
import { DataburstResponse } from '../types/DataburstResponse';
import dayjs from "dayjs";
import { VotingLocationWithAssignments } from "../components/Pollworker/Panels/pollworkerVotingLocationDetailsUtils";

export {
  getHeaders,
  fetchEmployees
}

export * from './featureFlags';
export * from './inventory';

// TODO: Separate these fetchers into logically-organized files

export function getClassesForElection(customerId: string, electionId: string) {
  return fetchApi('/api/GetClassesForElection', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({id: customerId, electionId}),
  })
  .then((resp) => resp.json());
}

export function addAttendeeToClassSession(evUserId: string, sessionId: string) {
  return fetchApi('/api/AddAttendeeToClassSession', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({EVUserId: evUserId, SessionId: sessionId, compress: false}),
  })
  .then((resp) => resp.json());
}

export function loadPollworkerExpenses(electionId: string, evUserId: string) {
  return fetchApi('/api/PollworkerReimbursementApi/LoadPollworkerReimbursementsByParameter', {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({Id: evUserId, ElectionId: electionId, compress: false}),
    })
    .then((resp) => resp.json());
}

export function markExpenseStatus(expenses: Partial<Expense>[], status: PollworkerReimbursementStatus) {
  const expenseIds = expenses.map((e) => e.id);

  return fetchApi(`/api/PollworkerReimbursementApi/SavePollworkerReimbursementStatus`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({IdLIst: expenseIds, StatusValue: status}),
  })
  .then((resp) => resp.json());
}

export function getElectionsForCustomer() {
  return fetchApi('/api/ElectionApi/LoadElectionsForCustomer', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Compress: false}),
  })
  .then((resp) => resp.json());
}

export function getPollworkerSettings(customerId: string) {
  return fetchApi('/api/SettingsApi/LoadPollworkerSettings', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: customerId, Compress: false}),
  })
  .then((resp) => resp.json());
}

export function createPollworkerExpense(expense: Partial<Expense>) {
  return fetchApi(`/tables/PollworkerReimbursement/PostPollworkerReimbursement`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(expense),
  })
  .then((resp) => resp.json());
}

export function updateCustomField(customFieldId: string, customFields: {[key: string]: string}) {
  return fetchApi('/api/CustomFieldContentApi/SaveCustomFieldContent', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: customFieldId, Data: customFields}),
  })
  .then((resp) => resp.json());
}

export function patchPollworkerExpense(expense: Partial<Expense>) {
  return fetchApi(`/tables/PollworkerReimbursement/PatchPollworkerReimbursement/${expense.id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(expense),
  })
  .then((resp) => resp.json());
}

export function pollworkerClockInOut(pollworkerWorkHistoryId: string) {
  return fetchApi(`/api/PollworkerTimeClockActionApi/PollworkerClockInOut`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: pollworkerWorkHistoryId, Notes: ''}),
  })
  .then((resp) => resp.json());
}

export function createPollworkerSchedule(schedule: Partial<Schedule>) {
  return fetchApi(`/tables/PollworkerSchedule/PostPollworkerSchedule`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(schedule),
  })
  .then((resp) => resp.json());
}

export function patchPollworkerSchedule(schedule: Partial<Schedule>) {
  return fetchApi(`/tables/PollworkerSchedule/PatchPollworkerSchedule/${schedule.id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(schedule),
  })
  .then((resp) => resp.json());
}

export function deletePollworkerSchedule(schedule: Partial<Schedule>) {
  return fetchApi(`/tables/PollworkerSchedule/DeletePollworkerSchedule/${schedule.id}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });
}

export function createPollworkerClockInOut(timeClockAction: Partial<PollworkerTimeClockAction>) {
  return fetchApi(`/tables/PollworkerTimeClockAction/PostPollworkerTimeClockAction`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(timeClockAction),
  })
  .then((resp) => resp.json());
}

export function assignToElection(payload: AssignPollworkersToElectionPayload) {
  return fetchApi(`/api/AddPollworkersToElection`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(payload),
  })
  .then((resp) => resp.json());
}

export function patchPollworkerClockInOut(timeClockAction: Partial<PollworkerTimeClockAction>, timeClockId: string) {
  return fetchApi(`/tables/PollworkerTimeClockAction/PatchPollworkerTimeClockAction/${timeClockId}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(timeClockAction),
  })
  .then((resp) => resp.json());
}

export function deletePollworkerClockInOut(timeClockId: string) {
  return fetchApi(`/tables/PollworkerTimeClockAction/DeletePollworkerTimeClockAction/${timeClockId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
  .then((resp) => resp.json());
}

export function getPollworkerTimeEntriesForElection(electionId: string, pollworkerEvUserIds: string[]) {
  return fetchApi(`/api/PollworkerTimeClockActionApi/LoadPollworkerTimeEntriesForElection`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: electionId, List: pollworkerEvUserIds, Compress: false}),
  })
  .then((resp) => resp.json());
}

export function removeAttendeeFromClassSession(sessionId: string) {
  return fetchApi(`/tables/PollworkerClassAttendee/${sessionId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
  .then((resp) => resp.json());
}

export function getPollworkerClassMatrix(trainingClassId: string, electionId: string) {
  return fetchApi('/api/GetPollworkerClassMatrixData', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({id: trainingClassId, electionId, compress: false}),
  })
  .then((resp) => resp.json());
}

export function getPollworkerRequiredClassesToBeToken(keyEvUserId: string, electionId: string) {
  return fetchApi('/api/PollworkerApi/LoadRequiredClassesToBeTaken', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({id: keyEvUserId, electionId}),
  })
  .then((resp) => resp.json());
}

export function getPollworkerTrainingClassSessionList(electionId: string, classId: string) {
  return fetchApi('/api/getPollworkerTrainingClassSessionList', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({id: classId, ElectionId: electionId}),
  })
  .then((resp) => resp.json());
}

export function savePollworkerRequirement(requirement: Partial<PollworkerRequirement>) {
  return fetchApi('/tables/PollworkerRequirement', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(requirement),
  })
  .then((resp) => resp.json());
}

export function deletePollworkerRequirement(requirementId: string) {
  return fetchApi(`/tables/PollworkerRequirement/${requirementId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
  .then((resp) => resp.json());
}

export function updatePollworkerRequirement(requirement: Partial<PollworkerRequirement>) {
  return fetchApi(`/tables/PollworkerRequirement/${requirement.id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(requirement),
  })
  .then((resp) => resp.json());
}

export function getDocumentsForPollworker(id: string) {
  return fetchApi('/api/DocumentsApi/LoadDocumentsForObject', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: id})
  })
  .then((resp) => resp.json());
}

export function uploadFileToStorage(url: string, data: string) {
  return fetchApi('/api/BlobStorageApi/UploadDataFileToStorage', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: url, Data: data, Compress: false}),
  })
  .then((resp) => resp.json());
}

export function blobStorageFileExists(idUrl: string) {
  return fetchApi('/api/BlobStorageApi/FileExists', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: idUrl}),
  })
  .then((resp) => resp.json());
}

export function createDocument(document: any) {
  return fetchApi('/tables/Document/PostDocument', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(document),
  })
  .then((resp) => resp.json());
}

export function deleteDocument(documentId: string) {
  return fetchApi(`/tables/Document/${documentId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });
}

export function deletePollworkerEmergencyContact(emergencyContactId: string) {
  return fetchApi(`/tables/EmergencyContact/DeleteEmergencyContact/${emergencyContactId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
  .then((resp) => resp.json());
}

export function savePollworkerCallLog(pollworkerId: string, notes: string, reason: number, keyElectionId?: string) {
  return fetchApi(`/tables/PollworkerLog/PostPollworkerLog`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({keyEVUserId: pollworkerId, notes, reasonId: reason, keyElectionId}),
  })
  .then((resp) => resp.json());
}

export function patchPollworkerCallLog(log: PollworkerLog) {
  return fetchApi(`/tables/PollworkerLog/PatchPollworkerLog/${log.id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(log),
  })
  .then((resp) => resp.json());
}

export function deletePollworkerCallLog(log: PollworkerLog) {
  return fetchApi(`/tables/PollworkerLog/DeletePollworkerLog/${log.id}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
  .then((resp) => resp.json());
}


export function createPollworkerEmergencyContact(contact: Partial<PollworkerContact>) {
  return fetchApi(`/tables/EmergencyContact/PostEmergencyContact`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(contact),
  })
  .then((resp) => resp.json());
}

export function patchPollworkerEmergencyContact(contact: Partial<PollworkerContact>) {
  return fetchApi(`/tables/EmergencyContact/PatchEmergencyContact/${contact.id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(contact),
  })
  .then((resp) => resp.json());
}

export function saveSkillRating(keyElectionId: string, keyEVUserId: string, keySkillId: string, rating: number) {
  return fetchApi(`/tables/PollworkerSkillRating`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({keyElectionId, keyEVUserId, keySkillId, rating}),
    })
    .then((resp) => resp.json());
}

export function deleteSkillRating(id: string) {
  return fetchApi(`/tables/PollworkerSkillRating/${id}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
  .then((resp) => resp.json());
}

export function getRequirementClasses(keyCustomerId: string) {
  return fetchApi(`/tables/PollworkerTrainingClass?$filter=${encodeURIComponent(`(KeyCustomerId eq '${keyCustomerId}')`)}`, {
    method: 'GET',
    headers: getHeaders(),
  })
  .then((resp) => resp.json());
}

export function updateSkillRating(id: string, keyElectionId: string, keyEVUserId: string, keySkillId: string, rating: number, dateRated: string) {
  return fetchApi(`/tables/PollworkerSkillRating/${id}`, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify({id, keyElectionId, keyEVUserId, keySkillId, rating, dateRated}),
    })
    .then((resp) => resp.json());
}

export function loadRequirementsListForElectionAndUser(userId: string, electionId: string) {
  return fetchApi(`/api/LoadRequirementsListForElectionAndUser`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: userId, electionId})
  })
  .then((resp) => resp.json());
}

export function loadPollworkerLogs(startDate: string, endDate: string, userId: string) {
  return fetchApi(`/api/PollworkerLogApi/LoadLogsByParameters`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: userId, StartDate: startDate, EndDate: endDate, Compress: false}),
  })
  .then((resp) => resp.json());
}

// TODO: Insecure api
export function loadPollworkerSkills(electionId: string, userId: string) {
  return fetchApi(`/tables/PollworkerSkillRating?$filter=${encodeURIComponent(`((KeyElectionId eq '${electionId}') and (KeyEVUserId eq '${userId}'))`)}`, {
    method: 'GET',
    headers: getHeaders(),
  })
  .then((resp) => resp.json());
}

export function loadPollworkerContacts(userId: string) {
  return fetchApi(`/api/EmergencyContactsApi/LoadContactsForUser`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: userId, Compress: false})
  })
  .then((resp) => resp.json());
}

export function login(username: string, password: string): Promise<any> {
  return fetchApi('/api/EVLogin', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Username: username, Password: password})
  })
  .then(async (response) => [response.ok, await response.json()]);
}

export function getCustomerOrders() {
  return fetchApi('/api/Storeify/Orders/GetCustomerOrders', {
    headers: getHeaders(),
    method: 'POST',
  }).then((response: Response) => response.json());
}

export function getCountyConfigs(): Promise<DataburstResponse<OrderConfig[]>> {
  return fetchApi('/api/Storeify/Orders/GetCountyConfigs', {
    headers: getHeaders(),
    method: 'GET',
  })
  .then((response: Response) => response.json())
  .then((response: any) => ({
    ...response,
    data: JSON.parse(atob(response.data)) as OrderConfig[]
  }));
}

export function createOrder() {
  return fetchApi('/api/Storeify/Orders/CreateOrders', {
    headers: getHeaders(),
    method: 'POST',
  })
  .then((response: Response) => response.json());
}

export function updateOrderAsShipped(order: Order, shippingNote: string) {
  return fetchApi(`/api/Storeify/Orders/UpdateOrderAsShipped`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify({...order, shippingNote}),
  }).then((response: Response) => response.json());
}

export function updateOrderStatus(orderId: string, status: number) {
  return fetchApi(`/api/Storeify/Orders/UpdateStatus`, {
    headers: getHeaders(),
    method: 'PUT',
    body: JSON.stringify({orderId, status})
  })
  .then((response: Response) => response.json());
}

export function updateOrderItemQuantity(orderId: string, orderItemId: string, quantity: number) {
  return fetchApi(`/api/Storeify/Orders/UpdateOrderItemQuantity/${orderId}/${orderItemId}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify({
      quantity,
    })
  })
  .then((response: Response) => response.json());
}

export function removeOrderItem(orderId: string, orderItemId: string) {
  return fetchApi(`/api/Storeify/Orders/RemoveOrderItem/${orderId}/${orderItemId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  })
  .then((response: Response) => response.json());
}

export function getCart() {
  return fetchApi('/api/Storeify/Orders/GetCart', {
    headers: getHeaders(),
    method: 'GET',
  })
  .then((response: Response) => response.json());
}

export function saveConsumable(item: ConsumableItem) {
  return fetchApi(`/tables/InventoryEquipType/PatchInventoryEquipType?id=${item.id}`, {
    headers: getHeaders(),
    method: 'PATCH',
    body: JSON.stringify({...item}),
  }).then((response: Response) => response.json());
}

export function getConsumable(itemId: string) {
  return fetchApi(`/api/Storeify/Orders/GetStoreInventoryItem/${itemId}`, {
    headers: getHeaders(),
    method: 'GET',
  }).then((response: Response) => response.json());
}

export function getPollworkerEvUser(id: string) {
  return fetchApi(`/tables/EVUser/GetEVUser?id=${id}`, {
    headers: getHeaders(),
    method: 'GET',
  }).then((response: Response) => response.json());
}

export function getPollworkerWorkHistoryReportsTo(electionId: string) {
  return fetchApi(`/api/GetPollworkersWhoCanHaveReportsTo`, {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({Id: electionId}),
  }).then((response: Response) => response.json());
}

export function getPollworkerPollworkerWorkHistory(id: string) {
  return fetchApi(`/tables/PollworkerWorkHistory/${id}`, {
    headers: getHeaders(),
    method: 'GET',
  }).then((response: Response) => response.json());
}

export function updatePollworkerEvUser(record: UserInfo) {
  return fetchApi(`/tables/EVUser/PatchEVUser?id=${record.id}`, {
    headers: getHeaders(),
    method: 'PATCH',
    body: JSON.stringify({...record}),
  }).then((response: Response) => response.json());
}

export function updatePollworkerWorkHistory(record: any) {
  return fetchApi(`/tables/PollworkerWorkHistory/${record.id}`, {
    headers: getHeaders(),
    method: 'PATCH',
    body: JSON.stringify({...record}),
  }).then((response: Response) => response.json());
}

export function getNomenclature() {
  return fetchApi('/api/NomenclatureDefinition/LoadNomenclatureList', {
    headers: getHeaders(),
    method: 'POST',
  }).then((response: Response) => response.json());
}

export function updatePollworkerWorkHistoryWorkConfirmation(pollworkerWorkHistoryWorkConfirmation: PollworkerWorkAssignmentConfirmationsForElection, status: WorkConfirmationStatusValue | number) {
  return fetchApi(`/tables/PollworkerWorkHistoryAssignmentConfirmation/${pollworkerWorkHistoryWorkConfirmation.id}`, {
    headers: getHeaders(),
    method: 'PATCH',
    body: JSON.stringify({
      id: pollworkerWorkHistoryWorkConfirmation.id,
      pollworkerWorkHistoryId: pollworkerWorkHistoryWorkConfirmation.pollworkerWorkHistoryId,
      electionId: pollworkerWorkHistoryWorkConfirmation.electionId,
      status: status
    }),
  }).then((response: Response) => response.json());
}

export function createPollworkerWorkHistoryWorkConfirmation(electionId: string, pollworkerWorkHistoryId: string, status: number) {
  return fetchApi('/tables/PollworkerWorkHistoryAssignmentConfirmation', {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({electionId, pollworkerWorkHistoryId, status}),
  }).then((response: Response) => response.json());
}

export function getPartiesForCustomer(customerId: string) {
  return fetchApi(`/tables/Party/GetForCustomer/${customerId}`, {
    headers: getHeaders(),
    method: 'GET',
  }).then((response: Response) => response.json());
}

export function updatePollworker(record: PollworkerWorkHistory) {
  return fetchApi(`/tables/Pollworker/PatchPollworker?id=${record.id}`, {
    headers: getHeaders(),
    method: 'PATCH',
    body: JSON.stringify({...record}),
  }).then((response: Response) => response.json());
}

export function getPollworkerRoleTypes() {
  return fetchApi('/tables/PollworkerRole', {
    headers: getHeaders(),
    method: 'GET',
  }).then((response: Response) => response.json());
}

export function getPollworkerRoles(customerId: string) {
  return fetchApi('/api/GetPollworkerRoles', {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({Id: customerId}),
  }).then((response: Response) => response.json());
}

export function getBasePrecinctListForCustomer(customerId: string) {
  return fetchApi('/api/PrecinctApi/LoadBasePrecinctListForCustomer', {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({Id: customerId}),
  }).then((response: Response) => response.json());
}

export function getPollworkerSchedulesList(keyUserId: string, keyElectionId: string): Promise<DataburstResponse<Schedule[]>> {
  return fetchApi('/api/PollworkerScheduleApi/LoadPollworkerSchedulesList', {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({Id: keyUserId, ElectionId: keyElectionId}),
  }).then(async (response: Response) => await response.json() as DataburstResponse<any>)
    .then(json => ({
      ...json,
      data: JSON.parse(atob(json.data))
    }) as DataburstResponse<Schedule[]>)
    .then(schedulesResponse => {
      schedulesResponse.data = schedulesResponse.data.map(schedule => ({
        ...schedule,
        workDate: dayjs.utc(schedule.workDate).format('LL')
      }));
      return schedulesResponse;
    });
}

export function deletePollworkerWorkHistory(keyPollworkerWorkHistoryId: string) {
  return fetchApi(`/tables/PollworkerWorkHistory/${keyPollworkerWorkHistoryId}`, {
    headers: getHeaders(),
    method: 'DELETE',
  });
}

export function getPrecincts() {
  return fetchApi('/api/PrecinctApi/LoadSimplePrecinctList', {
    headers: getHeaders(),
    method: 'POST',
  }).then((response: Response) => response.json());
}

export function getPrecinctsForElection(electionId: string) {
  return fetchApi('/api/PrecinctApi/LoadElectionPrecinctList', {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({Id: electionId}),
  }).then((response: Response) => response.json());
}

export function getVotingLocations(customerId: string) {
  return fetchApi('/api/VotingLocationApi/LoadCoreLocations', {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({Compress: false, Id: customerId}),
  }).then((response: Response) => response.json());
}

export function getVotingLocationsForElection(electionId: string) {
  return fetchApi('/api/VotingLocationApi/LoadListForElectionNoDocuments', {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({Compress: false, Id: electionId}),
  }).then((response: Response) => response.json());
}

export async function loadPollworkerSchedulesForLocation(votingLocationId: string, electionId: string): Promise<DataburstResponse<VotingLocationSchedule[]>> {
  return fetchApi(`/api/PollworkerScheduleApi/LoadPollworkerSchedulesForLocation`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ Id: votingLocationId, ElectionId: electionId }),
  }).then(async (resp) => await resp.json())
    .then((databurstResponse: DataburstResponse<any>) => ({
      ...databurstResponse,
      data: JSON.parse(atob(databurstResponse.data)) as VotingLocationSchedule[]
    }))
    .then((databurstResponse: DataburstResponse<VotingLocationSchedule[]>) => {
      databurstResponse.data = databurstResponse.data.map(schedule => ({
        ...schedule,
        workDate: dayjs.utc(schedule.workDate).format('YYYY-MM-DD')
      }))
      return databurstResponse;
    });
}

export async function loadFullListForLocationWithAssignments(votingLocationId: string): Promise<DataburstResponse<VotingLocationWithAssignments[]>> {
  return fetchApi(`/api/PollworkerLocationRoleApi/LoadFullListForLocationWithAssignments`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ Id: votingLocationId }),
  }).then((resp) => resp.json())
    .then((databurstResponse: DataburstResponse<any>) => ({
      ...databurstResponse,
      data: JSON.parse(atob(databurstResponse.data)) as VotingLocationWithAssignments[]
    }))
    .then((databurstResponse: DataburstResponse<VotingLocationWithAssignments[]>) => {
      databurstResponse.data = databurstResponse.data.map((votingLocationWithAssignment: VotingLocationWithAssignments) => ({
        ...votingLocationWithAssignment,
        assignments: votingLocationWithAssignment.assignments.map(assignment => ({
          ...assignment,
          assignedDate: dayjs.utc(assignment.assignedDate).format('YYYY-MM-DD')
        }))
      }));
      return databurstResponse;
    });
}

export const getConsumables = () => {
  return async (dispatch: Function) => {
    dispatch(loadingConsumables());
    return fetchApi('/api/Storeify/Orders/GetStoreInventoryItems', {
      headers: getHeaders(),
      method: 'GET',
    })
    .then((response: Response) => response.json())
    .then((result) => {
      dispatch(loadedConsumables());
      dispatch(addConsumables(JSON.parse(atob(result.data))));
    }).catch((e) => {
      dispatch(failedLoadingConsumables());
      console.error('Error getting consumables', e);
    });
  }
}

export function getInventoryItemDetails(itemId: string): [Promise<any>, AbortController] {
  const [requestPromise, controller] = fetchApiWithAbort(`/api/Storeify/Orders/InventoryLevels/${itemId}/Details`, {
    headers: getHeaders(),
    method: 'GET',
  });

  return [requestPromise.then((response: Response) => response.json()), controller];
}

export function getStats() {
  return fetchApi('/api/Storeify/Orders/GetStats', {
    headers: getHeaders(),
    method: 'GET',
  }).then((response: Response) => response.json());
}

export function getInventoryLevels() {
  return fetchApi('/api/Storeify/Orders/InventoryLevels', {
    headers: getHeaders(),
    method: 'GET',
  }).then((response: Response) => response.json());
}

export const getUser = () => {
  return async (dispatch: Function) => {
    dispatch(loadingUser(true));

    return fetchApi('/api/Storeify/Orders/Me', {
      headers: getHeaders(),
      method: 'POST',
    })
    .then((response: Response) => response.json())
    .then(({data, success}) => {
      if (success) {
        const response = JSON.parse(atob(data));
        const {orderConfigs, userInfo, customerInfo, parentCustomerInfo = {}, hasFeatureEnabled, features = []} = response;

        customerInfo.isState = customerInfo.customerType < 3;
        customerInfo.isCounty = !customerInfo.isState;
        parentCustomerInfo.isState = parentCustomerInfo.customerType < 3;
        parentCustomerInfo.isCounty = !parentCustomerInfo.isState;

        dispatch(setUser({
          orderConfigs,
          enabled: hasFeatureEnabled,
          user: userInfo,
          account: customerInfo,
          parentAccount: parentCustomerInfo,
          features,
        }));

        return response;
      }
    })
    .catch((err) => {
      console.error('Could not load user', err);
    })
    .finally(() => dispatch(loadingUser(false)));
  };
}

export const submitOrder = (notes: string) => {
  return async (dispatch: Function) => {
    return fetchApi('/api/Storeify/Orders/SubmitOrderFromCart', {
      headers: getHeaders(),
      method: 'POST',
      body: JSON.stringify({notes}),
    })
    .then((response: Response) => response.json())
    .then(({data, success, msg}) => {
      dispatch(clearCart());
      dispatch(createOrder());
    })
  }
}

export function upsertCountyConfig(countyConfig?: OrderConfig) {
  return fetchApi('/api/Storeify/Orders/PutCountyConfig', {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify(countyConfig || {}),
  })
  .then((response: Response) => response.json())
}

export function getPollworkers(electionId: string, workerLevel: number, lastNameStartLetter: string = '*'): Promise<DataburstResponse<PollworkerApiResponse>> {
  return fetchApi('/api/PollworkerApi/LoadPollworkerWorkHistoryList', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Compress: false, LastNameStartLetter: lastNameStartLetter, WorkerLevel: workerLevel, ElectionId: electionId})
  }).then((response: Response) => response.json())
    .then((databurstReponse: DataburstResponse<any>) => ({
      ...databurstReponse,
      data: JSON.parse(atob(databurstReponse.data)) as PollworkerApiResponse
    }));
}

export function getPollworkersMasterList(electionId?: string, lastNameStartLetter: string = '*') {
  return fetchApi('/api/PollworkerApi/LoadPollworkersForCustomerWithWildCardAndAssignment', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Compress: false, Id: lastNameStartLetter, Notes: electionId}) // Yes, you read it right! Notes = ElectionId and Id = Wildcard
  }).then((response: Response) => response.json());
}

export function patchBulkUpdatePollworkerStatus(pollworkerIds: string[], status: number) {
  return fetchApi('/api/PollworkerApi/SetBulkPollworkerStatus', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Compress: false, list: pollworkerIds, additionalDataInt32: status})
  }).then((response: Response) => response.json());
}

export function patchBulkUpdatePollworkerDisplayState(pollworkerIds: string[], displayStateId: string) {
  return fetchApi('/api/PollworkerApi/SetBulkPollworkerDisplayStates', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Compress: false, list: pollworkerIds, id: displayStateId})
  }).then((response: Response) => response.json());
}

export function patchBulkUpdatePollworkerSms(pollworkerIds: string[], ok: boolean) {
  return fetchApi('/api/PollworkerApi/SetBulkOkToSendSMS', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Compress: false, list: pollworkerIds, flagValue: ok})
  }).then((response: Response) => response.json());
}

export async function postBulkSendSms(phoneNumberList: any, message: string) {
  let response = await fetchApi('/api/BulkSendSMS', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({phoneNumberList, message})
  });
  return await response.json();
}

export function getPollworkerLoadCustomFields() {
  return fetchApi('/api/CustomFieldApi/LoadCustomFields', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: null, ModuleId: 1, ObjectId: 201, Compress: false})
  })
  .then((response: Response) => response.json())
  .then(({data}) => JSON.parse(atob(data)));
}

export function getPollworkerLoadCustomFieldsDocument(keyPollworkerId: string) {
  return fetchApi('/api/CustomFieldContentApi/LoadCustomFieldContentForDocument', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: keyPollworkerId, Compress: false, AdditionalDataInt32: 0})
  })
  .then((response: Response) => response.json())
  .then(({data}) => data && JSON.parse(atob(data)));
}

export function getPollworkerCustomFields(keyCustomerId: string) {
  return fetchApi('/api/CustomFieldContentApi/LoadCustomFieldDataWithIndex', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Compress: false, Id: keyCustomerId, AdditionalData: "*", AdditionalDataInt32: 201})
  })
  .then((response: Response) => response.json())
  .then(({data}) => JSON.parse(atob(data)));
}

export function getPollworkerWorkHistoryAssignmentConfirmationsForElection(electionId: string) {
  return fetchApi('/api/GetPollworkerWorkHistoryAssignmentConfirmationsForElection', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Compress: false, Id: electionId})
  })
  .then((response: Response) => response.json())
  .then(({data}) => JSON.parse(atob(data)));
}


export function getPollworkerMessages(userId: string) {
  return fetchApi('/api/GetMessagesFor', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Id: userId})
  })
  .then((response: Response) => response.json());
}

export async function getPollworkerById(pollworker: PollworkerWorkHistory): Promise<PollworkerWorkHistory> {
  const {keyElectionId, keyEVUserId, workerLevel, userInfo: {lastName}} = pollworker;
  const { data } = await getPollworkers(keyElectionId, workerLevel, lastName[0].toLowerCase());

  return data.workerList.find((worker: PollworkerWorkHistory) => worker.keyEVUserId === keyEVUserId) as PollworkerWorkHistory;
}

export function getCustomerLogo(parentCustomerId: string) {
  return async (dispatch: Function) => {
    return fetchApi('/api/BlobStorageApi/RetrieveCustomerLogo', {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({Compress: false, Id: parentCustomerId})
    })
    .then(resp => resp.json())
    .then(({data, success, msg}) => {
      if (!success) return;

      dispatch(setLogo(JSON.parse(atob(data)).data));
    });
  }
}

export function getPollworkerScheduleSurvey(electionId: string) {
  return fetchApi('/api/PollworkerScheduleSurveyApi/LoadPwScheduleSurveysForElection', {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({Compress: false, Id: electionId})
  }).then((response: Response) => response.json())
}

export function getOrderById(orderId: string): [Promise<any>, AbortController] {
  const [fetch, controller] = fetchApiWithAbort(`/api/Storeify/Orders/GetOrder/${orderId}`, {
    headers: getHeaders(),
    method: 'GET',
  });

  return [fetch.then((response: Response) => response.json()), controller];
}

export function getAllCountyOrders() {
  return async (dispatch: Function) => {
    dispatch(loadingOrders());

    return fetchApi('/api/Storeify/Orders/GetCountyOrders', {
      headers: getHeaders(),
      method: 'GET',
    })
    .then((response: Response) => response.json())
    .then(({data, success, msg}) => {
      dispatch(loadedOrders());

      if (success) {
        const orders = JSON.parse(atob(data));

        const ordersMapped = orders.map(({order, orderItems}: { order: Order, orderItems: { orderItem: OrderItem, inventoryEquipType: ConsumableItem }[] }) => {
          order.orderItems = orderItems.map(({orderItem, inventoryEquipType}) => {
            // @ts-ignore
            delete orderItem['inventoryEquipmentType'];
            // @ts-ignore
            delete orderItem['order'];

            return {...orderItem, inventoryEquipType: inventoryEquipType};
          });

          return order;
        });

        dispatch(addOrders(ordersMapped));
        return;
      }

      dispatch(failedLoadingOrders(msg));
    }).catch(() => {
      dispatch(failedLoadingOrders('Unknown error, request failed'));
    });
  }
}

export function getOrderSearchConfig() {
  return fetchApi('/api/Storeify/Orders/OrderSearchConfig', {
    headers: getHeaders(),
    method: 'GET',
  })
  .then((response: Response) => response.json())
  .then(({data, success, msg}) => {
    const config = JSON.parse(atob(data));
    return {success, config};
  });
}

export function getOrderHistory(sortModel: any, filterModels: any, page: number): [Promise<OrderHistoryPayload>, AbortController] {
  const body = JSON.stringify({sortModel, filterModels, startRow: page, endRow: 0});
  const [promise, controller] = fetchApiWithAbort('/api/Storeify/Orders/GetOrderHistory', {
    headers: getHeaders(),
    method: 'POST',
    body,
  });

  return [
    promise
      .then((response: Response) => response.json())
      .then(({data, success}: ApiResponse) => {
        const {orders, count, pageSize} = JSON.parse(atob(data));
        return {success, count, orders, pageSize};
      }),
    controller
  ];
}

export function removeFromCart(item: CartItem) {
  return async (dispatch: Function) => {
    return fetchApi(`/api/Storeify/Orders/RemoveFromCart`, {
      headers: getHeaders(),
      method: 'DELETE',
      body: JSON.stringify({orderItemId: item.id})
    })
    .then((response: Response) => response.json())
    .then(({success}) => {
      if (success) {
        dispatch(removeItem(item));
        dispatch(showToast({content: `${item.inventoryEquipType.equipTypeName} (${item.quantity}) has been removed.`, undoAction: undefined}))
      }
    });
  }
}

export function getFileFromSecureStorage(uri: string) {
  return fetchApi('/api/BlobStorageApi/RetrieveDataFileFromSecureStorage', {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({
      Id: uri,
      Compress: false,
    }),
  }).then((resp) => resp.json());
}

export function getImageData(equipmentTypeId: string | undefined, customerIdOverride?: string, entitytype?: string, skipCache: boolean = false) {
  if (!equipmentTypeId) return Promise.resolve(null);

  const cacheValue = !skipCache && Cache.get(imageCacheKey(equipmentTypeId));
  if (cacheValue) return Promise.resolve(cacheValue);

  const id = [customerIdOverride || window.localStorage.getItem('customerId'), entitytype, equipmentTypeId].filter(p => !!p).join('/')

  return fetchApi('/api/BlobStorageApi/RetrieveDataFileFromStorage', {
    headers: getHeaders(),
    method: 'POST',
    body: JSON.stringify({
      Id: id,
      Compress: false,
    }),
  })
  .then((response: Response) => response.json())
  .then((data) => {
    const parsedData = JSON.parse(atob(data.data));
    const payload = { id: equipmentTypeId, src: `data:image/jpg;base64,${parsedData?.data}` };
    return Cache.set(imageCacheKey(equipmentTypeId), payload);
  })
  .catch((_err) => {
    return { id: equipmentTypeId, src: null }
  });
}

export function addToCart(item: ConsumableItem, quantity: number) {
  if (!quantity) return Promise.resolve();

  return async (dispatch: Function) => {
    return fetchApi(`/api/Storeify/Orders/AddItemToCart?itemIdToAdd=${item.id}&quantity=${quantity}`, {
      headers: getHeaders(),
      method: 'POST',
      body: JSON.stringify({
        itemIdToAdd: item.id,
        quantity,
      })
    })
    .then((response: Response) => response.json())
    .then(({data, success}) => {
      if (success) {
        const parsedData = JSON.parse(atob(data));
        let isUpdate = !!parsedData.updatedAt;
        dispatch(showToast({content: `You have ${isUpdate ? 'updated' : 'added'} an item in the cart.`, undoAction: CART_ACTION_TYPES.UNDO}));
        dispatch(getCartItems());

        return parsedData;
      }

      return data;
    });
  }
}

export const getCartItems = () => {
  return async (dispatch: Function) => {
    dispatch(loadingCart());

    return fetchApi('/api/Storeify/Orders/GetCartItems', {
      headers: getHeaders(),
      method: 'GET',
    })
    .then((response: Response) => response.json())
    .then(({data, success, msg}) => {
      if (!success) {
        dispatch(failedLoadingCart(msg));
        return;
      }

      dispatch(addCartItems(JSON.parse(atob(data))));
    })
    .catch((err) => dispatch(failedLoadingCart(err.msg)))
    .finally(() => dispatch(loadedCart()));
  }
}

export function getPollworkerApplicant(applicantId: string) {
  return fetchApi(`/api/PollworkerApplicantApi/${applicantId}`, {
    method: 'GET',
    headers: getHeaders()
  }).then((response: Response) => response.json());
}
