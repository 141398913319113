import React, {Fragment, useEffect} from "react";
import {Transition} from '@headlessui/react';
import {CheckIcon, XCircleIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../reducers";
import {popToast} from "../actions/toast";
import {classNames} from "shared/src/utils/classNames";

let timer: number | undefined;

export default function Toaster() {
  const toast = useSelector(({toast}: AppState) => toast);
  const dispatch = useDispatch();

  useEffect(() => {
    if (timer) window.clearTimeout(timer);
    timer = window.setTimeout(() => dispatch(popToast()), toast.timeout || 5000);
  }, [toast]);

  return (
    <div
      aria-live="assertive"
      className={classNames(toast.position === 'top' ? 'top-10' : 'bottom-10', 'pointer-events-none absolute z-40 w-full')}
    >
      <div className="flex w-full flex-col items-center space-y-4">
        <Transition
          show={!!toast.content}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom={classNames(toast.position === 'top' ? '-translate-y-2' : 'translate-y-2', 'opacity-0')}
          enterTo="translate-y-0 opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-2 ring-ev-blue ring-opacity-50 ">
            <div className="p-4 bg-gray-50">
              <div className="flex items-center">
                <div className="flex w-0 flex-1 justify-between space-x-2">
                  { toast.success ? (<CheckIcon className="text-green-500 h-5 w-5" />) : (<XCircleIcon className="text-green-500 h-5 w-5" />) }
                  <p className="w-0 flex-1 text-sm font-medium text-gray-900">{toast.content}</p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ev-blue focus:ring-offset-2"
                    onClick={() => {
                      dispatch(popToast(toast));
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}
