import { LocationAssignmentsForDate } from "./pollworkerVotingLocationDetailsUtils";
import Tippy from "@tippyjs/react";
import { TippyContent } from "shared/src/components";
import { CakeIcon, ExclamationTriangleIcon, LifebuoyIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function ScheduleDateAdorners({ schedulesByRole }: { schedulesByRole: LocationAssignmentsForDate }) {
  const {
    isUnassignedDate,
    overProvisionedIndex,
    hasUnrequestedParty,
  } = schedulesByRole.meta;

  return (
    <>
      {
        isUnassignedDate ? (
          <Tippy content={<TippyContent>This is not a scheduled date</TippyContent>}>
            <ExclamationTriangleIcon className="size-4"/>
          </Tippy>
        ) : null
      }
      {
        hasUnrequestedParty ? (
          <Tippy content={<TippyContent>Contains unrequested party</TippyContent>}>
            <CakeIcon className="size-4 mb-0.5 text-ev-red"/>
          </Tippy>
        ) : null
      }
      {
        overProvisionedIndex && overProvisionedIndex >= 0 ? (
          <Tippy content={<TippyContent>Contains over-provisioned role</TippyContent>}>
            <LifebuoyIcon className="size-4 text-ev-red"/>
          </Tippy>
        ) : null
      }
    </>
  )
}
