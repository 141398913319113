import {useSelector} from "react-redux";
import {getPollworkerSelector, getShowPollworkerDetails} from "../selectors/pollworker";
import {useMemo} from "react";
import {PollworkerWorkHistory} from "../types/Pollworker/PollworkerWorkHistory";

export default function useSelectedPollworker() {
  const selectedPollworker = useSelector(getShowPollworkerDetails);
  const allPollworkers = useSelector(getPollworkerSelector);

  return useMemo(() => {
    if (!selectedPollworker) return;
    return allPollworkers.find((pw: PollworkerWorkHistory) => pw.id === selectedPollworker.id);
  }, [allPollworkers, selectedPollworker]);
}
