import { fetchApi } from 'shared/src/fetchers';
import { AddEditUserInfoDto, SaveEmployeeInfoDto } from '../screens/admin/SetupAndAdmin/AddEditUser';

export const fetchEmployees = async () => {
  const response = await fetchApi('/api/EmployeeApi/LoadEmployeesWithSSOInfo', {
    method: 'GET'
  });

  return await response.json();
}

export const fetchAddEditUserInfo: (evUserId: string | null | undefined) => Promise<AddEditUserInfoDto> = async (evUserId) => {
  const endpoint = evUserId
    ? `/api/EmployeeApi/FetchAddEditUserInfo/${encodeURIComponent(evUserId)}`
    : '/api/EmployeeApi/FetchAddEditUserInfo';
  
  const response = await fetchApi(endpoint, {
    method: 'GET'
  });
  
  return await response.json();
}

export const saveAddEditUserInfo: (data: SaveEmployeeInfoDto) => Promise<void> = async (data: SaveEmployeeInfoDto) => {
  const json = JSON.stringify(data);

  await fetchApi('/api/EmployeeApi/SaveAddEditUserInfo', {
    method: 'POST',
    body: json
  });
}

export const fetchUserIdIsInUse: (evUserId: string, userId: string) => Promise<boolean> = async (evUserId: string, userId: string) => {
  const req = {
    notes: userId,
    id: evUserId,
    compress: false,
  };

  const response = await fetchApi('/api/EVUserApi/CheckForDuplicateUserId', {
    method: 'POST',
    body: JSON.stringify(req)
  });

  const { data } = await response.json();

  return JSON.parse(atob(data)) as boolean;
}

export const getPollworkerSettings = async () => {
  const response = await fetchApi('/tables/Setting_Pollworker', {
    method: 'GET',
  });

  return await response.json();
}

export async function getForms() {
  const response = await fetchApi('/api/FormsApi/All', {
    method: 'GET',
  });

  return await response.json();
}

export async function getForm(formId: string) {
  const response = await fetchApi(`/api/FormsApi/${formId}`, {
    method: 'GET',
  });

  return await response.json();
}

export async function createForm(form: any) {
  const response = await fetchApi('/api/FormsApi', {
    method: 'POST',
    body: JSON.stringify(form),
  });

  return await response.json();
}

export async function updateForm(id: string, form: any) {
  const response = await fetchApi(`/api/FormsApi/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(form),
  });

  return await response.json();
}

export async function publishForm(id: string) {
  const response = await fetchApi(`/api/FormsApi/${id}/publish`, {
    method: 'POST',
  });

  return await response.json();
}

export async function revertDraftForm(id: string) {
  const response = await fetchApi(`/api/FormsApi/${id}/revert`, {
    method: 'DELETE',
  });

  return await response.json();
}

export async function createChildForm(id: string) {
  const response = await fetchApi(`/api/FormsApi/${id}/dup`, {
    method: 'POST',
  });

  return await response.json();
}

export async function loadCustomerPermissions() {
  const response = await fetchApi(`/api/CustomerApi/LoadCustomerPermissions`, {
    method: 'POST',
  });

  return await response.json();
}
