import { VotingLocation } from "../../../types/Pollworker/PollworkerWorkHistory";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useIntersection, useTimeout } from "react-use";
import Spinner from "shared/src/components/Spinner";
import { BlobImage } from "../../../screens/store/StoreItemImage";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";

export default function VotingLocationImage({ votingLocation }: { votingLocation: VotingLocation }) {
  const containerRef = useRef(null);
  const [loadImage, setLoadImage] = useState(false);
  const intersection = useIntersection(containerRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });
  const [isReady] = useTimeout(300);

  useEffect(() => {
    if (!intersection || loadImage) return;

    setLoadImage(intersection.isIntersecting);
  }, [intersection, isReady]);

  return (
    <div className="flex items-center justify-center h-10 w-10 shrink-0" ref={isReady() ? containerRef : null}>
      <Suspense fallback={<Spinner large={false} show/>}>
        {
          loadImage ? (
            <BlobImage
              entityType='votinglocations'
              alt='Voting location image'
              className="object-cover h-10 w-10 rounded-full"
              id={`${votingLocation.coreId}.jpg`}
              customerId={votingLocation.keyCustomerId}
              imageNotFoundFallback={(
                <div className='object-cover h-10 w-10 rounded-full flex items-center justify-center'>
                  <BuildingOffice2Icon className='size-5' />
                </div>
              )}
            />
          ) : null
        }
      </Suspense>
    </div>
  );
}
