import { createAction } from '@reduxjs/toolkit';

import { FeatureFlagCheck } from 'admin/src/types/FeatureFlagCheck';

export const FEATURE_FLAG_ACTION_TYPES = {
  SET: 'SET_FEATURE_FLAG_CHECK',
  CLEAR: 'CLEAR_FEATURE_FLAG_CHECK'
};

export const setFeatureFlagCheck = createAction<FeatureFlagCheck>(FEATURE_FLAG_ACTION_TYPES.SET);
export const clearFeatureFlagCheck = createAction<FeatureFlagCheck>(FEATURE_FLAG_ACTION_TYPES.CLEAR);
