import {
  PollworkerApiResponse,
  Precinct,
  RoleInfo,
  Schedule,
  VotingLocation
} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import React, {useEffect, useMemo, useState} from "react";
import useSelectedPollworker from "admin/src/hooks/useSelectedPollworker";
import { useDispatch, useSelector } from "react-redux";
import {AppState} from "admin/src/reducers";
import {
  createPollworkerSchedule,
  getBasePrecinctListForCustomer,
  getPollworkerRoles, getPollworkers,
  getPrecinctsForElection,
  getVotingLocations,
  getVotingLocationsForElection, patchPollworkerSchedule
} from "admin/src/fetchers";
import dayjs from "dayjs";
import { Flexor } from "shared/src/components"
import {
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
  PencilSquareIcon,
  TrashIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import {Button} from "shared/src/components/ui";
import CreateWorkTimeEditor from "./CreateWorkTimeEditor";
import {useEffectOnce} from "react-use";
import { PlusCircleIcon } from "@heroicons/react/16/solid";
import { StickyFooter } from "../ui/StickyFooter";
import PanelSlideout, { PanelSlideoutHeader } from "../ui/PanelSlideout";
import { SectionSubSubHeading } from "shared/src/components/SectionHeading";
import { updatePollworkerLocation } from "../../../../reducers/pollworker/grid";

export default function ScheduleEditor({schedule, toggle}: { schedule?: Schedule, toggle: () => void }) {
  const [scheduleToEditIdx, setScheduleToEditIdx] = useState<number>();
  const [scheduleToEdit, setScheduleToEdit] = useState<Partial<Schedule>>();
  const [schedules, setSchedules] = useState<Partial<Schedule>[]>([]);
  const [precinctsForElection, setPrecinctsForElection] = useState<Precinct[]>([]);
  const [votingLocationsForElection, setVotingLocationsForElection] = useState<VotingLocation[]>([]);
  const [allVotingLocations, setAllVotingLocations] = useState<VotingLocation[]>([]);
  const [allPrecincts, setAllPrecincts] = useState<Precinct[]>([]);
  const [pollworkerRoles, setPollworkerRoles] = useState<RoleInfo[]>([]);
  const [saving, setSaving] = useState(false);
  const [notes, setNotes] = useState<string>('');
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const pollworker = useSelectedPollworker();
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const dispatch = useDispatch();

  const selectedElectionId = moduleInfo?.Election?.id;

  useEffect(() => {
    if (!schedule) return;

    setSchedules([schedule]);
    setNotes(schedule.notes);
  }, [schedule]);

  useEffectOnce(() => {
    setScheduleToEdit(schedule);
  });

  useEffect(() => {
    if (scheduleToEdit && schedules.length) editSchedule(0);
  }, [scheduleToEdit]);

  useEffect(() => {
    if (!pollworker || !moduleInfo || !selectedElectionId) return;

    getPrecinctsForElection(selectedElectionId).then((resp) => {
      const precinctsForElection = JSON.parse(atob(resp.data));
      setPrecinctsForElection(precinctsForElection);
    });

    getVotingLocationsForElection(selectedElectionId).then((resp) => {
      const votingLocationsForElection = JSON.parse(atob(resp.data));
      setVotingLocationsForElection(votingLocationsForElection);
    });

    getVotingLocations(pollworker.userInfo.keyCustomerId).then((resp) => {
      const coreVotingLocations = JSON.parse(atob(resp.data));
      setAllVotingLocations(coreVotingLocations)
    });

    getBasePrecinctListForCustomer(pollworker.userInfo.keyCustomerId).then((resp) => {
      const basePrecinctListForCustomer = JSON.parse(atob(resp.data));
      setAllPrecincts(basePrecinctListForCustomer);
    });

    getPollworkerRoles(pollworker.userInfo.keyCustomerId).then((roles) => {
      setPollworkerRoles(roles);
    });
  }, [moduleInfo, pollworker, selectedElectionId]);

  const [totalHours, totalDays] = useMemo(() => {
    const totalMinutes = schedules.reduce((count, s) => count + calculateWorkMinutes(s), 0);
    const totalDays = schedules.length;

    return [`${Math.round(totalMinutes / 60)}h ${totalMinutes % 60}m`, totalDays];
  }, [schedules]);

  async function save() {
    setSaving(true);

    for (const schedule of schedules) {
      if (schedule.id) {
        await patchPollworkerSchedule({...schedule, notes}).then(console.log);
      } else {
        await createPollworkerSchedule({...schedule, notes}).then(console.log);
      }
    }

    await getPollworkers(moduleInfo!.ElectionId, pollworker.workerLevel).then(({ data }) => {
      const { locationList: pollworkerLocationList } = data;

      for (const location of pollworkerLocationList) {
        dispatch(updatePollworkerLocation(location));
      }
    });

    setSaving(false);
    close();
  }

  function close() {
    toggle();
    hideAddScheduleModal();
  }

  function appendSchedule(newSchedules: Partial<Schedule>[]) {
    let schedulesList = [...schedules];

    if (scheduleToEditIdx !== undefined) {
      schedulesList[scheduleToEditIdx] = newSchedules[0];
    } else {
      schedulesList = [...schedules, ...newSchedules];
    }

    const schedulesById = schedulesList.reduce((acc: {[key: string]: Partial<Schedule>[]}, s) => {
      if (s.id) acc[s.id] = [s];
      else acc['new'].push(s);
      return acc;
    }, {'new': []});

    setSchedules(Object.values(schedulesById).flat().sort((a: Partial<Schedule>, b: Partial<Schedule>) => {
      return dayjs(a.workDate) < dayjs(b.workDate) ? -1 : 1;
    }));

    hideAddScheduleModal();
  }

  function editSchedule(scheduleIdx: number) {
    const schedule = schedules[scheduleIdx];
    setScheduleToEditIdx(scheduleIdx);
    setScheduleToEdit(schedule);
    setShowAddSchedule(true);
  }

  function removeSchedule(scheduleIdx: number) {
    const newSchedules = [...schedules];
    newSchedules.splice(scheduleIdx, 1);
    setSchedules(newSchedules);
  }

  function hideAddScheduleModal() {
    setScheduleToEditIdx(undefined);
    setScheduleToEdit(undefined);
    setShowAddSchedule(false);
  }

  function calculateWorkMinutes(schedule: Partial<Schedule>): number {
    if (!schedule.startTime || !schedule.endTime) return 0;

    const startDateTime = dayjs(`${dayjs().format('YYYY-MM-DD')} ${schedule.startTime}`);
    const endDateTime = dayjs(`${dayjs().format('YYYY-MM-DD')} ${schedule.endTime}`);

    return endDateTime.diff(startDateTime, 'minutes');
  }

  let canSave = !!schedules.length;

  return (
    <div className="flex flex-col justify-between h-full">
      <PanelSlideout show={showAddSchedule}>
        <PanelSlideoutHeader close={hideAddScheduleModal}>
          <SectionSubSubHeading>{`${scheduleToEdit ? 'Edit' : 'Create'} Schedule`}</SectionSubSubHeading>
        </PanelSlideoutHeader>
        <CreateWorkTimeEditor pollworkerRoles={pollworkerRoles} selectedElectionId={selectedElectionId} scheduleToEdit={scheduleToEdit} precincts={precinctsForElection} allVotingLocations_WithoutElectionData={allVotingLocations} votingLocations={votingLocationsForElection} onAdd={appendSchedule} />
      </PanelSlideout>
      <div className="flex flex-col justify-between isolate">
        <div>
          <fieldset className="px-2">
            <Flexor className='my-1'>
              <legend className="text-base font-semibold leading-6 text-gray-900">Schedules</legend>
              <Flexor justify="start" className="space-x-2 text-xs mt-2 mb-2">
                <Flexor className="space-x-2">
                  <ClockIcon className="h-4 w-4" />
                  <div>{totalHours}</div>
                </Flexor>
                <span>/</span>
                <Flexor className="space-x-2">
                  <CalendarIcon className="h-4 w-4" />
                  <div>{totalDays} days</div>
                </Flexor>
              </Flexor>
            </Flexor>
            <div className="divide-y-0 divide-gray-200 border-b border-t border-gray-200">
              {schedules.length ? null : (
                <div className="my-4 rounded-md border-2 border-dashed p-5 text-center text-sm font-semibold">
                  No schedules added yet
                </div>
              )}
              <div>
                {
                  schedules.map((schedule, scheduleIdx) => {
                    const votingLocation = [...allVotingLocations, ...votingLocationsForElection].find((vl) => vl.id === schedule.keyVotingLocationId);
                    const precinct = [...allPrecincts, ...precinctsForElection].find((p) => p.id === schedule.keyPrecinctId);
                    const role = pollworkerRoles.find((r) => r.id === schedule.keyRoleId);
                    const locationName = precinct?.precinctName || votingLocation?.locationName;

                    return (
                      <div key={`${schedule.workDate}_${scheduleIdx}`} className="flex justify-between items-center py-4">
                        <div className="min-w-0 flex-1 text-sm leading-6">
                          <div className="font-medium text-gray-900">
                            <div>
                              {dayjs(schedule.workDate).format('MMM D, YY')} from {schedule.startTime} {schedule.endTime} for {Math.round(calculateWorkMinutes(schedule) / 60)}h {calculateWorkMinutes(schedule) % 60}m
                            </div>
                            <div>
                              <MapPinIcon className="h-4 w-4 inline mb-1 mr-1" />
                              <span>{locationName}</span>
                            </div>
                            <div>
                              <UserCircleIcon className="h-4 w-4 inline mb-1 mr-1" />
                              <span>{role?.roleName}</span>
                            </div>
                          </div>
                        </div>
                        <button onClick={() => editSchedule(scheduleIdx)} className="ml-3 flex h-6 items-center text-xs font-semibold">
                          <PencilSquareIcon className='text-gray-800 hover:text-ev-blue transition-colors h-5 w-5' />
                        </button>
                        <button onClick={() => removeSchedule(scheduleIdx)} className="ml-3 flex h-6 items-center text-xs font-semibold">
                          <TrashIcon className='text-gray-800 hover:text-ev-red transition-colors h-5 w-5' />
                        </button>
                      </div>
                    )
                  }
                )}
                <Flexor className="mb-4 text-sm font-semibold">
                  <button className='text-xs' onClick={() => setSchedules([])}>Clear</button>
                  <Button disabled={!!schedule} onClick={() => setShowAddSchedule(true)} size='sm'>
                    <PlusCircleIcon className="h-4 w-4" />
                    <span>Add Schedule</span>
                  </Button>
                </Flexor>
              </div>
            </div>
          </fieldset>

          <div className="space-y-2 px-2 mt-4">
            <Flexor>
              <label
                htmlFor="schedule_notes"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Notes
              </label>
              <span className="text-xs text-gray-500 italic font-semibold">Optional</span>
            </Flexor>

            <div className="sm:col-span-2">
              <textarea
                id="schedule_notes"
                name="schedule_notes"
                placeholder="Add any extra notes you may have for this schedule..."
                rows={3}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                value={notes}
                onChange={({target: {value}}) => setNotes(value)}
              />
            </div>
          </div>
        </div>
      </div>
      <StickyFooter className='bg-gray-100 p-2 flex justify-end items-center'>
        <Button disabled={!canSave} onClick={save}>{saving ? 'Saving...' : 'Save'}</Button>
      </StickyFooter>
    </div>
  )
}
