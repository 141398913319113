import { Link, useLocation } from "wouter";
import { classNames } from "shared/src/utils/classNames";
import React, { ReactNode } from "react";
import { Flexor } from "shared/src/components";
import { useSelector } from "react-redux";
import { AppState } from "../../reducers";
import { UserType } from "../../enums/userType";
import { isUserTypeRestricted } from "../../utils/userType";

export interface NavigationItem {
  icon: ReactNode,
  href: string,
  name: string,
  requiredUserType?: UserType,
  show?: () => boolean,
}

export default function NavBar({navigation, children}: {navigation: NavigationItem[], children?: ReactNode[]}) {
  const userType = useSelector((state: AppState) => state.user?.user?.userType);
  const [location] = useLocation();

  return (
    <>
      <header className="sticky inset-x-0 top-0 z-30 flex shrink-0 h-14 px-4 border-b border-gray-900/10 bg-white">
        <nav className="px-5 flex justify-start text-sm font-semibold leading-6 text-gray-700 h-full sm:space-x-2 md:space-x-6">
          {
            navigation.map((item) => {
              if (isUserTypeRestricted(item.requiredUserType, userType) || (item.show && !item.show())) return null;

              const active = location === item.href;

              return (
                <Link title={item.href} to={item.href} key={item.name}>
                  <div className={classNames(active ? 'border-b-2 border-ev-red' : '', 'flex flex-col items-center justify-center cursor-pointer')}>
                    <Flexor className='space-x-2'>
                      {item.icon}
                      <div>{item.name}</div>
                    </Flexor>
                  </div>
                </Link>
              )
            })
          }
        </nav>
      </header>
      {children}
    </>
  )
}
