export interface Dialog {
  postMessagePrefix: string;
}

export const dialogs = {
  editDialog: {
    postMessagePrefix: 'Dialog:WorkerEdit:Open',
  },
  detailsDialog: {
    postMessagePrefix: 'Dialog:PollworkerDetails:Open',
  }
};
