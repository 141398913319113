import React, {Fragment, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {AppState} from "admin/src/reducers";
import useSelectedPollworker from "admin/src/hooks/useSelectedPollworker";
import {
  deletePollworkerCallLog,
  loadPollworkerLogs,
  patchPollworkerCallLog,
  savePollworkerCallLog
} from "admin/src/fetchers";
import dayjs from "dayjs";
import { Flexor } from "shared/src/components"
import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import Spinner from "shared/src/components/Spinner";
import {
  CalendarIcon,
  CheckIcon,
  ClipboardDocumentListIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  NoSymbolIcon,
  PencilSquareIcon,
  PhoneIcon,
  TrashIcon,
  UserIcon
} from "@heroicons/react/24/outline";
import {PollworkerLog} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import { Alert, Button, ButtonGroup, AlertDescription } from "shared/src/components/ui";
import {Menu, Popover} from "@headlessui/react";
import { Transition } from "@headlessui/react";
import {classNames} from "shared/src/utils/classNames";
import PanelModal from "./ui/PanelModal";
import {EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import DateFormatter from "shared/src/components/DateFormatter";
import { DateRange, DayPicker } from "react-day-picker";
import {
  CalendarDaysIcon,
  FunnelIcon,
} from "@heroicons/react/16/solid";
import PanelHeader from "./ui/PanelHeader";

export enum PollworkerLogReason {
  NoneOrNotSpecified = 0,
  Informational = 1,
  PhoneCall = 2,
  Email = 3
}

export default function PollworkerLogsPanel() {
  const [logDetails, setLogDetails] = useState<string>('');
  const [logs, setLogs] = useState<PollworkerLog[]>([]);
  const [savingLog, setSavingLog] = useState<boolean>(false);
  const [showCreateLogModal, setShowCreateLogModal] = useState<boolean>(false);
  const [loadingLogs, setLoadingLogs] = useState<boolean>(false);
  const [logType, setLogType] = useState<PollworkerLogReason>(PollworkerLogReason.PhoneCall);
  const [logToEdit, setLogToEdit] = useState<PollworkerLog>();
  const [filterOptions, setFilterOptions] = useState({showPhoneCalls: true, showInformational: true});
  const [dateRange, setDateRange] = useState<DateRange>({from: dayjs().add(-30, 'days').toDate(), to: dayjs().toDate()});
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const pollworker = useSelectedPollworker();
  const logDetailsInput = useRef(null);

  useEffect(loadLogs, [moduleInfo, pollworker, dateRange]);

  function loadLogs() {
    if (!pollworker || !moduleInfo?.ElectionId || !dateRange.from || !dateRange.to) return;

    setLoadingLogs(true);

    loadPollworkerLogs(dateRange.from?.toISOString(), dayjs(dateRange.to).endOf('day')?.toISOString(), pollworker.keyEVUserId)
      .then(({data}) => {
        const logs = JSON.parse(atob(data)).sort((a: PollworkerLog, b: PollworkerLog) => {
          return dayjs(a.createdAt) < dayjs(b.createdAt) ? 1 : -1
        });

        setLogs(logs);
      })
      .finally(() => setLoadingLogs(false));
  }

  function saveLog() {
    setSavingLog(true);
    savePollworkerCallLog(pollworker.keyEVUserId, logDetails, logType).finally(close);
  }

  function close() {
    setSavingLog(false);
    setShowCreateLogModal(false);
    setLogToEdit(undefined);
    setLogDetails('');
    loadLogs();
  }

  function updateLog(log: PollworkerLog) {
    setSavingLog(true);

    patchPollworkerCallLog(log).finally(close);
  }

  function deleteLog(log: PollworkerLog) {
    deletePollworkerCallLog(log).finally(loadLogs);
  }

  function getBadgeStyles(log: PollworkerLog) {
    switch (log.reasonId) {
      case PollworkerLogReason.PhoneCall:
        return 'bg-green-50 text-green-700 ring-green-600/20';
      case PollworkerLogReason.Informational:
        return 'bg-blue-50 text-blue-700 ring-blue-600/20';
    }
  }

  function save() {
    if (logToEdit) {
      return updateLog({ ...logToEdit, reasonId: logType, notes: logDetails });
    }

    saveLog();
  }

  return (
    <>
      <div className="h-full">
        <PanelModal title={`${logToEdit ? 'Edit' : 'Add'} Log`} show={showCreateLogModal} hide={() => close()}>
          <>
            {
              logType === PollworkerLogReason.Informational ? (
                <Alert variant='warning'>
                  <ExclamationTriangleIcon />
                  <AlertDescription data-testid='informational-log-alert-description'>You will not be able to update or remove <strong>Informational</strong> logs.</AlertDescription>
                </Alert>
              ) : null
            }

            <strong className="text-semibold text-xs mt-2">Type</strong>
            <ButtonGroup className="isolate inline-flex w-full border overflow-hidden border-gray-400">
              <button
                type="button"
                disabled={!!logToEdit}
                onClick={() => setLogType(PollworkerLogReason.PhoneCall)}
                className={classNames(logType === PollworkerLogReason.PhoneCall ? 'bg-ev-red/75 text-white' : 'bg-white text-gray-900', "disabled:opacity-50 w-full inline-flex justify-center items-center px-3 py-2 text-sm font-semibold focus:z-10")}
              >
                <PhoneIcon className="mr-1 size-4" />
                <span>Phone Call</span>
              </button>
              <button
                type="button"
                disabled={!!logToEdit}
                onClick={() => setLogType(PollworkerLogReason.Informational)}
                className={classNames(logType === PollworkerLogReason.Informational ? 'bg-blue-200 text-blue-900' : 'bg-white text-gray-900', "disabled:opacity-50 w-full -ml-px inline-flex justify-center px-3 py-2 text-sm font-semibold focus:z-10")}
              >
                <InformationCircleIcon className="mr-1 h-5 w-5" />
                <span>Informational</span>
              </button>
            </ButtonGroup>
            <Flexor className='mt-4'>
              <strong className="text-semibold text-xs">Notes</strong>
              <span data-testid='log_notes_char_counter' className="text-sm w-full block pr-1 text-gray-700 text-right">{logDetails.length} / 500</span>
            </Flexor>
            <textarea
              ref={logDetailsInput}
              onChange={(event) => setLogDetails(event.target.value)}
              id="log_details"
              name="log_details"
              placeholder="Add log details here..."
              rows={3}
              className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
              defaultValue={logToEdit?.notes}
            />
            <br />
            <Flexor>
              <button className="text-sm font-semibold" onClick={close}>Cancel</button>
              <Button onClick={save} disabled={savingLog || !logDetails} className="bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <Spinner show={savingLog} />
                Save
              </Button>
            </Flexor>
          </>
        </PanelModal>
        <PanelHeader title='Logs' loading={loadingLogs} reload={loadLogs} onAdd={() => setShowCreateLogModal(true)}>
          <Menu as="div" className="flex-none relative">
            <Menu.Button className="block text-gray-500 hover:text-gray-900">
              <span className="sr-only">Open options</span>
              <FunnelIcon className="h-4 w-4" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 z-10 mt-0 space-y-2 w-54 origin-bottom-left rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        // active ? 'bg-gray-50 text-green-500' : 'text-gray-800',
                        'flex justify-between space-x-4 items-center block px-3 py-1 text-sm leading-6'
                      )}
                      onClick={() => setFilterOptions({...filterOptions, showInformational: !filterOptions.showInformational})}
                    >
                      <CheckIcon className={classNames(filterOptions.showInformational ? 'visible' : 'invisible', "shrink-0 h-5 w-5")} />
                      <span>Informational</span>
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item disabled={false}>
                  {({ active }) => (
                    <a
                      href="#"
                      className={classNames(
                        // active ? 'bg-ev-red text-white' : 'bg-gray-50 text-gray-900',
                        'flex justify-between space-x-4 items-center block px-3 py-1 text-sm leading-6'
                      )}
                      onClick={() => setFilterOptions({...filterOptions, showPhoneCalls: !filterOptions.showPhoneCalls})}
                    >
                      <CheckIcon className={classNames(filterOptions.showPhoneCalls ? 'visible' : 'invisible', "shrink-0 h-5 w-5")} />
                      <span>Phone Calls</span>
                    </a>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
          <Popover className="relative flex-none">
            <Popover.Button className='block ml-1 text-gray-500 hover:text-gray-900'>
              <CalendarDaysIcon className="h-4 w-4" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel className="absolute -left-5 scale-75 origin-top-left z-10 mt-0 space-y-2 w-54 origin-bottom-left rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <DayPicker
                  showOutsideDays
                  mode='range'
                  onSelect={(range) => {
                    range && setDateRange(range);
                    console.log(range)
                  }}
                  selected={dateRange}
                  disabled={(date) => date > new Date()}
                />
              </Popover.Panel>
            </Transition>
          </Popover>
        </PanelHeader>
        {
          loadingLogs ? (
            <Flexor justify="center" className="space-x-2 mt-10">
              <Spinner show />
              <SectionSubSubHeading>Loading logs...</SectionSubSubHeading>
            </Flexor>
          ) : (
            <>
              {
                !logs.length ? (
                  <Flexor justify="center" className="space-x-2 mt-10">
                    <NoSymbolIcon className="h-6 w-6" />
                    <SectionSubSubHeading>No logs found</SectionSubSubHeading>
                  </Flexor>
                ) : null
              }
              <ul className="divide-y divide-gray-100 h-full">
                {
                  logs.map((log) => {
                    if (!filterOptions.showPhoneCalls && log.reasonId === PollworkerLogReason.PhoneCall) return null;
                    if (!filterOptions.showInformational && log.reasonId === PollworkerLogReason.Informational) return null;

                    return (
                      <li key={log.id}>
                        <dl key={log.id} className={classNames("hover:bg-gray-100 pb-2 divide-y pl-2")}>
                          <Flexor justify="start" className="space-x-2" items="start">
                            <div className="transition-all w-full">
                              <div className="w-full flex items-center justify-between py-3 text-sm font-medium">
                                <dt className="font-medium text-gray-700 w-full space-y-2">
                                  <Flexor justify="start" className="space-x-1 w-full truncate break-all">
                                    <UserIcon className="h-4 w-4" />
                                    <div className="block">{log.creatorInfo.firstName} {log.creatorInfo.lastName}</div>
                                  </Flexor>
                                  <Flexor justify="start" className="space-x-1 w-full truncate break-all">
                                    <CalendarIcon className="h-4 w-4" />
                                    <div className="">
                                      <DateFormatter dateFormats={{
                                        year: "numeric",
                                        month: "short",
                                        day: "2-digit",
                                        hour: 'numeric',
                                        minute: 'numeric',
                                      }} dateString={log.dateCreated} />
                                    </div>
                                  </Flexor>
                                </dt>
                                <div className={classNames(getBadgeStyles(log), "p-0.5 px-1 rounded-sm ring-2")}>{PollworkerLogReason[log.reasonId]}</div>
                                <dd className="text-sm text-gray-900 flex items-center justify-end">
                                  <Menu as="div" className="relative flex-none">
                                    <Menu.Button data-testid={`log_menu_${log.id}`} disabled={log.reasonId !== PollworkerLogReason.PhoneCall} className="block p-2.5 text-gray-500 hover:text-gray-900">
                                      <span className="sr-only">Open options</span>
                                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="absolute right-5 z-10 mt-0 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        <Menu.Item disabled={log.reasonId !== PollworkerLogReason.PhoneCall}>
                                          {({ active, disabled }) => (
                                            <a
                                              data-testid={`edit_log_${log.id}`}
                                              href="#"
                                              className={classNames(
                                                disabled ? 'cursor-not-allowed' : '',
                                                active ? 'bg-gray-200' : 'bg-gray-50',
                                                'flex items-center text-gray-900 space-x-2 block px-3 py-1 text-sm leading-6'
                                              )}
                                              onClick={() => {
                                                setLogToEdit(log);
                                                setLogType(log.reasonId);
                                                setLogDetails(log.notes);
                                                setShowCreateLogModal(true);
                                              }}
                                            >
                                              <PencilSquareIcon className="h-5 w-5" />
                                              <span>Edit<span className="sr-only">, {log.id}</span></span>
                                            </a>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item disabled={log.reasonId !== PollworkerLogReason.PhoneCall}>
                                          {({ active, disabled }) => (
                                            <a
                                              href="#"
                                              className={classNames(
                                                disabled ? 'cursor-not-allowed' : '',
                                                active ? 'bg-ev-red text-white' : 'bg-gray-50 text-gray-900',
                                                'flex items-center space-x-2 block px-3 py-1 text-sm leading-6'
                                              )}
                                              onClick={() => deleteLog(log)}
                                            >
                                              <TrashIcon className="h-5 w-5" />
                                              <span>Delete<span className="sr-only">, {log.id}</span></span>
                                            </a>
                                          )}
                                        </Menu.Item>
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </dd>
                              </div>
                              <Flexor justify="start" className="space-x-1 text-sm">
                                <ClipboardDocumentListIcon className="h-4 w-4 shrink-0" />
                                <div className="">{log.notes}</div>
                              </Flexor>
                            </div>
                          </Flexor>
                        </dl>
                      </li>
                    )
                  })
                }
              </ul>
            </>
          )
        }
      </div>
    </>
  )
}
