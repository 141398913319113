import {CartItem} from "../types/CartItem";
import {createAction} from "@reduxjs/toolkit";

export const CART_ACTION_TYPES = {
  ADD_ITEM: 'ADD_ITEM',
  ADD_ITEMS: 'ADD_ITEMS',
  CLEAR: 'CLEAR',
  EDIT_ITEM: 'EDIT_ITEM',
  FAILED_LOADING_CART: 'FAILED_LOADING_CART',
  LOADED_CART: 'LOADED_CART',
  LOADING_CART: 'LOADING_CART',
  REMOVE_ITEM: 'REMOVE_ITEM',
  UNDO: 'UNDO',
}

export const clearCart = createAction(CART_ACTION_TYPES.CLEAR);
export const addItem = createAction<CartItem>(CART_ACTION_TYPES.ADD_ITEM);
export const addCartItems = createAction<CartItem[]>(CART_ACTION_TYPES.ADD_ITEMS);
export const loadingCart = createAction(CART_ACTION_TYPES.LOADING_CART);
export const failedLoadingCart = createAction<string | undefined>(CART_ACTION_TYPES.FAILED_LOADING_CART);
export const loadedCart = createAction(CART_ACTION_TYPES.LOADED_CART);
export const editItem = createAction<CartItem>(CART_ACTION_TYPES.EDIT_ITEM);
export const removeItem = createAction<CartItem>(CART_ACTION_TYPES.REMOVE_ITEM);
