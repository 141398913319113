import {CartItem} from "../types/CartItem";
import {AnyAction, AsyncThunkAction} from "@reduxjs/toolkit";
import {CART_ACTION_TYPES} from "../actions/cart";

export default function cartReducer(state: CartItem[] = [], action: AnyAction): CartItem[] {
  switch (action.type) {
    case CART_ACTION_TYPES.CLEAR:
      return [];
    case CART_ACTION_TYPES.ADD_ITEMS:
      return action.payload;
    case CART_ACTION_TYPES.ADD_ITEM:
      const existingItemInCartIdx = state.findIndex((item) => item.keyInventoryItemId === action.payload.id);

      const stateCopy = [...state];
      stateCopy[existingItemInCartIdx > -1 ? existingItemInCartIdx : stateCopy.length - 1] = action.payload;

      return stateCopy;
    case CART_ACTION_TYPES.REMOVE_ITEM:
      return state.filter((item) => item.id !== action.payload.id);
    case CART_ACTION_TYPES.EDIT_ITEM:
      const newItems = state.filter((item) => item.id !== action.payload.id);
      newItems.push(action.payload);
      return newItems;
    case CART_ACTION_TYPES.UNDO:
      const newState = [...state];
      newState.pop();

      console.log('Undoing', state, newState);
      return newState;
    default:
      // console.log('Missed action', action);
      return state;
  }
}
