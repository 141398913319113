import React, {ReactNode} from 'react';
export default function DebugDump({obj}: {obj: any}) {
  return (
    <AdminDebug>
      <pre>{JSON.stringify(obj, null, 2)}</pre>
    </AdminDebug>
  )
}

export function AdminDebug({children}: {children: ReactNode}): any {
  if (process.env.NODE_ENV === 'production') return null;

  return children;
}
