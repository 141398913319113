import React, { useCallback, useEffect, useState } from "react";
import {DocumentArrowDownIcon} from "@heroicons/react/24/outline";

import {Flexor, ScreenReaderIgnore, ScreenReaderOnly, Spinner} from "shared/src/components";
import {Button} from "shared/src/components/ui";
import {SectionSubHeading} from "shared/src/components/SectionHeading";
import { fetchActiveDeployments, downloadInventorySealReport } from "admin/src/fetchers";
import { setActiveDeployments } from "admin/src/actions";
import { useAppDispatch, useAppSelector } from "admin/src/hooks";
import { downloadDocument } from 'shared/src/utils';
import InventoryNavBar from "./InventoryNavBar";

export default function Reports() {
  const [loadingDeployments, setLoadingDeployments] = useState<boolean>(false);
  const [reportIsRunning, setReportIsRunning] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const deployments = useAppSelector(appState => appState.inventory.deployments.activeDeployments)

  useEffect(() => {
    setLoadingDeployments(true);
    fetchActiveDeployments().then(deployments => {
      dispatch(setActiveDeployments(deployments.data));
    }).finally(() => {
      setLoadingDeployments(false);
    })
  }, [])

  const handleDownloadReportButtonClicked = useCallback((deploymentId: string, deploymentName: string) => {
    setReportIsRunning(true);

    downloadInventorySealReport(deploymentId).then(responseBody => {
      downloadDocument(`${deploymentName}_InventorySealReport`, '.pdf')(responseBody);
    }).finally(() => {
      setReportIsRunning(false);
    });
  }, []);

  return (
    <>
      <InventoryNavBar />
      <div className='px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto'>
      <SectionSubHeading className="my-2 border-b pb-3 mb-4">Reports</SectionSubHeading>
      <div>
        <Flexor>
          <SectionSubHeading>Inventory Seal Report</SectionSubHeading>
        </Flexor>
      </div>
      <Spinner show={loadingDeployments} />
      {deployments.map(deployment => (
        <div key={deployment.id} className='bg-white rounded-md my-2 shadow'>
          <ul className='divide-y divide-gray-100 px-4'>
            <li className='flex items-center justify-between gap-x-6 py-5'>
              <div className='min-w-0'>
                <Flexor className='gap-x-3'>
                  <p className='text-sm font-semibold leading-6 text-gray-900'>
                    <ScreenReaderIgnore>{deployment.deploymentName}</ScreenReaderIgnore>
                  </p>
                </Flexor>
              </div>
              <div className='flex flex-none items-center gap-x-4'>
                <Button
                  data-testid={`download-btn-${deployment.id}`}
                  disabled={reportIsRunning}
                  onClick={() => handleDownloadReportButtonClicked(deployment.id, deployment.deploymentName)}
                >
                  <DocumentArrowDownIcon className='h-3 w-3 inline' /> Download<ScreenReaderOnly>, {deployment.deploymentName}</ScreenReaderOnly>
                </Button>
              </div>
            </li>
          </ul>
        </div>
      ))}
    </div>
    </>
  );
}
