import {
  ArchiveBoxIcon, ArchiveBoxXMarkIcon,
  CheckCircleIcon, ChevronDownIcon,
  ClockIcon, IdentificationIcon,
  NoSymbolIcon, PaperAirplaneIcon,
  PencilSquareIcon, PlayIcon,
  TruckIcon
} from "@heroicons/react/24/outline";
import {SectionHeading} from "../../../../shared/src/components/SectionHeading";
import {Fragment, useEffect, useState} from "react";
import {
  getCustomerOrders,
  removeOrderItem,
  updateOrderItemQuantity,
  updateOrderStatus
} from "../../fetchers";
import DateFormatter from "../../../../shared/src/components/DateFormatter";
import { Flexor } from "shared/src/components"
import {Menu, Transition} from "@headlessui/react";
import {classNames} from "shared/src/utils/classNames";
import ModalConfirmation, {ModalConfirmationAccents} from "../../components/modals/ModalConfirmation";
import {Order, OrderItem} from "../../types/Order";
import {getOrderId, orderStates} from "../../utils/getters";
import {OrderItemQuantityEditForm} from "../store/OrderItemQuantityEditForm";
import Spinner from "../../../../shared/src/components/Spinner";
import Loading from "../../../../shared/src/components/Loading";

export default function Orders() {
  const [orderFilter, setOrderFilter] = useState<string | undefined>('active');
  const [orders, setOrders] = useState<Order[]>([]);
  const [loadingOrders, setLoadingOrders] = useState<boolean>(false);
  const [orderItemToUpdate, setOrderItemToUpdate] = useState<OrderItem>();
  const [orderItemToRemove, setOrderItemToRemove] = useState<OrderItem>();
  const [markOrderAsDelivered, setOrderAsDelivered] = useState<Order>();
  const [orderToCancel, setOrderToCancel] = useState<Order>();
  const [showMarkOrderAsDelivered, setShowMarkOrderAsDelivered] = useState<boolean>(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false);

  useEffect(() => {
    fetchOrders();
  }, []);

  function fetchOrders() {
    setLoadingOrders(true);

    return getCustomerOrders().then(({data, success}) => {
      if (!data || !success) return;
      const items = JSON.parse(atob(data));

      // @ts-ignore
      const transformedOrders = Object.values(items).map((val: {item1: Order, item2: OrderItem[]}): Order => {
        const {item1: order, item2: orderItems} = val;
        return {...order, orderItems};
      }).sort((a: Order, b: Order) => {
        if (a.updatedAt === b.updatedAt) return 0;
        return new Date(a.updatedAt) < new Date(b.updatedAt) ? 1 : -1;
      });

      setOrders(transformedOrders);
    }).catch((e) => {
      // TODO: Need to handle error states
      console.error(e);
    })
    .finally(() => {
      setLoadingOrders(false);
    });
  }

  function renderStatus(status: number) {
    let icon = <CheckCircleIcon className="w-6 h-6 text-green-500" />;
    let statusWord = 'Pending';
    let strike = false;

    switch(status) {
      case -1:
        icon = <ClockIcon className="w-6 h-6 text-gray-500" />;
        statusWord = 'Pending';
        break;
      case 0:
        icon = <ClockIcon className="w-6 h-6 text-gray-500" />;
        statusWord = 'Pending';
        break;
      case 1:
        icon = <PaperAirplaneIcon className="w-6 h-6 text-callout" />;
        statusWord = 'Submitted';
        break;
      case 2:
        icon = <TruckIcon className="w-6 h-6 text-warning" />;
        statusWord = 'En Route';
        break;
      case 3:
        icon = <ArchiveBoxIcon className="w-6 h-6 text-success" />;
        statusWord = 'Delivered';
        break;
      case 4:
        icon = <ArchiveBoxXMarkIcon className="w-6 h-6 text-gray-500" />;
        statusWord = 'Canceled';
        strike = true
        break;
    }

    return (
      <div className={`flex space-x-2 ${strike && 'line-through'}`}>
        <div>{statusWord}</div>
        <span>{icon}</span>
      </div>
    );
  }

  const editsDisabled = !!orderItemToUpdate || !!orderItemToRemove;

  function updateItem(order: Order, item: OrderItem) {
    const state = orderStates(order);
    if (state.canEdit) setOrderItemToUpdate(item);
  }

  return (
    <>
      <ModalConfirmation
        initOpen={showMarkOrderAsDelivered}
        title={`Are you sure?`}
        loading={loadingOrders}
        content={!!markOrderAsDelivered ? `You are validating that this order has been received by the recipient` : ''}
        accent={ModalConfirmationAccents.YELLOW}
        onClose={async (close: boolean, confirm: boolean) => {
          if (markOrderAsDelivered && confirm) {
            setLoadingOrders(true);
            await updateOrderStatus(markOrderAsDelivered.id, 3);
            await fetchOrders();
            setLoadingOrders(false);
          }

          setShowMarkOrderAsDelivered(false);
          setOrderAsDelivered(undefined);
        }
      } />
      <ModalConfirmation
        initOpen={showCancelConfirmation}
        title={`Are you sure?`}
        loading={loadingOrders}
        content={!!orderToCancel ? `You will not be able to resubmit this order (ID: ${getOrderId(orderToCancel)}) after canceling. Make sure this is what you want to do first` : ''}
        accent={ModalConfirmationAccents.RED}
        onClose={async (close: boolean, confirm: boolean) => {
          if (orderToCancel && confirm) {
            setLoadingOrders(true);
            await updateOrderStatus(orderToCancel.id, 4);
            await fetchOrders();
            setLoadingOrders(false);
          }

          setShowCancelConfirmation(false);
          setOrderToCancel(undefined);
        }}
      />
      <div className="flex items-center justify-between">
        <div className="sm:flex-auto">
          <SectionHeading>Orders</SectionHeading>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the orders
          </p>
        </div>
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button
            type="button"
            onClick={() => setOrderFilter(orderFilter === 'active' ? undefined : 'active')}
            className={`${orderFilter === 'active' ? 'bg-gray-200' : 'bg-white'} relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10`}
          >
            <PlayIcon className="h-5 w-5 mr-1" />
            Active
          </button>
          <button
            type="button"
            onClick={() => setOrderFilter(orderFilter === 'past' ? undefined : 'past')}
            className={`${orderFilter === 'past' ? 'bg-gray-200' : 'bg-white'} relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
          >
            <ClockIcon className="h-5 w-5 mr-1" />
            Past
          </button>
        </span>
      </div>
      {
        loadingOrders && !orderItemToUpdate && !orderItemToRemove && (
          <Loading loadingMessage="Finding your orders..." />
        )
      }
      {
        (!loadingOrders || orderItemToUpdate || orderItemToRemove) && orders.map((order: Order) => {
          const state = orderStates(order);
          if (orderFilter === 'active' && state.isPast) return null;
          if (orderFilter === 'past' && state.isActive) return null;
          if (!state.isPast && !state.isActive) return null;

          return (
            <div className="mt-8" key={order.id}>
              <h1 className="flex justify-between items-cener mb-3 text-base font-semibold leading-6 text-gray-900">
                <Flexor justify="start" className="space-x-2">
                  <IdentificationIcon className="h-5 w-5" />
                  <span>{getOrderId(order)}</span>
                </Flexor>
                <span className="text-md font-light"><DateFormatter dateString={order.updatedAt} /></span>
                <Flexor items="center" className="space-x-2">
                  {renderStatus(order.status)}
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="flex items-center rounded-full mt-1 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-ev-red-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                        <span className="sr-only">Open options</span>
                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item disabled={!state.status.enRoute}>
                            {({ active }) => (
                              <a
                                onClick={() => {
                                  setOrderAsDelivered(order);
                                  setShowMarkOrderAsDelivered(true);
                                }}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  state.status.enRoute ? 'cursor-pointer' : 'cursor-not-allowed',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                Mark as Received
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item disabled={!state.canCancel}>
                            {({ active }) => (
                              <a
                                onClick={() => {
                                  setOrderToCancel(order);
                                  setShowCancelConfirmation(true);
                                }}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  state.canCancel ? 'cursor-pointer' : 'cursor-not-allowed',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                Cancel Order
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </Flexor>
              </h1>
              <div className="flow-root">
                <div data-print-id={`print-order-${order.id}`} className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Name
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Quantity
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {
                            order.orderItems.map((item: OrderItem) => {
                              return (
                                <tr key={`${order.id}_${item.keyInventoryItemId}`}>
                                  <td className="w-1/3 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {item.inventoryEquipType.equipTypeName}
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {
                                      orderItemToUpdate?.id === item.id && !loadingOrders ? (
                                        <Flexor>
                                          <OrderItemQuantityEditForm className="absolute w-20 h-8" onBlur={({target: {value}}) => {
                                            const newValue = Number.parseInt(value);
                                            if (newValue === item.quantity) {
                                              setOrderItemToUpdate(undefined);
                                              return;
                                            }

                                            setLoadingOrders(true);
                                            updateOrderItemQuantity(order.id, item.id, newValue).then((resp) => {
                                              fetchOrders().then(() => {
                                                setOrderItemToUpdate(undefined);
                                              }).finally(() => setLoadingOrders(false));
                                            });
                                          }} item={orderItemToUpdate} />
                                        </Flexor>
                                      ) : (
                                        <>
                                          {
                                            orderItemToUpdate?.id === item.id && loadingOrders ? (
                                              <Spinner light={false} />
                                            ) : (
                                              <span onDoubleClick={() => updateItem(order, item)}>{item.quantity}</span>
                                            )
                                          }
                                        </>
                                      )
                                    }
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                                  <td className="relative flex justify-end whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <Flexor className="space-x-2 text-gray-800">
                                      <span onClick={() => updateItem(order, item)} className={`${classNames(state.canEdit ? 'cursor-pointer' : 'cursor-not-allowed')} transition-all hover:text-ev-blue`}>
                                        <PencilSquareIcon className="h-5 w-5" />
                                        <span className="sr-only">, {item.equipTypeName}</span>
                                      </span>
                                        { loadingOrders && orderItemToRemove?.id === item.id ? (
                                            <Spinner light={false} />
                                          ) :
                                          (
                                            <span onClick={() => {
                                              if (state.canEdit && state.items.canRemove) {
                                                setOrderItemToRemove(item);
                                                setLoadingOrders(true);

                                                removeOrderItem(order.id, item.id)
                                                .then(async () => {
                                                  await fetchOrders();
                                                })
                                                .finally(() => {
                                                  setOrderItemToRemove(undefined);
                                                  setLoadingOrders(false);
                                                });
                                              }
                                            }} className={classNames(state.items.canRemove ? 'cursor-pointer' : 'cursor-not-allowed', 'transition-all hover:text-ev-red')}>
                                              <NoSymbolIcon className="h-5 w-5" />
                                              <span className="sr-only">, {item.equipTypeName}</span>
                                            </span>
                                          )
                                        }
                                    </Flexor>
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </>
  )
}
