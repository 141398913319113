import { PayloadAction } from '@reduxjs/toolkit';

import { INVENTORY_UI_ACTION_TYPES } from 'admin/src/actions/inventory';

export type InventoryUiState = {
  selectedCounty: string;
};

const initialState = {
  selectedCounty: ''
};

export default function inventoryUiReducer(state: InventoryUiState = initialState, action: PayloadAction<string>) {
  switch(action.type) {
    case INVENTORY_UI_ACTION_TYPES.SET_SELECTED_COUNTY:
      return {
        ...state,
        selectedCounty: action.payload
      }
    default:
      return state
  }
}
