import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState, Fragment } from "react";
import { toast } from "react-toastify";
import { SectionSubSubHeading } from "shared/src/components/SectionHeading";
import { AlertDescription } from "shared/src/components/ui/Alert";
import { FormStatus } from "shared/src/enums/FormStatus";
import { getHeaders } from "shared/src/fetchers";
import { Menu, Transition } from '@headlessui/react';
import {
  EyeIcon,
  PencilSquareIcon,
  ChevronDownIcon,
  TableCellsIcon,
  TrashIcon, ArrowTopRightOnSquareIcon, AdjustmentsVerticalIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Flexor, Loading } from "shared/src/components";
import { Alert, Button, Separator } from "shared/src/components/ui";
import { pollworkerSurveyJsTheme } from "shared/src/components/pollworkerSurveyJsTheme";
import { classNames } from "shared/src/utils/classNames";
import { compileFormSchema, generateAsyncFormQuestionHostname } from "shared/src/utils/forms";
import { Converter } from "showdown";
import { Model, settings } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { Link, useLocation, useParams } from "wouter";
import {
  createChildForm,
  getForms, getPollworkerSettings,
  publishForm, revertDraftForm,
  updateForm
} from "../../../fetchers/setupAndAdmin";
import { SurveyCreatorWidget } from './SurveyCreator';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import 'shared/src/PollworkerSettings.css';

export default function FormEditor() {
  const [form, setForm] = useState({});
  const [schema, setSchema] = useState();
  const [model, setModel] = useState();
  const [loadingForms, setLoadingForms] = useState(true);
  const [editFormTitle, setEditFormTitle] = useState(false);
  const [pollworkerSettings, setPollworkerSettings] = useState();
  const [userEditableSchema, setUserEditableSchema] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [updatingForm, setUpdatingForm] = useState(false);
  const customerId = useSelector((state) => state.user?.account?.id);
  const { formId } = useParams();
  const [, navigate] = useLocation();

  useEffect(() => {
    loadPollworkerSettings();
  }, []);

  useEffect(() => {
    if (!pollworkerSettings || !formId) return;

    loadFormById(formId);
  }, [pollworkerSettings, formId]);

  useEffect(() => {
    settings.web.disableQuestionWhileLoadingChoices = true;
    settings.web.onBeforeRequestChoices = (sender, options) => {
      Object.entries(getHeaders()).forEach(([key, val]) => {
        options.request.setRequestHeader(key, val);
      });
    }
  }, []);

  function onChange(saveNo, updatedSchema) {
    setUpdatingForm(true);

    const newSchema = structuredClone(schema);
    const [firstPage, ...otherPages] = schema.pages;

    setSchema(structuredClone({
      ...newSchema,
      pages: [firstPage, ...updatedSchema.pages, ...otherPages]
    }));

    return updateForm(formId, {
      CompiledFormJson: JSON.stringify(compileFormSchema({...form, json: JSON.stringify(updatedSchema.pages)})),
      Json: JSON.stringify(updatedSchema.pages)
    })
    .then(({ data }) => {
      const updatedForm = { ...form, ...JSON.parse(atob(data)) };

      setForm(updatedForm);
      updateModel(updatedForm, compileFormSchema(updatedForm));
    })
    .finally(() => setUpdatingForm(false));
  }

  function updateModel(form, modelJson) {
    modelJson.questionsOnPageMode = 'singlePage';
    const m = new Model(modelJson);

    settings.web.encodeUrlParams = false;

    m.readOnly = form.status === FormStatus.Published;
    m.showCompletedPage = false;
    m.showCompleteButton = false;
    m.focusFirstQuestionAutomatic = false;
    m.setVariable('pollworkerWebsiteApiHostname', generateAsyncFormQuestionHostname());
    m.setVariable('customerId', customerId);
    m.setVariable('iAgreeCopy', pollworkerSettings.prospectAgreement);
    m.css = pollworkerSurveyJsTheme;

    const converter = new Converter();
    m.onTextMarkdown.add((survey, options) => {
      let str = converter.makeHtml(options.text);
      options.html = str.substring(3, str.length - 4);
    });

    setModel(m);
  }

  function openPollworkerApplication() {
    window.open(`http://${pollworkerSettings.curl}/apply/?formId=${formId}`, '_blank');
  }

  function loadPollworkerSettings() {
    getPollworkerSettings().then(([settings]) => setPollworkerSettings(settings));
  }

  function publish() {
    if (!window.confirm('Are you sure you\'re ready to publish?')) return;

    publishForm(formId).then((resp) => {
      const publishedForm = JSON.parse(atob(resp.data));
      setForm(publishedForm);
    });
  }

  function revert() {
    if (!window.confirm('Are you sure you want to revert your changes?')) return;

    const revertDraftPromise = revertDraftForm(form.id);

    toast.promise(revertDraftPromise, {
      pending: 'Reverting to parent form...',
      success: 'This form has been reverted!',
      error: 'Could not revert form.',
    }, {position: 'top-center'})
    .then(({ data }) => {
      const success = JSON.parse(atob(data))
      if (!success) return;

      setTimeout(() => navigateToForm(form.parentFormId), 2000);
    });
  }

  function navigateToForm(id) {
    navigate(`~/admin/setupandadmin/forms/${id}`, { replace: true });
  }

  function editForm(formId) {
    createChildForm(formId)
    .then((resp) => {
      const child = JSON.parse(atob(resp.data));
      navigateToForm(child.id);
    })
  }

  function updateFormTitle(newFormTitle) {
    setEditFormTitle(false);

    const toastId = toast.loading('Saving', {position: 'top-center', autoClose: 1000});

    updateForm(formId, {
      Title: newFormTitle,
    })
    .then(({data, success}) => {
      if (!success) {
        toast.update(toastId, { type: 'error', isLoading: false, autoClose: 1000, render: 'Form could not be saved' });
        return;
      }

      toast.update(toastId, { type: 'success', isLoading: false, autoClose: 1000, render: 'Saved' });
      const updatedForm = JSON.parse(atob(data));
      setForm({ ...form, ...updatedForm });
    })
    .catch(() => {
      toast.update(toastId, { type: 'error', render: 'Form could not be saved' });
      loadFormById(formId);
    });
  }

  function loadFormById(id) {
    setLoadingForms(true);

    getForms()
    .then((resp) => JSON.parse(atob(resp.data)))
    .then((forms) => {
      const parsedForm = forms.find(f => f.id === id);

      if (!parsedForm) {
        setErrorMessage(`Could not find form ${formId} -> ${id}`);
        return;
      }

      setForm(parsedForm);

      const workingPages = JSON.parse(parsedForm.json);
      setUserEditableSchema({
        pages: workingPages,
      });

      const schema = compileFormSchema(parsedForm);

      setSchema(schema);
      updateModel(parsedForm, schema);
    })
    .finally(() => {
      setLoadingForms(false);
    });
  }

  if (errorMessage) {
    return (
      <div className='h-full w-full flex flex-col justify-center max-w-3xl mx-auto'>
        <Alert.Alert variant='error-text' back='~/admin/setupandadmin/forms'>
          <ExclamationCircleIcon className='size-5' />
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert.Alert>
      </div>
    )
  }

  if (!model) {
    return (
      <div className='h-full w-full flex flex-col justify-center'>
        <Loading loadingMessage='Starting form editor...' />
      </div>
    )
  }

  function validateForm() {
    if (!model.validate()) {
      toast.error('There are errors on the form', { position: 'top-center' });
      return;
    }

    toast.success('The form is valid!', { position: 'top-center' });
  }

  const isPublished = form.status === FormStatus.Published;

  return (
    <div className={classNames(loadingForms ? 'opacity-60 pointer-events-none' : '', 'h-full flex flex-col justify-between transition-opacity')}>
      <div className='h-14 justify-between bg-white border-b p-3 flex items-center'>
        <Flexor className='space-x-2'>
          <PencilSquareIcon className='shrink-0 h-4 w-4 text-gray-500' />
          {
            editFormTitle ? (
              <input
                autoFocus
                defaultValue={form.title}
                onBlur={({target: {value: newFormTitle}}) => updateFormTitle(newFormTitle)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
              />
            ) : (
              <SectionSubSubHeading onDoubleClick={() => setEditFormTitle(true)}>{form.title}</SectionSubSubHeading>
            )
          }
          <p className="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-gray-600 bg-gray-50 ring-gray-500/10">#{form.id.split('-')[0]}</p>
        </Flexor>
        <Flexor>
          {
            isPublished ? (
              <Button onClick={() => editForm(formId)}>
                <TableCellsIcon className='h-5 w-5' />
                <span>Edit</span>
              </Button>
            ) : (
              <Button onClick={publish}>
                <EyeIcon className='h-5 w-5' />
                <span>Publish Draft</span>
              </Button>
            )
          }
          <Separator />
          <Menu as="div" className="relative inline-block text-left border-r border-gray-300 mr-4 pr-6">
            <div>
              <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                Options
                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm'
                        )}
                        onClick={openPollworkerApplication}
                      >
                        <ArrowTopRightOnSquareIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                        View Application
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm'
                        )}
                        onClick={loadPollworkerSettings}
                      >
                        <AdjustmentsVerticalIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                        Refresh Pollworker Settings
                      </div>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="#"
                        disabled={isPublished}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'group flex items-center px-4 py-2 text-sm hover:bg-ev-red hover:text-white transition-colors'
                        )}
                        onClick={() => revert()}
                      >
                        <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-white" aria-hidden="true" />
                        Revert Changes
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <Link to='/forms'>
            <button className='mx-3'>Exit</button>
          </Link>
        </Flexor>
      </div>
      <PanelGroup autoSaveId='form-editor' direction="horizontal" className='flex h-full'>
        <Panel defaultSize={30} minSize={20} className='h-full'>
          <SurveyCreatorWidget readOnly={isPublished} model={userEditableSchema} onChange={onChange} />
        </Panel>
        <PanelResizeHandle className='bg-gray-200 hover:bg-ev-blue w-1 transition-colors' />
        <Panel style={{overflow: 'auto'}} defaultSize={30} minSize={20} className={classNames(updatingForm ? 'opacity-60' : '', 'w-full h-full overflow-y-auto px-2 py-4 transition-opacity')}>
          {model ? (
            <>
              <Survey model={model} />
              <Button className='w-full sticky bottom-0 flex justify-center items-center my-2' onClick={validateForm}>Validate</Button>
            </>
          ) : null}
        </Panel>
      </PanelGroup>
    </div>
  )
}
