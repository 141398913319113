import {useSelector} from "react-redux";
import {AppState} from "admin/src/reducers";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import {
  addAttendeeToClassSession,
  getClassesForElection,
  getPollworkerClassMatrix,
  getPollworkerRequiredClassesToBeToken, getPollworkerTrainingClassSessionList, removeAttendeeFromClassSession
} from "admin/src/fetchers";
import useSelectedPollworker from "admin/src/hooks/useSelectedPollworker";
import {TrainingClass} from "admin/src/types/Pollworker/PollworkerModuleInfo";
import {classNames} from "shared/src/utils/classNames";
import { Flexor } from "shared/src/components"
import {
  CalendarIcon,
  CheckBadgeIcon,
  CheckIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  FunnelIcon,
  InformationCircleIcon,
  NoSymbolIcon,
  ShieldCheckIcon,
  TrashIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import {
  PlusCircleIcon,
  UsersIcon
} from "@heroicons/react/16/solid";
import dayjs from "dayjs";
import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import {Button} from "shared/src/components/ui";
import Tippy from "@tippyjs/react";
import TippyContent from "shared/src/components/TippyContent";
import PanelSlideout from "./ui/PanelSlideout";
import Spinner from "shared/src/components/Spinner";
import {Menu, Transition} from "@headlessui/react";
import Loading from "./ui/Loading";
import PanelHeader from "./ui/PanelHeader";
import { StickyFooter } from "./ui/StickyFooter";
import { groupBy } from "lodash";

type TrainingSessionInfo = {
  address1: string,
  address2: string,
  city: string,
  credits: string,
  keyElectionId: string,
  keyPWTrainingClassId: string,
  locationContactName: string,
  locationName: string,
  notes: string,
  phone: string,
  state: string,
  teacherName: string,
  zip: string,
  createdAt: string,
  id: string,
  updatedAt: string,
  deleted: boolean,
  attendeePayAmount: number,
  capacity: number,
}

interface Schedule {
  endDateTime: string,
  instructorName: string,
  keyPWClassSessionId: string,
  notes: string,
  startDateTime: string,
  createdAt: string,
  id: string,
  updatedAt: string,
  deleted: boolean,
  endDateTimeBinary: number,
  startDateTimeBinary: number,
}

interface TrainingScheduleEntry {
  attended: false,
  attendeeInfo: never
  credits: number,
  dateMarkedAsAttended: string,
  keyEVUserId: string,
  keyPollworkerWorkHistoryId: string,
  keySessionId: string,
  notes: string,
  registrationTime: string,
  sessionInfo: TrainingSessionInfo,
  workHistoryInfo: never,
  deleted: boolean,
  id: string,
}

type TrainingScheduleWithSessionAndClass = {
  trainingSchedule: TrainingScheduleEntry,
  klass?: TrainingClass,
  schedule?: Schedule,
}

type TrainingClassSession = {
  attendeeCount: number,
  capacity: number,
  finalEndDate: string,
  firstStartDate: string,
  keyElectionId: string,
  keyPWTrainingClassId: string,
  locationName: string,
  teacherName: string,
  id: string,
}

function AddAttendeeToSessionSlideout({
  loading,
  trainingClasses,
  trainingScheduleList,
  reload: reloadAll,
  onSave,
  requiredClassesToBeTaken,
}: {
  trainingClasses: TrainingClass[],
  trainingScheduleList: TrainingScheduleEntry[],
  loading: boolean,
  reload: () => void,
  onSave: () => void,
  requiredClassesToBeTaken: TrainingClass[],
}) {
  const selectedPollworker = useSelectedPollworker();
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedSessions, setSelectedSessions] = useState<TrainingClassSession[]>([]);
  const [trainingClassSessionsCount, setTrainingClassSessionsCount] = useState<number>(0);
  const [filterByTrainingClassId, setFilterByTrainingClassId] = useState<string>('');
  const [trainingClassSessionsByClassId, setTrainingClassSessionsByClassId] = useState<{[key: string]: TrainingClassSession[]}>({});
  const [filterOptions, setFilterOptions] = useState({showHiddenSessions: false, showSelected: false});
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);

  useEffect(reload, [moduleInfo]);

  function reload() {
    if (!moduleInfo) return;

    reloadAll();

    const trainingClassSessionPromises = trainingClasses.map(({id}) => {
      if (!id) return Promise.resolve();
      return getPollworkerTrainingClassSessionList(moduleInfo.ElectionId, id);
    });

    Promise.all(trainingClassSessionPromises).then((trainingClassSessions: TrainingClassSession[][]) => {
      const trainingClassSessionsList = trainingClassSessions.flat();
      const trainingClassSessionsByClassId = trainingClassSessionsList.reduce((acc: {[key: string]: TrainingClassSession[]}, trainingClassSession: TrainingClassSession) => {
        acc[trainingClassSession.keyPWTrainingClassId] ||= [];
        acc[trainingClassSession.keyPWTrainingClassId].push(trainingClassSession);
        return acc;
      }, {});

      setTrainingClassSessionsCount(trainingClassSessionsList.length);
      setTrainingClassSessionsByClassId(trainingClassSessionsByClassId);
    });
  }

  function handleSessionSelection(sessionToAdd: TrainingClassSession, checked: boolean) {
    if (checked) return setSelectedSessions([...selectedSessions, sessionToAdd]);

    setSelectedSessions(selectedSessions.filter((trainingClassSession) => trainingClassSession.id !== sessionToAdd.id));
  }

  async function addSelectedSessions() {
    setSaving(true);

    for (const session of selectedSessions) {
      console.log(selectedPollworker.keyEVUserId, session.id)
      const response = await addAttendeeToClassSession(selectedPollworker.keyEVUserId, session.id);
      console.log('Adding user to session', session, response);
    }

    onSave();
    setSaving(false);
  }

  function isScheduledForTrainingClass(trainingClassId: string) {
    return !!trainingScheduleList.find((trainingSchedule) => trainingSchedule.sessionInfo.keyPWTrainingClassId === trainingClassId);
  }

  const isAlreadyScheduledForTrainingClass = useMemo(() => {
    return isScheduledForTrainingClass(filterByTrainingClassId);
  }, [filterByTrainingClassId, trainingScheduleList]);

  const filtersApplied = useMemo(() => !!Object.values(filterOptions).filter((opt) => opt).length, [filterOptions]);

  const trainingClassesForSelectedClass = trainingClassSessionsByClassId[filterByTrainingClassId] || [];

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div>
          <div className="px-2">
            <Flexor className='mt-2'>
              <label
                htmlFor="requirement_class"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Filter by Training Class
              </label>
              <div className="scale-75">
                <Spinner show={loading} />
              </div>
            </Flexor>
            <div className="sm:col-span-2">
              <select
                id="requirement_class"
                name="requirement_class"
                disabled={!trainingClasses.length}
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-ev-red sm:text-sm sm:leading-6"
                onChange={({target: {value}}) => setFilterByTrainingClassId(value)}
              >
                <option selected disabled>{trainingClasses.length ? 'Select a class...' : 'There are no classes to select'}</option>
                {
                  trainingClasses.map((klass) => {
                    const isRequirementFulfilled = !!requiredClassesToBeTaken.find((requiredClass) => requiredClass.id === klass.id) && isScheduledForTrainingClass(klass.id);
                    return <option selected={filterByTrainingClassId === klass.id} value={klass.id}>{klass.className} {isRequirementFulfilled ? '★' : ''}</option>
                  })
                }
              </select>
            </div>
          </div>
          {
            requiredClassesToBeTaken.length && !isAlreadyScheduledForTrainingClass ? (
              <div className="bg-blue-50 p-4 mt-3">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-blue-800">This election requires that poll workers take the following classes</h3>
                    <div className="mt-2 text-sm text-blue-700">
                      <ul role="list" className="list-disc space-y-1 pl-5">
                        {requiredClassesToBeTaken.map((reqClass) => {
                          return (<li key={`required_class_${reqClass.id}`}>{reqClass.className}</li>);
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          }
          <fieldset className="my-3">
            <legend className="text-base w-full flex items-center justify-between font-semibold leading-6 text-gray-900 px-2">
              <div>
                <Flexor className='space-x-2'>
                  <span>Sessions</span>
                  <Loading reload={reload} loading={loading} />
                </Flexor>
              </div>
              <Menu as="div" className="flex-none">
                <Menu.Button className={classNames(filtersApplied ? 'animate-pulse text-ev-red font-semibold' : 'text-gray-700', "hover:text-gray-900")}>
                  <span className="sr-only">Open options</span>
                  <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-5 z-10 mt-0 w-54 origin-bottom-left rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            // active ? 'bg-gray-50 text-green-500' : 'text-gray-800',
                            'flex justify-between space-x-4 items-center block px-3 py-1 text-sm leading-6'
                          )}
                          onClick={() => setFilterOptions({...filterOptions, showSelected: !filterOptions.showSelected})}
                        >
                          <CheckIcon className={classNames(filterOptions.showSelected ? 'visible' : 'invisible', "shrink-0 h-5 w-5")} />
                          <span>Only show selected</span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item disabled={false}>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            // active ? 'bg-ev-red text-white' : 'bg-gray-50 text-gray-900',
                            'flex justify-between space-x-4 items-center block px-3 py-1 text-sm leading-6'
                          )}
                          onClick={() => setFilterOptions({...filterOptions, showHiddenSessions: !filterOptions.showHiddenSessions})}
                        >
                          <CheckIcon className={classNames(filterOptions.showHiddenSessions ? 'visible' : 'invisible', "shrink-0 h-5 w-5")} />
                          <span>Show hidden</span>
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </legend>

            <div className="my-2 h-full pb-10 overflow-y-auto divide-y divide-gray-200 border-t border-gray-200">
              {
                isAlreadyScheduledForTrainingClass ? (
                  <div className="bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-600" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Warning</h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>
                            This pollworker has already been assigned to a session for the selected Training Class.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null
              }
              <div className="">
                <>
                  {
                    !trainingClassesForSelectedClass && filterByTrainingClassId ? (
                      <div className="my-8 flex-col items-center justify-center">
                        <div className="text-center">No sessions available for this Training Class</div>
                      </div>
                    ) : null
                  }
                  {
                    !filterByTrainingClassId ? (
                      <div className="my-8 flex-col items-center justify-center">
                        <div className="text-center">Select a Training Class to see sessions</div>
                      </div>
                    ) : null
                  }
                  {
                    trainingClassesForSelectedClass.map((session) => {
                      const disabled = isAlreadyScheduledForTrainingClass;
                      const startDate = dayjs(session.firstStartDate);
                      const endDate = dayjs(session.finalEndDate);
                      const isSelected = !!selectedSessions.find((selectedSession) => selectedSession.id === session.id) || isAlreadyScheduledForTrainingClass;

                      return (
                        <div key={`session-${session.id}`} className={classNames(disabled ? 'opacity-50' : '', "px-2 relative flex items-start py-4")}>
                          <label htmlFor='' className="min-w-0 flex-1 text-sm leading-6">
                            <div className="select-none font-medium text-gray-900">
                              <div className="text-gray-600 flex items-center space-x-2">
                                <UsersIcon className="h-4 w-4"/>
                                <span>{session.capacity - session.attendeeCount}/{session.capacity} seats left</span>
                              </div>
                              <div className="text-gray-600 flex items-center space-x-2">
                                <CalendarIcon className="h-4 w-4"/>
                                <span>{startDate?.toDate()?.toDateString()}</span>
                              </div>
                              <div className="flex items-center space-x-2">
                                <ClockIcon className="h-4 w-4"/>
                                <span>{startDate.format('hh:mm A') } → {endDate.format('hh:mm A') }</span>
                              </div>
                            </div>
                          </label>
                          <div className="h-6 mr-2">
                            <input
                              id={`session-${session.id}`}
                              name={`session-${session.id}`}
                              type="checkbox"
                              disabled={disabled}
                              checked={isSelected}
                              onChange={({target: {checked}}) => handleSessionSelection(session, checked)}
                              className="h-4 w-4 rounded border-gray-300 text-ev-red focus:ring-ev-red"
                            />
                          </div>
                        </div>
                      )
                    })
                  }
                </>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <StickyFooter>
        <div className="text-base bg-white w-full flex items-center justify-between font-semibold leading-6 text-gray-900 p-2">
          <span className="text-sm text-gray-700">{trainingClassSessionsCount} total sessions</span>
          <Button variant='tertiary' className="text-sm text-gray-700" onClick={() => setSelectedSessions([])}>Clear selection</Button>
        </div>
        <Flexor className="bg-gray-100 p-2">
          <span className="text-sm font-semibold">{selectedSessions.length} sessions selected</span>
          <Button disabled={saving} size='sm' className="flex items-center space-x-1 py-0.5 mr-0" onClick={() => addSelectedSessions()}>
            {saving ? <Spinner show /> : <PlusCircleIcon className="h-4 w-4" />}
            <span>Add selected</span>
          </Button>
        </Flexor>
      </StickyFooter>
    </div>
  );
}

export default function PollworkerTrainingClass() {
  const [trainingClasses, setTrainingClasses] = useState<TrainingClass[]>([]);
  const [trainingScheduleList, setTrainingScheduleList] = useState<TrainingScheduleEntry[]>([]);
  const [trainingScheduleListByDate, setTrainingScheduleListByDate] = useState<{[key: string]: TrainingScheduleWithSessionAndClass[]}>({});
  const [showAddClassesSlideout, setShowAddClassesSlideout] = useState<boolean>(false);
  const [loadingClassesForElection, setLoadingClassesForElection] = useState<boolean>(false);
  const [requiredClassesToBeTaken, setRequiredClassesToBeTaken] = useState<TrainingClass[]>([]);
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const selectedPollworker = useSelectedPollworker();

  useEffect(() => {
    loadPollworkerTrainingData();
  }, [selectedPollworker, moduleInfo]);

  async function loadPollworkerTrainingData() {
    if (!selectedPollworker || !moduleInfo) return;

    setLoadingClassesForElection(true);

    getPollworkerRequiredClassesToBeToken(selectedPollworker.keyEVUserId, moduleInfo.ElectionId).then((resp) => {
      const classes = JSON.parse(atob(resp.data));
      setRequiredClassesToBeTaken(classes);
    });

    const trainingClasses = await getClassesForElection(selectedPollworker.userInfo.keyCustomerId, moduleInfo.ElectionId)
      .finally(() => setLoadingClassesForElection(false));

    setTrainingClasses(trainingClasses);

    getPollworkerClassMatrix(selectedPollworker.pollworkerInfo.keyEVUserId, moduleInfo.ElectionId).then(({data}) => {
      const classMatrix = JSON.parse(atob(data));

      classMatrix.scheduleList = classMatrix.scheduleList.map((schedule: Schedule) => {
        return {
          ...schedule,
          // The backend will convert the UTC timestamps to the user's supposed timezone (-5, EST)
          // we want to do out own conversion here and manage from a UTC timestamp
          startDateTime: dayjs(schedule.startDateTime).add(5, 'hours').toDate().toISOString(),
          endDateTime: dayjs(schedule.endDateTime).add(5, 'hours').toDate().toISOString(),
        };
      });

      const sortedTrainingScheduleListWithMeta = classMatrix!.trainingScheduleList
        .map((trainingSchedule: TrainingScheduleEntry): TrainingScheduleWithSessionAndClass => {
          const klass = trainingClasses.find((trainingClass: TrainingClass) => trainingClass.id === trainingSchedule.sessionInfo.keyPWTrainingClassId);
          const schedule = classMatrix.scheduleList.find((schedule: Schedule) => schedule.keyPWClassSessionId === trainingSchedule.keySessionId);
          return { klass, schedule, trainingSchedule }
        })
        .sort((a: TrainingScheduleWithSessionAndClass, b: TrainingScheduleWithSessionAndClass) => {
          if (!a.schedule) return -1;
          if (!b.schedule) return 1;

          return dayjs(a.schedule.startDateTime) > dayjs(b.schedule.startDateTime) ? -1 : 1;
        });

      const groupedTrainingScheduleListWithMeta = groupBy(sortedTrainingScheduleListWithMeta, ({schedule}: {schedule: Schedule}) => {
        return dayjs(schedule!.startDateTime).toISOString();
      });

      setTrainingScheduleList(classMatrix.trainingScheduleList);
      setTrainingScheduleListByDate(groupedTrainingScheduleListWithMeta);
    });
  }

  function deleteTraining(scheduleEntry: TrainingScheduleEntry) {
    if (!window.confirm('Are you sure you want to delete this training?')) return;

    removeAttendeeFromClassSession(scheduleEntry.id).then((resp) => {
      loadPollworkerTrainingData();
    })
    .catch(console.error)
    .finally(loadPollworkerTrainingData)
  }

  return (
    <div>
      <PanelHeader title='Training' loading={loadingClassesForElection} reload={loadPollworkerTrainingData} onAdd={() => setShowAddClassesSlideout(true)} />
      <PanelSlideout show={showAddClassesSlideout}>
        <div className="bg-gray-50 px-4 py-3 sm:px-3 sticky top-0 z-50 border-b border-gray-200">
          <div className="flex items-start justify-between space-x-3">
            <div className="space-y-1">
              <SectionSubSubHeading>Add Classes to {selectedPollworker.userInfo.firstName} {selectedPollworker.userInfo.lastName}</SectionSubSubHeading>
            </div>
            <div className="flex h-7 items-center">
              <button
                type="button"
                className="relative text-gray-400 hover:text-gray-500"
                onClick={() => setShowAddClassesSlideout(false)}
              >
                <span className="absolute -inset-2.5" />
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <AddAttendeeToSessionSlideout
          loading={loadingClassesForElection}
          reload={loadPollworkerTrainingData}
          trainingClasses={trainingClasses}
          requiredClassesToBeTaken={requiredClassesToBeTaken}
          trainingScheduleList={trainingScheduleList}
          onSave={() => {
            setShowAddClassesSlideout(false);
            loadPollworkerTrainingData();
          }}
        />
      </PanelSlideout>
      <ul className="mt-4">
        {
          !trainingScheduleList.length ? (
            <Flexor justify="center" className="space-x-2 mt-10">
              <NoSymbolIcon className="h-6 w-6" />
              <SectionSubSubHeading>No scheduled classes found</SectionSubSubHeading>
            </Flexor>
          ) : null
        }
        {
          Object.keys(trainingScheduleListByDate).map((trainingDate: string) => {
            const trainingSchedules = trainingScheduleListByDate[trainingDate];

            return (
              <dl key={trainingDate}>
                <div className="p-2 bg-gray-50 border-b-2 border-gray-100">
                  <SectionSubSubHeading>{dayjs(trainingDate).format('ddd, MMM D, YYYY')}</SectionSubSubHeading>
                </div>
                {
                  trainingSchedules.map((trainingScheduleWithSessionAndClass) => {
                    const {trainingSchedule, klass, schedule} = trainingScheduleWithSessionAndClass;

                    const startTime = schedule ? dayjs(schedule.startDateTime) : null;
                    const inFuture = startTime && dayjs() < startTime && !trainingSchedule.attended;

                    return (
                      <li key={klass?.id} className="relative flex justify-between py-5 hover:bg-gray-50 px-3">
                        <div className="flex items-center gap-x-4">
                          { trainingSchedule.attended ? (
                            <Tippy content={<TippyContent>This training has been attended and completed!</TippyContent>}>
                              <CheckBadgeIcon className="h-6 w-6 flex-none rounded-full bg-green-500" />
                            </Tippy>
                          ) : null }
                          { inFuture ? (
                            <Tippy content={<TippyContent>This training is schedule for the future</TippyContent>}>
                              <ClockIcon className="h-6 w-6 flex-none rounded-full text-yellow-600" />
                            </Tippy>
                          ) : null }
                          <div className="min-w-0 flex-auto">
                            <div className="flex items-center text-xs leading-5 text-gray-900 space-x-2">
                              <p className="text-sm leading-6">{klass?.className}</p>
                              <span>·</span>
                              <Tippy content={<TippyContent>{trainingSchedule.credits} Credits</TippyContent>}>
                                <span className="text-sm font-semibold">{trainingSchedule.credits} credits</span>
                              </Tippy>
                            </div>
                            <p className="mt-1 text-sm">
                              {trainingSchedule.sessionInfo.locationName}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-end">
                          <div className="hidden sm:block">
                            <p className="mt-1 text-xs leading-5 text-blue-600 font-semibold flex items-center">
                              {
                                trainingSchedule.attended ? (
                                  <>
                                    <ShieldCheckIcon className="h-4 w-4 mr-1" />
                                    <span>Verified</span>
                                  </>
                                ) : null
                              }
                            </p>
                          </div>
                          <TrashIcon onClick={() => deleteTraining(trainingSchedule)} className="h-5 w-5 flex-none hover:text-ev-red text-gray-400" aria-hidden="true" />
                        </div>
                      </li>
                    );
                  })
                }
              </dl>
            )
          })
        }
      </ul>
    </div>
  )
}
