import {useDispatch, useSelector} from "react-redux";
import {getShowPollworkerDetails} from "admin/src/selectors/pollworker";
import React, { ReactElement, useState } from "react";
import {setShowPollworkerDetailsId} from "admin/src/reducers/pollworker/grid";
import {PollworkerWorkHistory} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import { Flexor } from "shared/src/components";
import {
  AcademicCapIcon,
  ChatBubbleLeftIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  CurrencyDollarIcon,
  DocumentIcon,
  ExclamationTriangleIcon,
  IdentificationIcon,
  PencilIcon,
  PhoneIcon, StarIcon,
  TableCellsIcon, UserIcon, XCircleIcon
} from "@heroicons/react/24/outline";
import {classNames} from "shared/src/utils/classNames";
import {openNativeDialog} from "../utils/nativeDialog";
import {dialogs} from "../dialogs";
import PollworkerCustomFieldsPanel from "./PollworkerCustomFieldsPanel";
import PollworkerMessagesPanel from "./PollworkerMessagesPanel";
import PollworkerSchedulesPanel from "./SchedulesPanel/PollworkerSchedulePanel";
import PollworkerInfoPanel from "./PollworkerInfoPanel";
import PollworkerDocumentsPanel from "./PollworkerDocumentsPanel";
import PollworkerRequirementsPanel from "./PollworkerRequirementsPanel";
import PollworkerLogsPanel from "./PollworkerLogsPanel";
import PollworkerSkillsPanel from "./PollworkerSkillsPanel";
import PollworkerContactsPanel from "./PollworkerContactsPanel";
import PollworkerTrainingClass from "./PollworkerTrainingPanel";
import PollworkerExpensesPanel from "./PollworkerExpensesPanel";
import useSelectedPollworker from "admin/src/hooks/useSelectedPollworker";

export type PanelNavigationItem = {
  name: string,
  classes: string,
  icon: ReactElement,
  current: boolean,
  component: (props: PanelItemProps) => ReactElement,
  showQuickDetails?: boolean,
}

type PanelItemProps = { pollworker: PollworkerWorkHistory, readOnly?: boolean, onSave?: () => void }

export const pollworkerPanelsByName: { [panelName: string]: PanelNavigationItem } = {
  details: {
    name: 'Details',
    classes: 'pt-3',
    icon: <IdentificationIcon className="h-5 w-5"/>,
    current: false,
    component: ({ readOnly = false }) => <PollworkerInfoPanel readOnly={readOnly} />,
    showQuickDetails: false
  },
  fields: {
    name: 'Fields',
    classes: 'pt-3',
    icon: <TableCellsIcon className="h-5 w-5"/>,
    current: false,
    component: ({ pollworker: selectedPollworker }) => <PollworkerCustomFieldsPanel pollworker={selectedPollworker}/>,
    showQuickDetails: true
  },
  messages: {
    name: 'Messages',
    classes: 'pt-3',
    icon: <ChatBubbleLeftIcon className="h-5 w-5"/>,
    current: false,
    component: () => <PollworkerMessagesPanel />,
    showQuickDetails: true
  },
  time: {
    name: 'Time',
    classes: 'pt-3',
    icon: <ClockIcon className="h-5 w-5"/>,
    current: false,
    component: ({ onSave }) => <PollworkerSchedulesPanel onSave={onSave} />,
    showQuickDetails: true
  },
  expenses: {
    name: 'Expenses',
    classes: 'pt-3',
    icon: <CurrencyDollarIcon className="h-5 w-5"/>,
    current: false,
    component: () => <PollworkerExpensesPanel/>,
    showQuickDetails: true
  },
  training: {
    name: 'Training',
    classes: 'pt-3',
    icon: <AcademicCapIcon className="h-5 w-5"/>,
    current: false,
    showQuickDetails: true,
    component: () => <PollworkerTrainingClass/>
  },
  documents: {
    name: 'Documents',
    classes: 'pt-3',
    icon: <DocumentIcon className="h-5 w-5"/>,
    current: true,
    showQuickDetails: true,
    component: () => <PollworkerDocumentsPanel/>
  },
  requirements: {
    name: 'Requirements',
    classes: 'pt-3',
    icon: <ExclamationTriangleIcon className="h-5 w-5"/>,
    current: false,
    component: () => <PollworkerRequirementsPanel/>,
    showQuickDetails: true
  },
  log: {
    name: 'Log',
    classes: 'pt-3',
    icon: <ClipboardDocumentListIcon className="h-5 w-5"/>,
    current: false,
    showQuickDetails: true,
    component: () => <PollworkerLogsPanel/>
  },
  skills: {
    name: 'Skills',
    classes: 'pt-3',
    icon: <StarIcon className="h-5 w-5"/>,
    current: false,
    showQuickDetails: true,
    component: () => <PollworkerSkillsPanel/>
  },
  contacts: {
    name: 'Contacts',
    classes: 'pt-3',
    icon: <PhoneIcon className="h-5 w-5"/>,
    current: false,
    showQuickDetails: true,
    component: () => <PollworkerContactsPanel/>
  },
};

const panels: PanelNavigationItem[] = [
  pollworkerPanelsByName.details,
  pollworkerPanelsByName.fields,
  pollworkerPanelsByName.messages,
  pollworkerPanelsByName.time,
  pollworkerPanelsByName.expenses,
  pollworkerPanelsByName.training,
  pollworkerPanelsByName.documents,
  pollworkerPanelsByName.requirements,
  pollworkerPanelsByName.log,
  pollworkerPanelsByName.skills,
  pollworkerPanelsByName.contacts
];

export default function PollworkerDetails({readOnly = false, onSave = () => {}}: {readOnly?: boolean, onSave?: (panelName: string) => void}) {
  const [panel, setPanel] = useState<PanelNavigationItem>(panels[0]);
  const showPollworkerDetails = useSelector(getShowPollworkerDetails);
  const pollworker = useSelectedPollworker();
  const dispatch = useDispatch();

  function pollworkerName(showPollworkerDetails: PollworkerWorkHistory) {
    return [
      showPollworkerDetails.pollworkerInfo.userInfo.prefix,
      showPollworkerDetails.pollworkerInfo.userInfo.firstName,
      showPollworkerDetails.pollworkerInfo.userInfo.lastName,
      showPollworkerDetails.pollworkerInfo.userInfo.suffix,
    ].join(' ');
  }

  const userId = showPollworkerDetails?.pollworkerInfo?.userInfo?.userId;

  return (
    <>
      {
        showPollworkerDetails ? (
          <div className='flex flex-col h-full justify-start'>
            <div className="bg-white divide-y divide-gray-400/50 border-b border-gray-400/50 sticky top-0 z-10">
              <Flexor className='pl-3'>
                <Flexor className='flex space-x-2 flex-grow items-center font-semibold relative cursor-default py-1.5 pr-10 text-left text-gray-900 focus:outline-none sm:text-sm sm:leading-6' justify="start">
                  <div>{pollworkerName(showPollworkerDetails)}</div>
                  <span className="hidden lg:flex items-center text-gray-700 bg-gray-50 ring-gray-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                    <UserIcon className='h-3 w-3 mr-1' />
                    {userId ? <span>{userId}</span> : <span className='italic text-gray-500'>No User ID</span>}
                  </span>
                </Flexor>
                <button>
                  <IdentificationIcon
                    onClick={() => openNativeDialog(showPollworkerDetails, dialogs.detailsDialog)}
                    className="mr-3 h-5 w-5 text-gray-800 group-hover:text-gray-800"
                    aria-hidden="true"
                  />
                </button>
                <button>
                  <PencilIcon
                    onClick={() => openNativeDialog(showPollworkerDetails, dialogs.editDialog)}
                    className="mr-3 scale-90 h-5 w-5 text-gray-800 group-hover:text-gray-800"
                    aria-hidden="true"
                  />
                </button>
                <button>
                  <XCircleIcon className="mr-3 h-5 w-5 text-gray-800 group-hover:text-gray-800"
                    aria-hidden="true"
                    onClick={() => dispatch(setShowPollworkerDetailsId(undefined))}
                  />
                </button>
              </Flexor>
              <div className="border-y border-gray-200">
                <nav className="-mb-px flex justify-between mt-2 overflow-x-auto w-full">
                  {
                    panels.map((tab) => (
                      <a
                        key={tab.name}
                        title={tab.name}
                        onClick={() => setPanel(tab)}
                        className={classNames(
                          tab.name === panel?.name
                            ? 'border-ev-red text-ev-red'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'whitespace-nowrap w-full border-b-2 pb-2 px-1 text-sm font-medium flex justify-center'
                        )}
                      >
                        <span className="block">{tab.icon}</span>
                      </a>
                    ))
                  }
                </nav>
              </div>
            </div>
            <div className={classNames(panel.classes, 'relative')}>
              { panel.component({ pollworker, readOnly, onSave: () => onSave(panel.name) }) }
            </div>
          </div>
        ) : null
      }
    </>
  )
}
