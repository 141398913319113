import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {AppState} from "admin/src/reducers";
import { Flexor, Spinner } from "shared/src/components";
import { Dropdown } from "shared/src/components/ui";
import {
  getPollworkerById,
  getPollworkerCustomFields,
  getPollworkerLoadCustomFieldsDocument,
  updateCustomField
} from "admin/src/fetchers";
import {
  setPollworkerCustomFieldsData,
  updatePollworker as updatePollworkerState
} from "admin/src/reducers/pollworker/grid";
import debounce from "lodash.debounce";
import {CheckIcon} from "@heroicons/react/24/outline";
import {useClickAway} from "react-use";
import {PollworkerWorkHistory} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import dayjs from "dayjs";
import PanelHeader from "./ui/PanelHeader";
import { classNames } from "shared/src/utils/classNames";

type CustomField = {
  dataTypeId: number,
  moduleId: number,
  objectId: number,
  deleted: boolean,
  description: string,
  keyCustomerId: string,
  promptText: string,
  createdAt: string,
  id: string,
  updatedAt: string,
  _rid: string,
  _self: string,
  _etag: string,
  _attachments: string,
  _ts: string,
};

export default function PollworkerCustomFieldsPanel({pollworker}: {pollworker: PollworkerWorkHistory}) {
  const targetEditElem = useRef(null);
  const customFields = useSelector((state: AppState) => state.pollworker.grid.customFields) as CustomField[];
  const [customFieldMetadata, setCustomFieldsMetadata] = useState<any>({});
  const [valueToSave, setValueToSave] = useState<[field: CustomField, value: any]>();
  const [editingId, setEditingId] = useState<string>();
  const [savingId, setSavingId] = useState<string>();
  const [loadingCustomFields, setLoadingCustomFields] = useState<boolean>(true);
  const dispatch = useDispatch();

  useClickAway(targetEditElem, () => {
    setEditingId(undefined);
    setSavingId(editingId);
  });

  useEffect(() => {
    loadCustomFields();
  }, []);

  useEffect(() => {
    if (!valueToSave || editingId) return;

    const [customField, value] = valueToSave;
    save(customField, value);
  }, [valueToSave, editingId]);

  function loadCustomFields() {
    setLoadingCustomFields(true);

    return getPollworkerLoadCustomFieldsDocument(pollworker.keyPollworkerId)
    .then((data) => {
      if (!data) return;

      setCustomFieldsMetadata(data);
    })
    .finally(() => {
      setLoadingCustomFields(false);
    });
  }

  const save = debounce(async (customField: CustomField, value: string) => {
    let payload = pollworker._customFields;
    let newValue;

    if (customField.dataTypeId === 3) {
      newValue = value === 'yes';
    } else {
      newValue = value;
    }

    if (newValue === payload[customField.promptText]) return;

    payload[customField.promptText] = newValue;

    payload.ObjectType = 'Pollworker';
    payload.id = pollworker.keyPollworkerId; // "id" must be lowercase
    payload.KeyCustomerId = pollworker.userInfo.keyCustomerId;
    payload = {...customFieldMetadata, ...payload};

    await updateCustomField(pollworker.keyPollworkerId, payload);

    const customFields = await getPollworkerCustomFields(pollworker.userInfo.keyCustomerId);
    dispatch(setPollworkerCustomFieldsData(customFields));

    getPollworkerById(pollworker).then((updatedPollworker) => {
      dispatch(updatePollworkerState(updatedPollworker));
      setSavingId(undefined);
    });
  }, 1000);

  function renderCustomFieldEditor(customField: CustomField) {
    const defaultValue = pollworker._customFields[customField.promptText];
    let editor = null;

    if (customField.dataTypeId === 3) {
      let selectedValue = defaultValue ? 'yes' : 'no';
      if (valueToSave) selectedValue = valueToSave[1];

      editor = (
        <Dropdown
          autoFocus
          ref={targetEditElem}
          placeholder='Yes or no'
          emptyPlaceholder='No entry'
          name={customField.promptText}
          selectedValue={selectedValue}
          id={`customFieldEditing_${customField.id}`}
          onChange={({target: {value}}) => setValueToSave([customField, value])}
          options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
        />
      )
    }

    if (customField.dataTypeId === 4) {
      editor = (
        <input
          autoFocus
          type='date'
          ref={targetEditElem}
          defaultValue={defaultValue}
          onChange={({target: {value}}) => setValueToSave([customField, value])}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
        />
      );
    }

    if (customField.dataTypeId === 2) {
      editor = (
        <input
          ref={targetEditElem}
          type='number'
          defaultValue={defaultValue}
          onChange={({target: {value}}) => setValueToSave([customField, value !== '' ? Number(value) : null])}
          autoFocus
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
        />
      );
    }

    editor ||= (
      <input
        type='text'
        ref={targetEditElem}
        defaultValue={defaultValue}
        onChange={({target: {value}}) => setValueToSave([customField, value])}
        autoFocus
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
      />
    );

    return (
      <Flexor>
        {editor}
        <CheckIcon data-testid="save_custom_field" className="shrink ml-2 h-7 w-7" onClick={() => setEditingId(undefined)} />
      </Flexor>
    )
  }

  function renderCustomFieldValue(customField: CustomField) {
    if (savingId === customField.id) {
      return (<Spinner show />);
    }

    if (!pollworker._customFields[customField.promptText] && customField.dataTypeId !== 3) {
      return (<span className="italic text-gray-600">Not set</span>);
    }

    switch (customField.dataTypeId) {
    case 3:
      return pollworker._customFields[customField.promptText] ? 'Yes' : 'No';
    case 4:
      return dayjs(pollworker._customFields[customField.promptText]).format('MM-DD-YYYY');
    default:
      return pollworker._customFields[customField.promptText];
    }
  }

  const initLoading = loadingCustomFields && !Object.keys(customFieldMetadata).length;

  if (!pollworker) return null;

  return (
    <div>
      <div className="border-b border-gray-200 inset">
        <PanelHeader title={'Custom Fields'} loading={loadingCustomFields} reload={loadCustomFields} />
      </div>
      {
        initLoading ? (
          <Flexor justify="center" className="space-x-2 mt-10">
            <Spinner show />
            <SectionSubSubHeading>Loading custom fields...</SectionSubSubHeading>
          </Flexor>
        ) : (
          <div className={classNames(loadingCustomFields ? 'opacity-50' : '', 'transition-opacity divide-y divide-gray-200 px-2')}>
            {
              customFields.map((customField) => {
                return (
                  <dl className="mt-1" data-testid={customField.id} key={customField.id}>
                    <div className="flex items-center justify-between py-3 text-sm font-medium">
                      <dt className="grow">
                        <div className="text-gray-800 font-medium">{customField.promptText}</div>
                        {customField.description ? <div className="font-normal text-gray-500">{customField.description}</div> : null}
                      </dt>
                      <dd className="text-sm text-gray-900 shrink max-w-1/2">
                        <button className="text-left" onClick={() => setEditingId(customField.id)}>
                          {renderCustomFieldValue(customField)}
                        </button>
                      </dd>
                    </div>
                    {
                      customField.id === editingId ? (
                        renderCustomFieldEditor(customField)
                      ) : null
                    }
                  </dl>
                )
              })
            }
          </div>
        )
      }
    </div>
  )
}
