import { useSelector } from "react-redux";
import { AppState } from "../../../reducers";
import React, { useEffect } from "react";
import ModuleInfoChangeManager from "../utils/ModuleInfoChangeManager";
import { PollworkerVotingLocationDetails } from "./PollworkerVotingLocationDetails";
import useHandlePollworkers from "../../../hooks/useHandlePollworkers";

export default function PollworkerVotingLocationScheduler() {
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const [loadingPollworkers, , handlePollworkers] = useHandlePollworkers();

  useEffect(() => {
    if (!moduleInfo?.ElectionId) return;

    handlePollworkers();
  }, [moduleInfo?.ElectionId]);

  return (
    <>
      <ModuleInfoChangeManager/>
      <div className="relative w-full h-full">
        <div className="h-full flex flex-col justify-between">
          <PollworkerVotingLocationDetails reload={handlePollworkers} loading={loadingPollworkers} />
        </div>
      </div>
    </>
  )
}
