import {Menu, Transition} from "@headlessui/react";
import {ChevronUpDownIcon, Cog6ToothIcon} from "@heroicons/react/24/outline";
import {Fragment, useState} from "react";
import {classNames} from "shared/src/utils/classNames";
import {useDispatch} from "react-redux";
import {setQuickFilter} from "admin/src/reducers/pollworker/grid";

export default function GridResetDropdown({resetRef}: {resetRef: React.MutableRefObject<any>}) {
  const [hover, setHover] = useState<boolean>(false)
  const dispatch = useDispatch();

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
        <Menu.Button className="flex items-center justify-center p-1 h-full text-sm space-x-0.5 text-gray-800 px-2">
          <Cog6ToothIcon className={classNames(hover ? 'animate-spin' : '', 'h-4 w-4')} />
          <span>Reset</span>
          <ChevronUpDownIcon className="-mr-1 h-4 w-4 text-gray-800" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-[0.25em] w-48 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => resetRef.current.reset.resetFilters()}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Reset Filters
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => resetRef.current.reset.resetColumnState()}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Reset Columns
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => {
                    dispatch(setQuickFilter(undefined));
                  }}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Clear Quick Search
                </a>
              )}
            </Menu.Item>
            <hr className="my-1" />
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => resetRef.current.reset.resetAll()}
                  type="submit"
                  className={classNames(
                    active ? 'bg-ev-red text-white' : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm'
                  )}
                >
                  Reset All
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
