import {AppState} from "../reducers";
import {ConsumableItem} from "../types/ConsumableItem";
import {CustomerInfo} from "../types/CustomerInfo";

export const getParentAccountName = (state: AppState) => {
  const parentAccount = getParentAccount(state);
  return parentAccount?.customerName;
}

export const getItemInCart = (id: string | undefined) => {
  return (state: AppState) => {
    if (!id) return null;
    return state.cart.find((item) => item.keyInventoryItemId === id);
  }
}

export const getConsumableItems = (state: AppState): ConsumableItem[] => (state.consumables.data);

export const getParentAccount = (state: AppState): CustomerInfo | undefined => (state.user?.parentAccount);

export const getAccount = (state: AppState): CustomerInfo | undefined => (state.user?.account);

export const selectIsStateUser = (state: AppState): boolean => {
  if (state.user && state.user.account && state.user.parentAccount) {
    return state.user.account.id === state.user.parentAccount.id;
  }

  return false;
}

export const getConsumableItem = (id: string | undefined) => {
  return (state: AppState) => {
    return state.consumables.data.find((i: ConsumableItem) => i.id === id)
  };
}

export const getOrders = () => {
  return (state: AppState) => {
    return state.orders;
  }
}
