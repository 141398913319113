import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import {useSelector} from "react-redux";
import React, {Fragment, Suspense, useEffect, useRef, useState} from "react";
import {
  createPollworkerExpense, getPollworkerRoles,
  loadPollworkerExpenses,
  patchPollworkerExpense,
} from "admin/src/fetchers";
import {AppState} from "admin/src/reducers";
import {
  BanknotesIcon,
  CalendarDaysIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  NoSymbolIcon,
  TruckIcon,
  PencilSquareIcon, TicketIcon, TrashIcon,
} from "@heroicons/react/24/outline";
import { CheckIcon, ClipboardDocumentListIcon } from "@heroicons/react/16/solid";
import { Flexor } from "shared/src/components";
import { Input, Textarea, Dropdown, Label, ButtonGroup } from "shared/src/components/ui";
import useSelectedPollworker from "admin/src/hooks/useSelectedPollworker";
import Spinner from "shared/src/components/Spinner";
import dayjs from "dayjs";
import PanelSlideout, { PanelSlideoutHeader } from "./ui/PanelSlideout";
import {classNames} from "shared/src/utils/classNames";
import {Button} from "shared/src/components/ui";
import { Menu, Transition } from "@headlessui/react";
import {EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import {Expense, PollworkerReimbursementStatus} from "admin/src/types/Pollworker/PollworkerModuleInfo";
import {currencyFormat} from "simple-currency-format";
import {CurrencyInput} from "./Currency";
import {createUploadId, uploadToStorage} from "./utils/documentUpload";
import {BlobImage, ImageFromFileReader} from "admin/src/screens/store/StoreItemImage";
import DeprecatedModal from "shared/src/components/DeprecatedModal";
import {RoleInfo} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import PanelHeader from "./ui/PanelHeader";
import { StickyFooter } from "./ui/StickyFooter";
import { groupBy } from "lodash";

export function calculateExpenseChargeAmount(mileage: number, mileageRate: number) {
  return mileage * mileageRate;
}

export function ExpenseEditor({expense: expenseToEdit, toggle}: { expense?: Expense, toggle: () => void }) {
  const [expense, setExpense] = useState<Partial<Expense>>();
  const [saving, setSaving] = useState(false);
  const [roles, setRoles] = useState<RoleInfo[]>([]);
  const [expenseType, setExpenseType] = useState<'mileage' | 'reimbursement'>('reimbursement');
  const [attachedReceipt, setAttachedReceipt] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const pollworker = useSelectedPollworker();
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const [selectedRole, setSelectedRole] = useState<RoleInfo>();

  useEffect(() => {
    setExpense({
      ...expenseToEdit,
      expenseDate: dayjs(expenseToEdit?.expenseDate || new Date()).format('YYYY-MM-DD'),
      chargeAmount: expenseToEdit?.chargeAmount || 0,
    });

    setExpenseType(expenseToEdit?.mileage ? 'mileage' : 'reimbursement');
  }, [expenseToEdit]);

  useEffect(() => {
    if (expense?.mileage && selectedRole) {
      setExpense({...expense, chargeAmount: calculateExpenseChargeAmount(expense.mileage, selectedRole.mileageRate)});
    }
  }, [expense?.mileage, selectedRole]);

  useEffect(() => {
    if (expenseType === 'mileage' && pollworker) {
      getPollworkerRoles(pollworker.userInfo.keyCustomerId).then(setRoles);
    }
  }, [expenseType, pollworker]);

  function save() {
    if (!expense || !moduleInfo?.ElectionId) return;

    setSaving(true);

    let upsertExpensePromise;
    const payload = {
      ...expense,
      keyEVUserId: pollworker.pollworkerInfo.keyEVUserId,
      // @ts-ignore
      expenseDate: new Date(expense.expenseDate).toISOString(),
      keyElectionId: moduleInfo.ElectionId,
      mileage: expenseType === 'mileage' ? expense.mileage : undefined,
    };

    if (expense.id) {
      upsertExpensePromise = patchPollworkerExpense(payload);
    }

    upsertExpensePromise ||= createPollworkerExpense({
      ...expense,
      keyEVUserId: pollworker.pollworkerInfo.keyEVUserId,
      // @ts-ignore
      expenseDate: new Date(expense.expenseDate).toISOString(),
      keyElectionId: moduleInfo.ElectionId,
    });

    upsertExpensePromise
      .then(({id: expenseId}) => {
        if (!attachedReceipt) return toggle();

        uploadToStorage(attachedReceipt, createUploadId(pollworker.userInfo.keyCustomerId, 'expenses', attachedReceipt, expenseId))
          .then(toggle)
          .finally(() => setSaving(false));
      })
      .finally(() => {
        if (!attachedReceipt) setSaving(false);
      });
  }

  const canSave = expense && !!expense.expenseDate && (expenseType === 'mileage' ? !!expense.mileage && !!selectedRole : true);

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="px-2 my-2">
          <Label>Type</Label>
          <ButtonGroup className="isolate inline-flex w-full ring-1 ring-gray-400">
            <button
              type="button"
              disabled={!!expenseToEdit?.id}
              onClick={() => setExpenseType('reimbursement')}
              className={classNames(expenseType === 'reimbursement' ? 'bg-green-200 text-green-900' : 'text-gray-900', "disabled:opacity-50 w-full relative inline-flex justify-center rounded-l-md px-3 py-2 text-sm font-semibold focus:z-10")}
            >
              <CurrencyDollarIcon className="mr-1 h-5 w-5" />
              Reimbursement
            </button>
            <button
              type="button"
              disabled={!!expenseToEdit?.id}
              onClick={() => setExpenseType('mileage')}
              className={classNames(expenseType === 'mileage' ? 'bg-blue-200 text-blue-900' : 'text-gray-900', "disabled:opacity-50 w-full relative -ml-px inline-flex justify-center rounded-r-md px-3 py-2 text-sm font-semibold focus:z-10")}
            >
              <TruckIcon className="mr-1 h-5 w-5" />
              Mileage
            </button>
          </ButtonGroup>
        </div>

        {
          expenseType === 'mileage' ? (
            <div className="px-2">
              <Label htmlFor='role'>
                <Flexor>
                  <span>Role</span>
                  {!selectedRole ? <span className="text-xs text-ev-red/80 font-semibold">Required</span> : <CheckIcon className="h-4 w-4 text-green-500" /> }
                </Flexor>
              </Label>
              <Dropdown
                autoFocus
                id='role'
                name='role'
                placeholder='Select a role'
                selectedValue={selectedRole?.id}
                options={roles.map((role) => ({ label: `${role.roleName} - (${role.mileageRate}/mi)`, value: role.id, disabled: !role.drivingReimburseable }))}
                onChange={({target: {value}}) => {
                  setSelectedRole(roles.find((r) => r.id === value));
                }}
                emptyPlaceholder={'Loading...'}
              />
            </div>
          ) : null
        }

        <div className="px-2 my-4 flex items-center space-x-4">
          <div className="w-full">
            <Label htmlFor="expense_amount">Amount</Label>
            <div data-testid='expense-amount'>
              <CurrencyInput disabled={expenseType === 'mileage'} value={expense?.chargeAmount || 0} onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                setExpense({...expense, chargeAmount: Number(value)});
              }} />
            </div>
          </div>
          {
            expenseType === 'mileage' ? (
              <div className="w-full">
                <Label htmlFor="expense_mileage">
                  <Flexor>
                    <span>Miles</span>
                    {!expense?.mileage || expense.mileage < 1 ? <span className="text-xs text-ev-red/80 font-semibold">Required</span> : <CheckIcon className="h-4 w-4 text-green-500" /> }
                  </Flexor>
                </Label>
                <Input
                  type='number'
                  min={1}
                  placeholder="Mileage #"
                  defaultValue={expense?.mileage}
                  onChange={({target: {value}}) => setExpense({...expense, mileage: Number(value)})}
                  id="expense_mileage"
                  name="expense_mileage"
                />
              </div>
            ) : null
          }
        </div>

        <div className="px-2">
          <Label htmlFor="expense_date">
            <Flexor>
              <span>Date</span>
              {!expense?.expenseDate ? <span className="text-xs text-ev-red/80 font-semibold">Required</span> : <CheckIcon className="h-4 w-4 text-green-500" /> }
            </Flexor>
          </Label>
          <Input
            type='date'
            defaultValue={expense?.expenseDate}
            onChange={({target: {value}}) => setExpense({...expense, expenseDate: value})}
            id="expense_date"
            name="expense_date"
          />
        </div>

        <div className="px-2 mt-4">
          <Label htmlFor="expense_notes">
            <Flexor>
              <span>Notes</span>
              <span className="text-xs text-gray-500 italic font-semibold">Optional</span>
            </Flexor>
          </Label>
          <div className="sm:col-span-2">
            <Textarea
              id="expense_notes"
              name="expense_notes"
              placeholder="Add any extra notes you may have for this expense..."
              rows={3}
              defaultValue={expense?.notes}
              onBlur={({target: {value}}) => setExpense({...expense, notes: value})}
            />
          </div>
        </div>

        <div className="space-y-1 px-2 mt-4">
          <Flexor>
            <label
              className="block text-sm font-medium text-gray-900"
            >
              Attachments
            </label>
            <span className="text-xs text-gray-500 italic font-semibold">Optional</span>
          </Flexor>
          {
            attachedReceipt ? (
              <Suspense fallback={(<Spinner large={false} show />)}>
                <ImageFromFileReader alt='Selected expense receipt to upload' file={attachedReceipt} classes='mx-auto w-1/2' />
              </Suspense>
            ) : (
              <Suspense fallback={(
                <div className="text-sm border-2 border-gray-500 border-dashed rounded-lg p-5 text-center">
                  No attachments
                </div>
              )}>
                <BlobImage className='mx-auto w-1/2' skipCache customerId={pollworker?.userInfo?.keyCustomerId} entityType={'expenses'} id={`${expense?.id}.jpg`} alt="Receipt image for an expense" />
              </Suspense>
            )
          }

          <input ref={fileInputRef} type="file" accept='image/jpeg,image/png' className="hidden" onChange={(event) => {
            const files = event.target.files || [];

            if (!files.length) return;
            if (!event.target.accept.split(',').includes(files[0].type)) return;

            setAttachedReceipt(files[0]);
          }} />

          {
            attachedReceipt ? (
              <Button onClick={() => setAttachedReceipt(undefined)} className="w-full flex justify-center">
                <ClipboardDocumentListIcon className="h-4 w-4" />
                <span>Remove Receipt</span>
              </Button>
            ) : (
              <Button onClick={() => fileInputRef.current?.click()} className="w-full flex justify-center">
                <ClipboardDocumentListIcon className="h-4 w-4" />
                {expenseToEdit ? <span>Add or Replace Receipt</span> : <span>Add Receipt</span>}
              </Button>
            )
          }
        </div>
      </div>
      <StickyFooter>
        <Flexor className='p-2 mt-2 bg-gray-100'>
          <span>{saving ? 'Saving...' : null}</span>
          <Button disabled={!canSave} onClick={() => save()}>Save</Button>
        </Flexor>
      </StickyFooter>
    </div>
  );
}

export default function PollworkerExpensesPanel() {
  const [showSlideout, setShowSlideout] = useState<boolean>(false);
  const [expenses, setExpenses] = useState<{[key: string]: Expense[]}>({});
  const [expenseToEdit, setExpenseToEdit] = useState<Expense>();
  const [loadingExpenses, setLoadingExpenses] = useState<boolean>(true);
  const [expandedExpenseId, setExpandedExpenseId] = useState<string>();
  const [showExpandedImageId, setShowExpandedImageId] = useState<string>();
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const pollworker = useSelectedPollworker();

  useEffect(() => {
    if (!moduleInfo || !pollworker) return;

    loadExpenses();
  }, [moduleInfo, pollworker]);

  function loadExpenses() {
    if (!pollworker || !moduleInfo?.ElectionId) return;

    setLoadingExpenses(true);
    loadPollworkerExpenses(moduleInfo.ElectionId, pollworker.keyEVUserId)
    .then(async (resp) => {
      const expenses = JSON.parse(atob(resp.data)).sort((a: Expense, b: Expense) => {
        return dayjs(a.createdAt) > dayjs(b.createdAt) ? -1 : 1;
      });

      const expensesByType = groupBy(expenses, (expense: Expense) => {
        return expense.mileage ? 'Mileage' : 'Reimbursement';
      });

      setExpenses(expensesByType);
    })
    .finally(() => setLoadingExpenses(false));
  }

  function getStatusColor(expense: Expense) {
    switch (expense.status) {
      case PollworkerReimbursementStatus.New:
        return 'bg-blue-50 text-blue-700 ring-blue-600/20';
      case PollworkerReimbursementStatus.Approved:
        return 'bg-green-50 text-green-700 ring-green-600/20';
      case PollworkerReimbursementStatus.Rejected:
        return 'bg-red-50 text-red-700 ring-red-600/20';
    }
  }

  function toggle() {
    loadExpenses();
    setShowSlideout(!showSlideout);
    showSlideout && setExpenseToEdit(undefined);
  }

  const initLoading = loadingExpenses && !Object.keys(expenses).length;

  return (
    <div>
      <PanelHeader title='Expenses' loading={loadingExpenses} reload={loadExpenses} onAdd={() => setShowSlideout(true)} />
      <PanelSlideout show={showSlideout}>
        <PanelSlideoutHeader close={toggle}>
          <div className="space-y-1">
            <SectionSubSubHeading>{expenseToEdit?.id ? 'Edit' : 'Add'} Expense for {pollworker.userInfo.firstName} {pollworker.userInfo.lastName}</SectionSubSubHeading>
            <Flexor justify="start" className="space-x-2" items="center">
              <TicketIcon className="h-4 w-4" />
              <span className="text-xs font-semibold">{moduleInfo?.Election?.ElectionName}</span>
            </Flexor>
          </div>
        </PanelSlideoutHeader>
        <ExpenseEditor toggle={toggle} expense={expenseToEdit} />
      </PanelSlideout>
      {
        initLoading ? (
          <Flexor justify="center" className="space-x-2 mt-10">
            <Spinner show/>
            <SectionSubSubHeading>Loading expenses...</SectionSubSubHeading>
          </Flexor>
        ) : (
          <>
            {
              !Object.keys(expenses).length && !loadingExpenses ? (
                <Flexor justify="center" className="space-x-2 mt-10">
                  <NoSymbolIcon className="h-6 w-6" />
                  <SectionSubSubHeading>No expenses found</SectionSubSubHeading>
                </Flexor>
              ) : null
            }
            <ul className={classNames(!initLoading && loadingExpenses ? 'opacity-50' : '', 'transition-opacity')}>
              {
                Object.keys(expenses).map((expenseType) => {
                  const electionExpenses = expenses[expenseType];

                  return (
                    <li key={expenseType}>
                      <div className="p-2 bg-gray-50 border-b-2 border-gray-100">
                        <SectionSubSubHeading>{expenseType}</SectionSubSubHeading>
                      </div>
                      {
                        electionExpenses.map((expense) => {
                          const expanded = expense.id === expandedExpenseId;
                          const expenseDate = dayjs(expense.expenseDate);

                          return (
                            <dl key={expense.id} className={classNames("hover:bg-gray-100 pb-2 divide-y pl-2")}>
                              <Flexor justify="start" className="space-x-2" items="start">
                                <div className="py-4" onClick={() => setExpandedExpenseId(expanded ? undefined : expense.id)}>
                                  <ChevronRightIcon className={classNames(expanded ? 'rotate-90' : '', "transition-transform h-4 w-4")} />
                                </div>
                                <div className="transition-all w-full">
                                  <div className="w-full flex items-center justify-between py-3 text-sm font-medium">
                                    <dt className="text-sm font-medium text-gray-700 w-full">
                                      <Flexor justify="start" className="space-x-1 w-full truncate break-all">
                                        <BanknotesIcon className="h-4 w-4" />
                                        <div>{currencyFormat(expense.chargeAmount, 'en-US', 'USD', 2)}</div>
                                      </Flexor>
                                      <div className="flex items-center space-x-2 text-xs">
                                        <CalendarDaysIcon className="h-4 w-4" />
                                        <time
                                          className={classNames('text-gray-700', "block font-normal")}
                                          title={expenseDate.format('MM-DD-YYYY hh:MM A')}>
                                          {expenseDate.format('MM-DD-YYYY')}
                                        </time>
                                      </div>
                                      {
                                        expense.mileage ? (
                                          <div className="flex items-center space-x-2 text-xs">
                                            <TruckIcon className="h-4 w-4" />
                                            <span>{expense.mileage} mi → ${(expense.chargeAmount / expense.mileage).toFixed(3)} per mile</span>
                                          </div>
                                        ) : null
                                      }
                                    </dt>
                                    <span className={classNames(getStatusColor(expense), "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset")}>
                                      {PollworkerReimbursementStatus[expense.status]}
                                    </span>
                                    <dd className="text-sm text-gray-900 flex items-center justify-end">
                                      <Menu as="div" className="relative flex-none">
                                        <Menu.Button className="block p-2.5 text-gray-500 hover:text-gray-900">
                                          <span className="sr-only">Open options</span>
                                          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                        </Menu.Button>
                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                        >
                                          <Menu.Items className="absolute right-5 z-10 mt-0 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                            {
                                              expense.status === PollworkerReimbursementStatus.New ? (
                                                <Menu.Item>
                                                  {({ active }) => (
                                                    <a
                                                      href="#"
                                                      className={classNames(
                                                        // active ? 'bg-gray-50 text-green-500' : 'text-gray-800',
                                                        'flex items-center space-x-2 block px-3 py-1 text-sm leading-6'
                                                      )}
                                                      onClick={() => {
                                                        setExpenseToEdit(expense);
                                                        toggle();
                                                      }}
                                                    >
                                                      <PencilSquareIcon className="h-5 w-5" />
                                                      <span data-testid={`edit-expense-${expense.id}`}>Edit<span className="sr-only">, {expense.id}</span></span>
                                                    </a>
                                                  )}
                                                </Menu.Item>
                                              ) : null
                                            }
                                            <Menu.Item disabled={PollworkerReimbursementStatus.Rejected === expense.status}>
                                              {({ active }) => (
                                                <a
                                                  href="#"
                                                  className={classNames(
                                                    active ? 'bg-ev-red text-white' : 'bg-gray-50 text-gray-900',
                                                    'flex items-center space-x-2 block px-3 py-1 text-sm leading-6'
                                                  )}
                                                  onClick={() => window.alert('TODO')}
                                                >
                                                  <TrashIcon className="h-5 w-5" />
                                                  <span>Delete<span className="sr-only">, {expense.id}</span></span>
                                                </a>
                                              )}
                                            </Menu.Item>
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>
                                    </dd>
                                  </div>
                                  {
                                    expanded ? (
                                      <div className="space-y-2">
                                        <div className="text-sm">
                                          <span className="leading-6 text-gray-800 font-semibold">Notes</span>
                                          <div className="border-l-2 border-gray-400 pl-2">{expense.notes}</div>
                                        </div>
                                        <div>
                                          <DeprecatedModal title={'Receipt'} squared open={showExpandedImageId === expense.id} onClose={() => setShowExpandedImageId(undefined)}>
                                            <Suspense fallback={(<Spinner large={false} show />)}>
                                              <BlobImage skipCache customerId={pollworker?.userInfo?.keyCustomerId} entityType={'expenses'} id={`${expense.id}.jpg`} alt="Receipt image for an expense" />
                                            </Suspense>
                                          </DeprecatedModal>
                                          <div>
                                            <span className="text-sm leading-6 text-gray-800 font-semibold">View receipt</span>
                                            {!showSlideout ? (
                                              <Suspense fallback={(<Spinner large={false} show />)}>
                                                <div className="w-20 cursor-zoom-in" onClick={() => setShowExpandedImageId(expense.id)}>
                                                  <BlobImage key={expense.updatedAt} skipCache imageNotFoundFallback={(
                                                    <div className="text-xs">Not found</div>
                                                  )} customerId={pollworker?.userInfo?.keyCustomerId} entityType={'expenses'} id={`${expense.id}.jpg`} alt="Receipt image for an expense" />
                                                </div>
                                              </Suspense>
                                            ) : null }
                                          </div>
                                        </div>
                                      </div>
                                    ) : null
                                  }
                                </div>
                              </Flexor>
                            </dl>
                          )
                        })
                      }
                    </li>
                  )
                })
              }
            </ul>
          </>
        )
      }
    </div>
  )
}
