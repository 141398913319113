import InventoryLevels from "../InventoryLevels";
import {useLocation} from "wouter";

export default function InventoryLevelsDashboard() {
  const [, navigate] = useLocation();

  return (
    <div>
      <InventoryLevels />
    </div>
  );
}
