import React, {useEffect, useState} from 'react';
import { SectionHeading, SectionSubSubHeading } from "shared/src/components/SectionHeading";
import {classNames} from "shared/src/utils/classNames";
import {
  CalendarDaysIcon,
  GlobeAmericasIcon,
  CalculatorIcon,
  IdentificationIcon,
  UserCircleIcon,
  ChartBarIcon,
  ArrowPathIcon,
  Cog8ToothIcon,
  PaperClipIcon,
  NoSymbolIcon,
  CheckCircleIcon,
  ArchiveBoxIcon,
  QuestionMarkCircleIcon,
  ArrowRightIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import {getAllCountyOrders, getStats} from "../../fetchers";
import {useDispatch, useSelector} from "react-redux";
import {Order} from "../../types/Order";
import {getOrders} from "../../selectors";
import DateFormatter from "../../../../shared/src/components/DateFormatter";
import { Flexor } from "shared/src/components";
import {getOrderId, getOrderStatusName, orderStates} from "../../utils/getters";
import ModalPackingList from "../../components/modals/ModalPackingList";
import {Button} from "shared/src/components/ui";
import SlideoutCountySettings from "../../components/slideouts/SlideoutCountySettings";
import {useLocation} from "wouter";
import OrderHistoryTable from "../../components/Dashboard/OrderHistoryTable";
import Loading from "../../../../shared/src/components/Loading";
import {ConsumableItem} from "../../types/ConsumableItem";
import StoreItemImage from "../store/StoreItemImage";
import Tippy from "@tippyjs/react";
import TippyContent from "../../../../shared/src/components/TippyContent";
import Spinner from "../../../../shared/src/components/Spinner";
import { Separator } from "shared/src/components/ui";

export default function Dashboard() {
  const [stats, setStats] = useState<{[key: string]: any}>({});
  const [showPackingListModal, setShowPackingListModal] = useState<boolean>(false);
  const [loadingQuickStats, setLoadingQuickStats] = useState<boolean>(false);
  const [packingListModalOrder, setPackingListModalOrder] = useState<Order>();
  const {data: orders, fetchStatus: ordersStatus} = useSelector(getOrders());
  const [showSlideoutCountySettings, setShowSlideoutCountySettings] = useState(false);
  const [, navigate] = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    reloadOrderData();
    reloadStats();
  }, []);

  function reloadStats() {
    setLoadingQuickStats(true);

    getStats()
    .then(r => {
      const parsedStats = JSON.parse(atob(r.data));

      if (parsedStats.orderCountsByWeek.length >= 2) {
        parsedStats.orderCountsByWeek = {
          negative: parsedStats.orderCountsByWeek[0].orderCount - (parsedStats.orderCountsByWeek[1].orderCount) < 0,
          percentage: Math.round((parsedStats.orderCountsByWeek[0].orderCount / (parsedStats.orderCountsByWeek[1]?.orderCount || 1)) * 100),
          current: parsedStats.orderCountsByWeek[0].orderCount,
        }
      }

      setStats(parsedStats);
    })
    .finally(() => {
      setLoadingQuickStats(false);
    });
  }

  function reloadOrderData() {
    // @ts-ignore
    return dispatch(getAllCountyOrders());
  }

  return (
    <>
      <ModalPackingList
        onClose={() => {
          reloadOrderData();
          setShowPackingListModal(false);
        }}
        isClosed={(closed: boolean) => {
          if (closed) {
            setPackingListModalOrder(undefined);
          }
        }}
        show={showPackingListModal}
        orderId={packingListModalOrder?.id}
      />
      <Flexor>
        <SectionHeading>Dashboard</SectionHeading>
        <Flexor className="space-x-2">
          <Button onClick={() => setShowSlideoutCountySettings(true)}>
            <Cog8ToothIcon className="h-5 w-5 hover:animate-spin" />
            <span>County Settings</span>
            <SlideoutCountySettings show={showSlideoutCountySettings} onClose={() => setShowSlideoutCountySettings(false)} />
          </Button>
        </Flexor>
      </Flexor>
      <br />
      <div>
        <Flexor>
          <SectionSubSubHeading>Quick Stats</SectionSubSubHeading>
          <Button onClick={reloadStats} variant='tertiary' size='zero' className='pr-1'>
            <ArrowPathIcon className={classNames(loadingQuickStats ? 'animate-spin' : '', 'size-4')} />
            <span>Refresh</span>
          </Button>
        </Flexor>
        <dl className={classNames(loadingQuickStats ? 'grid-cols-1' : 'sm:grid-cols-4', 'border border-gray-200 mx-auto grid grid-cols-1 gap-px bg-gray-900/5 rounded-lg overflow-hidden mt-2 bg-white')}>
          {
            loadingQuickStats ? (
              <Loading className="my-10" loadingMessage="Calculating quick stats..." />
            ) : (
              <>
                <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 px-4 py-10 sm:px-6 xl:px-8">
                  <dt className="text-sm font-medium leading-6 text-gray-500 flex items-center space-x-2">
                    <span>Orders this week</span>
                    <Tippy content={(<TippyContent>The number of orders submitted this week</TippyContent>)}>
                      <QuestionMarkCircleIcon className="h-4 w-4" />
                    </Tippy>
                  </dt>
                  {
                    stats.orderCountsByWeek?.current ? (
                      <>
                        <Tippy content={(<TippyContent>Percent different of orders this week verses last week.</TippyContent>)}>
                          <dd
                            className={classNames(
                              stats.orderCountsByWeek.negative ? 'text-rose-600' : 'text-gray-700',
                              'text-xs font-medium block'
                            )}
                          >
                            <>
                              {stats.orderCountsByWeek.negative ? '-' : ''}
                              {stats.orderCountsByWeek.percentage}%
                            </>
                          </dd>
                        </Tippy>
                        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                          {stats.orderCountsByWeek.current}
                        </dd>
                      </>
                    ) : (
                      <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                        0
                      </dd>
                    )
                  }
                </div>
                <div
                  className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8"
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500 flex items-center space-x-2">
                    <span>Open orders</span>
                    <Tippy content={(<TippyContent>The number of orders that have not been fulfilled yet. You can see details in the table below ⬇️</TippyContent>)}>
                      <QuestionMarkCircleIcon className="h-4 w-4" />
                    </Tippy>
                  </dt>
                  <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    {orders.length}
                  </dd>
                </div>
                <div
                  className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8"
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500 flex items-center space-x-2">
                    <span>Active carts</span>
                    <Tippy content={(<TippyContent>The number of orders that have not been submitted yet, but have been updated in the last 24 hours</TippyContent>)}>
                      <QuestionMarkCircleIcon className="h-4 w-4" />
                    </Tippy>
                  </dt>
                  {
                    stats.activeCarts ? (
                      <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                        {stats.activeCarts}
                      </dd>
                    ) : (
                      <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                        {stats.activeCarts}
                      </dd>
                    )
                  }
                </div>
                <div
                  className="flex flex-wrap items-baseline justify-between gap-x-4 bg-white gap-y-2 px-4 py-10 sm:px-6 xl:px-8"
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500 flex items-center space-x-2 w-full">
                    <>
                      <span>Top items</span>
                      <Tippy content={(<TippyContent>This shows the most 5 most popular items from left to right.</TippyContent>)}>
                        <QuestionMarkCircleIcon className="h-4 w-4" />
                      </Tippy>
                    </>
                  </dt>
                  <dd className="w-full flex space-x-2 text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    {
                      stats.top5Items?.map(({inventoryEquipType: item, quantitySum}: {inventoryEquipType: ConsumableItem, quantitySum: number}) => {
                        return (
                          <Tippy key={`top_5_items_${item.id}`} content={(<TippyContent>{item.equipTypeName}</TippyContent>)}>
                            <div className="relative">
                              <StoreItemImage className="h-8 w-8 object-fill rounded-full" item={item} key={`stats_top5_${item.id}`}/>
                              <span className="absolute text-xs scale-90 m-auto font-semibold text-blue-800 pt-[3px] w-full bg-blue-200 rounded-full -bottom-6 h-6 text-center border-blue-800 border">
                                {quantitySum}
                              </span>
                            </div>
                          </Tippy>
                        );
                      })
                    }
                    {
                      stats.top5Items?.length < 5 ? (
                        <span className="block h-8 w-8 flex justify-center items-center block border-2 border-dashed rounded-full border-gray-200 text-gray-400 relative flex flex-col">
                          <ArchiveBoxIcon className="h-4 w-4" />
                        </span>
                      ): null
                    }
                  </dd>
                </div>
              </>
            )
          }
        </dl>
      </div>
      <dl className={classNames('pt-2 flex space-x-4 flex-col sm:mt-1 sm:flex-row sm:flex-wrap')}>
        {
          loadingQuickStats ? (
            <div className="mr-4 ml-1 flex items-center justify-center">
              <Spinner show />
            </div>
          ) : (
            <>
              <Button variant='quaternary' onClick={() => {
                navigate('/inventory?filter=lowInventory')
              }}>
                {
                  stats?.lowInventoryItems?.length ? (
                    <>
                      <ExclamationTriangleIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400"
                        aria-hidden="true"
                      />
                      Some inventory levels are low
                    </>
                  ) : (
                    <>
                      <CheckCircleIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-success"
                        aria-hidden="true"
                      />
                      Inventory levels are healthy
                    </>
                  )
                }
              </Button>
              <Button variant='quaternary' onClick={() => navigate('/inventory?filter=missingReorderThreshold')}>
                {
                  stats?.activeItemsMissingReorderThreshold?.length ? (
                    <>
                      <ExclamationTriangleIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-warning"
                        aria-hidden="true"
                      />
                      Some items missing reorder thresholds
                    </>
                  ) : (
                    <>
                      <CheckCircleIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                        aria-hidden="true"
                      />
                      All reorder thresholds are set
                    </>
                  )
                }
              </Button>
            </>
          )
        }
        <Separator />
        <Button variant='quaternary' onClick={() => navigate('/inventory')}>
          <span>Inventory</span>
          <ArrowRightIcon className="size-4" />
        </Button>
      </dl>

      <div className="flow-root mt-5">
        <Flexor>
          <SectionSubSubHeading>Orders</SectionSubSubHeading>
          <Button onClick={reloadOrderData} variant='tertiary' size='zero' className='pr-1'>
            <ArrowPathIcon className={classNames(ordersStatus.loading ? 'animate-spin' : '', 'size-4')} />
            <span>Refresh</span>
          </Button>
        </Flexor>

        <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    <PaperClipIcon title="Has Notes?" className="h-5 w-5" />
                  </th>
                  <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    <Flexor justify="start" className="space-x-1">
                      <IdentificationIcon className="h-5 w-5" />
                      <span>ID</span>
                    </Flexor>
                  </th>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    <Flexor justify="start" className="space-x-1">
                      <UserCircleIcon className="h-5 w-5" />
                      <span>User</span>
                    </Flexor>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <Flexor justify="start" className="space-x-1">
                      <GlobeAmericasIcon className="h-5 w-5" />
                      <span>County</span>
                    </Flexor>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <Flexor justify="start" className="space-x-1">
                      <CalculatorIcon className="h-5 w-5" />
                      <span>Count</span>
                    </Flexor>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <Flexor justify="start" className="space-x-1">
                      <ChartBarIcon className="h-5 w-5" />
                      <span>Status</span>
                    </Flexor>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <Flexor justify="start" className="space-x-1">
                      <CalendarDaysIcon className="h-5 w-5" />
                      <span>Submit Date</span>
                    </Flexor>
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {
                  ordersStatus.loading && !orders.length ? (
                    <tr>
                      <td colSpan={100} className="bg-white">
                        <div className="flex items-center justify-center my-10">
                          <Loading loadingMessage="Getting active orders..." />
                        </div>
                      </td>
                    </tr>
                  ) : null
                }
                {
                  !ordersStatus.loading && !orders.length ? (
                    <tr>
                      <td colSpan={100} className="bg-white">
                        <div className="flex items-center justify-center my-10">
                          <div>
                            <NoSymbolIcon className="h-12 w-12 mx-auto" />
                            <div>No orders found</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : null
                }
                {
                  orders.map((order: Order) => {
                    const state = orderStates(order);

                    return (
                      <tr key={order.id}>
                        <td title={!!order.notes ? 'This order has notes attached' : ''} className="truncate whitespace-nowrap py-4 text-sm text-gray-500 pl-6">
                          <span>{!!order.notes ? (<PaperClipIcon className="h-5 w-5" />) : null}</span>
                        </td>
                        <td className="truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                          <span className="w-1/6">{getOrderId(order)}</span>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {order.user?.firstName} {order.user?.lastName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{order.customer.customerName}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{order.orderItems.length}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium">{getOrderStatusName(order.status)}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><DateFormatter dateFormats={{ dateStyle: 'short', timeStyle: "short" }} dateString={order.updatedAt} /></td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Button variant='tertiary' size='zero' onClick={async () => {
                            if (!state.canFulfil) return;
                            setShowPackingListModal(true);
                            await reloadOrderData();
                            setPackingListModalOrder(order);
                          }} className={`${classNames(state.canFulfil ? null : 'text-gray-500 cursor-not-allowed')}`}>
                            Start Packing
                            <span className="sr-only">, Start packing order with ID of {order.id}</span>
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="my-10">
          <OrderHistoryTable />
        </div>
      </div>
    </>
  )
}
