import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'wouter';
import {ChartBarSquareIcon, ClipboardDocumentIcon} from '@heroicons/react/24/outline';

import { classNames } from 'shared/src/utils/classNames';
import { Flexor } from 'shared/src/components';
import { selectFeatureFlag } from 'admin/src/selectors/featureFlags';
import { useAppDispatch } from 'admin/src/hooks';
import { checkFeatureFlag } from 'admin/src/fetchers'
import { selectIsStateUser } from 'admin/src/selectors';
import {FeatureFlagCheck} from "../../types/FeatureFlagCheck";

interface NavItem {
  href: string;
  icon: React.ReactNode;
  name: string;
}

const dashboardFeatureFlagName = 'state-inventory-dashboard';

export default function InventoryNavBar() {
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const dispatch = useAppDispatch();
  const isStateUser = useSelector(selectIsStateUser);
  const dashboardFeatureFlag: FeatureFlagCheck = useSelector(selectFeatureFlag(dashboardFeatureFlagName));
  const [location] = useLocation();

  useEffect(() => {
    if (!dashboardFeatureFlag) {
      checkFeatureFlag(dispatch, dashboardFeatureFlagName).then();
    }
  }, []);

  useEffect(() => {
    if (dashboardFeatureFlag && dashboardFeatureFlag.enabled && isStateUser) {
      setNavItems([
        {
          icon: <ChartBarSquareIcon className='size-4' />,
          href: '/dashboard',
          name: 'Dashboard'
        },
        {
          icon: <ClipboardDocumentIcon className='size-4' />,
          href: '/',
          name: 'Reports'
        }
      ]);
    } else {
      setNavItems([{
        icon: <ClipboardDocumentIcon className='size-4' />,
        href: '/',
        name: 'Reports'
      }]);
    }
  }, [isStateUser, dashboardFeatureFlag]);

  return (
    <header className="sticky inset-x-0 top-0 z-30 flex shrink-0 h-14 px-4 border-b border-gray-900/10 bg-white">
      <nav className="px-5 flex justify-start text-sm font-semibold leading-6 text-gray-700 h-full sm:space-x-2 md:space-x-6">
        {
          navItems.map((item) => {
            const active = location === item.href;

            return (
              <Link title={item.href} to={item.href} key={item.name}>
                <div className={classNames(active ? 'border-b-2 border-ev-red' : '', 'flex flex-col items-center justify-center cursor-pointer')}>
                  <Flexor className='space-x-2'>
                    {item.icon}
                    <div>{item.name}</div>
                  </Flexor>
                </div>
              </Link>
            )
          })
        }
      </nav>
    </header>
  )
}
