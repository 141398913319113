import {Menu, Transition} from "@headlessui/react";
import {AdjustmentsVerticalIcon, ChevronUpDownIcon} from "@heroicons/react/24/outline";
import {Fragment, useEffect, useState} from "react";
import {classNames} from "shared/src/utils/classNames";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "admin/src/reducers";
import {defaultSettingsReducer, setPollworkerSettings} from "admin/src/reducers/pollworker/settings";

export default function GridSettingsDropdown() {
  const [activeSettingsCount, setActiveSettingsCount] = useState(0);
  const settings = useSelector((state: AppState) => state.pollworker.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    const alteredSettingsCount = Object.keys(defaultSettingsReducer).filter((key) => !key.startsWith('_')).reduce((acc, settingKey) => {
      // @ts-ignore
      if (defaultSettingsReducer[settingKey] !== settings[settingKey]) acc += 1;
      return acc;
    }, 0);

    setActiveSettingsCount(alteredSettingsCount);
  }, [settings]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center justify-center p-1 h-full text-sm space-x-0.5 text-gray-800 px-2">
          <AdjustmentsVerticalIcon className="h-4 w-4" />
          <span>Settings</span>
          { activeSettingsCount ? (
            <span className="scale-75 inline-flex items-center rounded-full bg-ev-blue px-2 py-1 text-xs font-medium text-blue-200 font-semibold ring-1 ring-inset ring-blue-700/10">
              {activeSettingsCount}
            </span>
          ) : null}
          <ChevronUpDownIcon className="-mr-1 h-4 w-4 text-gray-800" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-[0.25em] w-48 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => dispatch(setPollworkerSettings({showDetailsView: !settings.showDetailsView}))}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm flex items-center space-x-2'
                  )}
                >
                  <div className={classNames(settings.showDetailsView ? 'bg-transparent' : 'bg-blue-800', "h-3 w-3 shrink-0 rounded-full")}></div>
                  <div>{settings.showDetailsView ? 'Hide' : 'Show'} details panel</div>
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={() => dispatch(setPollworkerSettings({openNativeDetailsDialogOnDoubleClick: !settings.openNativeDetailsDialogOnDoubleClick}))}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm flex items-center space-x-2'
                  )}
                >
                  <div className={classNames(settings.openNativeDetailsDialogOnDoubleClick ? 'bg-transparent' : 'bg-blue-800', "h-3 w-3 rounded-full shrink-0")}></div>
                  <div>{settings.openNativeDetailsDialogOnDoubleClick ? 'Disallow' : 'Allow'} details popup</div>
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
