import React, { forwardRef, SelectHTMLAttributes, useEffect, useState } from "react";
import Select, { SelectProps } from "./Select";

type DropdownProps = Omit<{
  classes?: string,
  role?: string,
  emptyPlaceholder: string,
  options: { label: string, value: number | string, disabled?: boolean }[],
  selectedValue?: number | string,
  placeholder?: string,
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
} & SelectHTMLAttributes<HTMLSelectElement>, 'defaultValue'> & SelectProps;

const Dropdown = forwardRef<HTMLSelectElement, DropdownProps>((props, ref) => {
  const [selectedValue, setSelectedValue] = useState<string | number>('');

  useEffect(() => {
    if (!props.options.length) return;

    setSelectedValue(props.selectedValue || '');
  }, [props.options, props.selectedValue]);

  return (
    <Select
      ref={ref}
      id={props.id}
      role={props.role}
      variant={props.variant}
      name={props.name}
      value={selectedValue}
      onChange={(event) => {
        setSelectedValue(event.target.value);
        props.onChange(event);
      }}
      autoFocus={props.autoFocus}
      disabled={props.disabled || !props.options.length}
      className={props.className}
    >
      <option value='' disabled>{props.options.length ? props.placeholder : props.emptyPlaceholder}</option>
      {
        props.options.map(({value, label, disabled}) => {
          return <option key={value} disabled={disabled} value={value}>{label}</option>
        })
      }
    </Select>
  );
});

export default Dropdown;
