import React, { useEffect, useMemo, useState } from "react";
import { LocationAssignments, PartiesByCode } from "./pollworkerVotingLocationDetailsUtils";
import { PollworkerWorkHistory, VotingLocation } from "admin/src/types/Pollworker/PollworkerWorkHistory";
import { useSelector } from "react-redux";
import { getPollworkerSelector } from "admin/src/selectors/pollworker";
import { Button, ButtonGroup, Input, Label, Textarea } from "shared/src/components/ui";
import { BuildingOfficeIcon, ChevronLeftIcon, UserIcon, CakeIcon, SunIcon } from "@heroicons/react/24/outline";
import { SectionSubSubHeading } from "shared/src/components/SectionHeading";
import { classNames } from "shared/src/utils";
import { Flexor, TippyContent } from "shared/src/components";
import { PollworkerRequirements } from "./types";
import { getPollworkersMasterList } from "admin/src/fetchers";
import { moduleInfoSelector } from "admin/src/selectors/pollworker/grid";
import { WorkerStatus } from "admin/src/components/enums/WorkerStatusEnum";
import Tippy from "@tippyjs/react";

const POLLWORKER_EXCLUSION_STATUSES = [WorkerStatus.DO_NOT_USE, WorkerStatus.RETIRED];

type PollworkerMatch = { isAlreadyAssigned: boolean, pollworker: PollworkerWorkHistory | any };
type CategorizedPollworkers = { matched: PollworkerMatch[], unmatched: PollworkerMatch[] };
type PollworkerSlideoutProps = {
  requirements: PollworkerRequirements,
  locationAssignments: LocationAssignments,
  roleInfoId?: string,
  votingLocation?: VotingLocation,
  partiesByCode: PartiesByCode,
  onAdd: (pollworker: PollworkerWorkHistory, startTime: string, endTime: string, notes: string, assignToElection: boolean) => void
}

function locationAssignmentSlotsByEVUserId(locationAssignments: LocationAssignments) {
  return locationAssignments.slots.reduce((acc: {[keyEVUserId: string]: boolean}, slot) => {
    return {...acc, [slot.keyEVUserId]: true};
  }, {});
}

export default function PollworkerVotingLocationPicker({partiesByCode, locationAssignments, onAdd, requirements}: PollworkerSlideoutProps) {
  const {roleInfoId, party} = requirements;
  const [pollworkerToAdd, setPollworkerToAdd] = useState<PollworkerWorkHistory | any>();
  const [startTime, setStartTime] = useState<string>('09:00');
  const [endTime, setEndTime] = useState<string>('23:00');
  const [notes, setNotes] = useState<string>('');
  const [pollworkerType, setPollworkerType] = useState<'assigned' | 'unassigned'>('assigned');
  const [nameFilter, setNameFilter] = useState<string>('');
  const [pollworkerMasterList, setPollworkerMasterList] = useState([]);
  const pollworkers = useSelector(getPollworkerSelector);
  const moduleInfo = useSelector(moduleInfoSelector);

  useEffect(() => {
    if (!moduleInfo?.ElectionId || pollworkerType !== 'unassigned') return;

    getPollworkersMasterList().then((resp) => {
      setPollworkerMasterList(JSON.parse(atob(resp.data)));
    });
  }, [pollworkerType, moduleInfo]);

  const isSelectedPollworkerAssignedToElection = useMemo(() => {
    return !!pollworkers.find((pw: PollworkerWorkHistory) => pollworkerToAdd?.keyEVUserId === pw?.keyEVUserId);
  }, [pollworkerToAdd, pollworkers]);

  const allPollworkerSearch = useMemo((): CategorizedPollworkers => {
    if (pollworkerType !== 'unassigned') return { matched: [], unmatched: [] };

    const assignmentsByPollworkerId = locationAssignmentSlotsByEVUserId(locationAssignments);

    return pollworkerMasterList.reduce((acc: CategorizedPollworkers, pollworker: any) => {
      let match = true;
      if (POLLWORKER_EXCLUSION_STATUSES.includes(pollworker.status)) return acc;
      if (pollworker.roleInfo?.id !== roleInfoId) match = false;
      if (party && pollworker.party !== party.partyCode) match = false;

      if (nameFilter) {
        const fullName = [
          pollworker.userInfo.firstName,
          pollworker.userInfo.lastName,
        ].map(n => n.toLowerCase()).join(' ');

        match = fullName.includes(nameFilter.toLowerCase());
        if (!match) return acc;
      }

      acc[match ? 'matched' : 'unmatched'].push({
        pollworker,
        isAlreadyAssigned: assignmentsByPollworkerId[pollworker.keyEVUserId],
      });

      return acc;
    }, {matched: [], unmatched: []});
  }, [pollworkerType, requirements, pollworkerMasterList, nameFilter]);

  const assignedPollworkerSearch = useMemo((): CategorizedPollworkers => {
    if (pollworkerType !== 'assigned') return {matched: [], unmatched: []};

    const assignmentsByPollworkerId = locationAssignmentSlotsByEVUserId(locationAssignments);

    return pollworkers.reduce((acc: CategorizedPollworkers, pollworker: PollworkerWorkHistory) => {
      let match = true;
      if (POLLWORKER_EXCLUSION_STATUSES.includes(pollworker.status)) return acc;
      if (pollworker.roleInfo?.id !== roleInfoId) match = false;
      if (party && pollworker.pollworkerInfo.party !== party.partyCode) match = false;

      if (nameFilter) {
        const fullName = [
          pollworker.pollworkerInfo.userInfo.firstName,
          pollworker.pollworkerInfo.userInfo.lastName
        ].map(n => n.toLowerCase()).join(' ');

        match = fullName.includes(nameFilter.toLowerCase());
        if (!match) return acc;
      }

      acc[match ? 'matched' : 'unmatched'].push({
        pollworker,
        isAlreadyAssigned: assignmentsByPollworkerId[pollworker.keyEVUserId]
      });

      return acc;
    }, {matched: [], unmatched: []});
  }, [pollworkerType, requirements, pollworkers, nameFilter]);

  return (
    <div>
      {
        pollworkerToAdd ? (
          <div>
            <Flexor className="shadow p-2 space-x-1">
              <button className="flex justify-start items-center" onClick={() => setPollworkerToAdd(undefined)}>
                <ChevronLeftIcon className="size-4"/>
                <SectionSubSubHeading>Back</SectionSubSubHeading>
              </button>
              <Flexor>
                <SectionSubSubHeading>{[pollworkerToAdd.userInfo.firstName, pollworkerToAdd.userInfo.lastName].join(' ')}</SectionSubSubHeading>
                {
                  pollworkerToAdd.pollworkerInfo?.party || pollworkerToAdd.party ? (
                    <div className="px-1 py-0.5 bg-gray-50 ml-2 rounded text-xs border">
                      {partiesByCode[pollworkerToAdd.pollworkerInfo?.party || pollworkerToAdd.party].partyName}
                    </div>
                  ) : null
                }
              </Flexor>
            </Flexor>
            <div className="space-y-4 p-2">
              <Flexor className="space-x-0">
                <div>
                  <Label htmlFor="start_time">Start time</Label>
                  <Input
                    type="time"
                    id="start_time"
                    defaultValue={startTime}
                    onChange={({ target: { value } }) => setStartTime(value)}
                  />
                </div>
                <div>
                  <Label htmlFor="end_time">End time</Label>
                  <Input
                    type="time"
                    id="end_time"
                    defaultValue={endTime}
                    onChange={({ target: { value } }) => setEndTime(value)}
                  />
                </div>
              </Flexor>
              <div>
                <Label htmlFor="notes">Notes</Label>
                <Textarea
                  value={notes}
                  id="notes"
                  placeholder="Add some notes for this schedule..."
                  onChange={({ target: { value } }) => setNotes(value)}
                />
              </div>
            </div>
            <div className="flex justify-end sticky bottom-0 p-2">
              <Button onClick={() => onAdd(pollworkerToAdd, startTime, endTime, notes, !isSelectedPollworkerAssignedToElection)}>{isSelectedPollworkerAssignedToElection ? 'Save' : 'Assign to Election & Save' }</Button>
            </div>
          </div>
        ) : (
          <>
            <div className="shadow p-2 mb-2 space-y-2 bg-white sticky z-50 top-0">
              <SectionSubSubHeading>Select a Poll Worker</SectionSubSubHeading>
              <Input onChange={(event) => setNameFilter(event.target.value)} placeholder="Search by name..."/>
              <ButtonGroup className='w-full'>
                <Button onClick={() => setPollworkerType('assigned')} variant={pollworkerType === 'unassigned' ? 'secondary' : 'default'} className='w-1/2'>Election</Button>
                <Button onClick={() => setPollworkerType('unassigned')} variant={pollworkerType === 'unassigned' ? 'default' : 'secondary'} className='w-1/2'>All</Button>
              </ButtonGroup>
            </div>
            <>
              {
                pollworkerType === 'assigned' ? (
                  <>
                    {
                      Object.entries(assignedPollworkerSearch).map(([type, pollworkers]) => {
                        return (
                          <div key={`election_${type}`}>
                            <SectionSubSubHeading className="capitalize bg-gray-100 sticky top-0 px-3 py-1.5">{type}</SectionSubSubHeading>
                            <div className="divide-y">
                              {
                                pollworkers.map(({
                                  pollworker,
                                  isAlreadyAssigned,
                                }: PollworkerMatch) => {
                                  const fullName = [pollworker.pollworkerInfo.userInfo.firstName, pollworker.pollworkerInfo.userInfo.lastName].join(' ');

                                  // console.log(`election-${type}-${pollworker.id}`);

                                  return (
                                    <div key={pollworker.id} data-testid={`election-${type}-${pollworker.id}`} className="min-h-16 px-3 py-1.5 text-sm flex justify-between items-center w-full">
                                      <div className={classNames(isAlreadyAssigned ? 'text-ev-red' : '', 'space-y-1')}>
                                        <div className="flex items-center space-x-1">
                                          <UserIcon className="size-4"/>
                                          <span>{fullName}</span>
                                          {
                                            pollworker.pollworkerInfo.earlyVotingWorker ? (
                                              <Tippy content={<TippyContent>Early Voting Worker</TippyContent>}>
                                                <SunIcon className='size-4' />
                                              </Tippy>
                                            ) : null
                                          }
                                        </div>
                                        <Flexor justify='start' className='space-x-1'>
                                          <BuildingOfficeIcon className="size-4"/>
                                          <span>{pollworker.locationInfo?.locationName || 'No location'}</span>
                                        </Flexor>
                                        <Flexor justify='start' className='space-x-1'>
                                          <UserIcon className="size-4"/>
                                          <span>{pollworker.roleInfo?.roleName || 'No role'}</span>
                                        </Flexor>
                                        <Flexor justify='start' className='space-x-1'>
                                          <CakeIcon className="size-4"/>
                                          <span>{partiesByCode[pollworker.pollworkerInfo.party]?.partyName || 'No party'}</span>
                                        </Flexor>
                                      </div>
                                      <div className='flex flex-col items-center justify-between h-full'>
                                        <Button disabled={isAlreadyAssigned} onClick={() => setPollworkerToAdd(pollworker)}>{isAlreadyAssigned ? 'Assigned' : 'Assign'}</Button>
                                      </div>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </div>
                        );
                      })
                    }
                  </>
                ) : (
                  <>
                    {
                      Object.entries(allPollworkerSearch).map(([type, pollworkers]) => {
                        return (
                          <div key={`master_${type}`}>
                            <SectionSubSubHeading className="capitalize bg-gray-100 sticky top-0 px-3 py-1.5">{type}</SectionSubSubHeading>
                            <div className="divide-y">
                              {
                                pollworkers.map(({
                                  pollworker,
                                  isAlreadyAssigned
                                }: PollworkerMatch) => {
                                  return (
                                    <div key={pollworker.id} className="min-h-16 px-3 py-1.5 text-sm flex justify-between items-center w-full">
                                      <div className={classNames(isAlreadyAssigned ? 'text-ev-red' : '', 'space-y-1')}>
                                        <div className="flex items-center">
                                          <UserIcon className="size-4"/>
                                          <span>{[pollworker.userInfo.firstName, pollworker.userInfo.lastName].join(' ')}</span>
                                        </div>
                                        <div className="flex items-center">
                                          <BuildingOfficeIcon className="size-4"/>
                                          <span>{pollworker.defaultLocationInfo?.locationName || 'No location'}</span>
                                        </div>
                                        <div className="flex items-center">
                                          <UserIcon className="size-4"/>
                                          <span>{pollworker.roleInfo?.roleName || 'No role'}</span>
                                        </div>
                                        <div className="flex items-center">
                                          <CakeIcon className="size-4"/>
                                          <span>{partiesByCode[pollworker.party]?.partyName || 'No party'}</span>
                                        </div>
                                      </div>
                                      <div className="flex flex-col items-center justify-between h-full">
                                        <Button disabled={isAlreadyAssigned} onClick={() => setPollworkerToAdd(pollworker)}>{isAlreadyAssigned ? 'Assigned' : 'Assign'}</Button>
                                      </div>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </div>
                        );
                      })
                    }
                  </>
                )
              }
            </>
          </>
        )
      }
    </div>
  )
}
