export default function Nomenclature() {
  // Get data
  // http://localhost:3000/tables/Customer/GetCustomer?id=4dcb5f132f56471c89f00f2d2e423bed
  // http://localhost:3000/api/NomenclatureDefinition/LoadNomenclatureList
  // TODO: This is a table api, so likely you will NOT be able to remove the customer id from the url :(

  return (
    <div>Nomenclature</div>
  )
}
