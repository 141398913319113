import React, { useEffect, useState } from "react";
import { getForms } from "../../../fetchers/setupAndAdmin";
import { classNames } from "shared/src/utils/classNames";
import { Link } from "wouter";
import dayjs from "dayjs";
import { Heading, SectionSubSubHeading, Text } from "shared/src/components/SectionHeading";
import { Flexor } from "shared/src/components";
import { CalendarDaysIcon, UserIcon } from "@heroicons/react/24/outline";
import { FormStatus } from "shared/src/enums/FormStatus";
import { Button } from "shared/src/components/ui";

export default function Forms() {
  const [forms, setForms] = useState<{[key: string]: any}>({});

  useEffect(() => {
    getForms()
    .then((resp) => JSON.parse(atob(resp.data)))
    .then((forms) => {
      const parsedForms = forms.map((f: any) => {
        return {
          ...f,
          createdAt: dayjs(f.createdAt).format('MM-DD-YYYY hh:mm A'),
          updatedAt: dayjs(f.updatedAt).format('MM-DD-YYYY hh:mm A'),
        }
      }).reduce((acc: { [key: string]: any }, form: any) => {
        acc[form.typeName] = form;
        return acc;
      }, {});

      setForms(parsedForms);
    });
  }, []);

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <Heading size={4} className="my-4 border-b pb-4">Forms</Heading>
        {Object.keys(forms).map((formTypeName: string) => {
          const form = forms[formTypeName];
          const isPublished = form.status === FormStatus.Published;
          const publishedBy = isPublished ? form.publishedBy : form.parent?.publishedBy;
          const updatedAtFormatted = dayjs(form.updatedAt).format('MMMM D, YY hh:mm A');

          return (
            <div key={form.id}>
              <Flexor>
                <Heading size={5}>{formTypeName}</Heading>
                <div>
                  <Flexor justify='end' className='space-x-2'>
                    {
                      publishedBy ? (
                        <>
                          <UserIcon className='h-4 w-4' />
                          <SectionSubSubHeading>{publishedBy.firstName} {publishedBy.lastName}</SectionSubSubHeading>
                        </>
                      ) : (
                        <SectionSubSubHeading className='italic font-normal'>Never published</SectionSubSubHeading>
                      )
                    }
                  </Flexor>
                  <Flexor className='space-x-2'>
                    <CalendarDaysIcon className='h-4 w-4' />
                    <Text>
                      <time dateTime={form.updatedAt}>{updatedAtFormatted}</time>
                    </Text>
                  </Flexor>
                </div>
              </Flexor>
              <div className='bg-white rounded-md my-2 shadow'>
                <ul role="list" className="divide-y divide-gray-100 px-4">
                  <li className="flex items-center justify-between gap-x-6 py-5">
                    <div className="min-w-0">
                      <Flexor className="gap-x-3">
                        <p className="text-sm font-semibold leading-6 text-gray-900">{form.title}</p>
                        <span
                          className={classNames(isPublished ? 'text-green-700 bg-green-50 ring-green-600/20' : 'text-orange-700 bg-orange-50 ring-orange-600/20', "capitalize rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset")}>
                          {form.status}
                        </span>
                      </Flexor>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                      <Link to={`/forms/${form.id}`}>
                        <Button>{isPublished ? 'Open' : 'Edit'}</Button>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
