import { PollworkerWorkHistory } from "../../../../types/Pollworker/PollworkerWorkHistory";
import { differenceInMinutes, format, parse } from "date-fns";
import { createPollworkerSchedule } from "../../../../fetchers";

export async function addToSchedule(
  votingLocationId: string,
  electionId: string,
  roleInfoId?: string,
  scheduleDate?: string,
  pollworker?: PollworkerWorkHistory,
  startTime: string = '09:00 AM',
  endTime: string = '11:00 PM',
  notes: string = ''
) {
  if (!roleInfoId || !scheduleDate || !pollworker) return Promise.resolve();

  const startTimeDate = parse(startTime, 'HH:mm', new Date());
  const endTimeDate = parse(endTime, 'HH:mm', new Date());
  const difference = differenceInMinutes(endTimeDate, startTimeDate);

  if (difference < 0) return;

  const payload = {
    workDate: scheduleDate,
    startTime: format(startTimeDate, 'hh:mm aa'), // "09:00 AM",
    endTime: format(endTimeDate, 'hh:mm aa'),
    keyRoleId: roleInfoId,
    keyEVUserId: pollworker.keyEVUserId,
    keyElectionId: electionId,
    keyVotingLocationId: votingLocationId,
    notes,
  };

  return createPollworkerSchedule(payload);
}
