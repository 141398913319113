import {SimpleTippyContent} from "shared/src/components/TippyContent";
import React from "react";
import {
  AcademicCapIcon,
  CheckIcon,
  ClipboardDocumentListIcon,
  MinusCircleIcon,
  NoSymbolIcon, QuestionMarkCircleIcon
} from "@heroicons/react/24/outline";
import {WorkerStatus} from "admin/src/components/enums/WorkerStatusEnum";

export const workerStatusContent = (status: number) => {
  let content, icon;

  switch (status) {
    case WorkerStatus.ACTIVE:
      content = 'Active';
      icon = <CheckIcon className="text-green-600 h-5 w-5" />;
      break;
    case WorkerStatus.INACTIVE:
      content = 'Inactive';
      icon = <MinusCircleIcon className="text-blue-400 h-5 w-5" />;
      break;
    case WorkerStatus.RETIRED:
      content = 'Retired';
      icon = <AcademicCapIcon className="text-purple-600 h-5 w-5" />;
      break;
    case WorkerStatus.DO_NOT_USE:
      content = 'Do Not Use';
      icon = <NoSymbolIcon className="text-red-600 h-5 w-5" />;
      break;
    case WorkerStatus.INCOMPLETE_REGISTRATION:
      content = 'Incomplete Registration';
      icon = <ClipboardDocumentListIcon className="text-orange-400 h-5 w-5" />;
      break;
    default:
      content = 'Unknown';
      icon = <QuestionMarkCircleIcon className="text-pink-500 h-5 w-5" />;
      break;
  }

  return {content, icon};
}

export default function StatusCellRenderer({params, filterDisplay}: {params: any, filterDisplay?: boolean}) {
  const {icon, content} = workerStatusContent(params.value);

  return (
    <SimpleTippyContent content={content} disabled={filterDisplay}>
      <div className="flex items-center space-x-2">
        <div>{icon}</div>
        {filterDisplay ? <div>{content}</div> : null}
      </div>
    </SimpleTippyContent>
  )
}
