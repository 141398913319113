import {createAction} from "@reduxjs/toolkit";
import {Order} from "../../types/Order";

export const ORDER_ACTION_TYPES = {
  ADD_ORDERS: 'ADD_ORDERS',
  EDIT_ORDER: 'EDIT_ORDER',
  CLEAR_ORDERS: 'CLEAR_ORDERS',
  UPDATE_ORDER: 'UPDATE_ORDER',
  REMOVE_ORDER: 'REMOVE_ORDER',
  LOADED_ORDERS: 'LOADED_ORDERS',
  LOADING_ORDERS: 'LOADING_ORDERS',
  EDIT_ORDER_ITEM: 'EDIT_ORDER_ITEM',
  UPDATE_ORDER_ITEM: 'UPDATE_ORDER_ITEM',
  FAILED_LOADING_ORDERS: 'FAILED_LOADING_ORDERS',
};

type UpdateOrderItemPayload = {
  orderId: string;
  orderItemId: string;
  key: string;
  value: any;
}

export const addOrders = createAction<Order[]>(ORDER_ACTION_TYPES.ADD_ORDERS);
export const loadingOrders = createAction(ORDER_ACTION_TYPES.LOADING_ORDERS);
export const failedLoadingOrders = createAction<string | undefined>(ORDER_ACTION_TYPES.FAILED_LOADING_ORDERS);
export const loadedOrders = createAction(ORDER_ACTION_TYPES.LOADED_ORDERS);
export const updateOrder = createAction<Order>(ORDER_ACTION_TYPES.UPDATE_ORDER);
export const updateOrderItemQuantity = createAction<UpdateOrderItemPayload>(ORDER_ACTION_TYPES.UPDATE_ORDER_ITEM);
