import {CustomerInfo} from "../CustomerInfo";
import {EmployeeInfo} from "../User";

export interface Party {
  "keyCustomerId": string,
  "partyCode": string,
  "partyName": string,
  "useAsUnknown": boolean,
  "createdAt": string,
  "deleted": boolean,
  "id": string,
  "updatedAt": string
}

export interface PollworkerMessage {
  body: string,
  createdById: string,
  creatorInfo?: string,
  dateSent: string,
  from: string,
  lastStatus: string,
  msgStatus: number,
  msgType: number,
  originalMessage?: string,
  subject: string,
  targetId: string,
  targetInfo?: string,
  to: string,
  createdAt: string,
  deleted: boolean,
  id: string,
  updatedAt: string,
}

export interface CustomField {
  description: string,
  keyCustomerId: string,
  moduleId: number,
  objectId: number,
  promptText: string,
  createdAt: string,
  deleted: boolean,
  id: string,
  updatedAt: string,
  dataTypeId: number,
}

export interface RoleInfo {
  "active": boolean,
  "dailyRate": number,
  "drivingReimburseable": boolean,
  "hasReports": boolean,
  "hourlyRate": number,
  "keyCustomerId": string,
  "mileagePayable": boolean,
  "mileageRate": number,
  "notes": string,
  "roleName": string,
  "deleted": boolean,
  "id": string,
}

export interface Precinct {
  "baseId": string,
  "baseInfo": {
    "isTemporary": boolean,
    "keyCustomerId": string,
    "keyVotingLocationId": string,
    "precinctCode": string,
    "precinctName": string,
    "precinctType": string,
    "voterCount": number,
    "votersInLineCount": number,
    "waitTimePerMachine": number,
    "createdAt": string,
    "deleted": boolean,
    "id": string,
    "updatedAt": string,
  },
  "customerInfo": CustomerInfo,
  "isTemporary": boolean,
  "keyCustomerId": string,
  "keyElectionId": string,
  "keyVotingLocationId": string,
  "locationInfo": VotingLocation,
  "pollworkerManagerEVUserId": string,
  "pollworkerManagerInfo": any,
  "precinctCode": string,
  "precinctName": string,
  "precinctType": string,
  "voterCount": number,
  "votersInLineCount": number,
  "waitTimePerMachine": number,
  "createdAt": string,
  "deleted": boolean,
  "id": string,
  "updatedAt": string,
}

export interface VotingLocation {
  address1: string
  address2: string
  city: string
  contactCellPhone: string
  contactEmail: string
  contactName: string
  contactType: string
  customerInfo: CustomerInfo
  distributionCenterKeyId: string
  coreId: string
  keyCustomerId: string
  latitude: number
  locationCode: string
  locationName: string
  longitude: number
  notes: string
  outOfCounty: boolean
  roomNumberName: string
  state: string
  usedForEarlyVoting: boolean
  votingMachineCount: number
  waitTimePerMachine: number
  zip: string
  createdAt: string
  deleted: boolean
  id: string
  updatedAt: string
}

export interface PollworkerRequirement {
  createdById: string,
  createdByInfo: UserInfo,
  customerInfo: CustomerInfo,
  dateCreated: string,
  dateDue: string,
  description: string,
  keyCustomerId: string,
  keyElectionId: string,
  keyEVUserId: string,
  notes: string,
  pollworkerLevel: number,
  requiredObjectId: string,
  requirementType: number,
  satisfiedById?: string,
  status: PollworkerRequirementStatus,
  userInfo: UserInfo,
  deleted: boolean,
  createdAt: string,
  id: string,
  updatedAt: string,
  requirementName?: string,
}

export enum PollworkerRequirementType {
  UnknownOrNotSpecified = 0,
  TrainingClass = 1,
  Task = 2,
  Document = 3,
  Survey = 4,
  CustomRequirement = 5
}

export interface PollworkerRequirementClass {
  updatedAt: string,
  id: string,
  createdAt: string,
  passValue: string,
  notes: string,
  keyEVUserContactId: string,
  keyCustomerId: string,
  className: string,
  certificateOfCompletionTemplateId?: string,
  deleted: boolean,
  prerequisiteId?: string,
  // "contactInfo": Contact
}

export enum PollworkerRequirementStatus {
  NewStatus = 0,
  Completed = 1,
  Canceled = 2,
}

export interface PollworkerDocument {
  customerInfo: CustomerInfo;
  id: string;
  dateCreated: string;
  documentName: string;
  keyCustomerId: string;
  objectId: string;
  ownerId: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  mime?: string;
}

export interface UserInfo {
  _fullAddress?: string,
  "address1": string,
  "address2": string,
  "cellPhone": string,
  "city": string,
  "emailAddress": string,
  "employeeNumber": string,
  "firstName": string,
  "_fullName"?: string,
  "homePhone": string,
  "keyCustomerId": string,
  "lastName": string,
  "loginAttemptCount": number,
  "middleName": string,
  "okToSendSMS": boolean,
  "preferredContactMethod": number,
  "prefix": string,
  "state": string,
  "status": number,
  "suffix": string,
  "userType": number,
  "voterRegistrationNumber": string,
  "zip": string,
  "deleted": boolean,
  "id": string,
  userId: string,
}

export type ScheduleEditorSchedule = {
  startDate: string;
  endDate: string;
  startTime: number;
  workHours: number;
  assignTo: string;
  workplaceId?: string;
  roleId?: string;
}

export interface PollworkerLog {
  creatorInfo: UserInfo;
  createdByUserId: string;
  dateCreated: string;
  keyCustomerId: string;
  keyEVUserId: string;
  notes: string;
  createdAt: string;
  id: string;
  updatedAt: string;
  customerInfo: CustomerInfo;
  pollworkerInfo: EmployeeInfo;
  reasonId: number;
  deleted: boolean;
}

export interface PollworkerContact {
  address1: string,
  address2: string,
  cellPhone: string,
  city: string,
  emailAddress: string,
  firstName: string,
  homePhone: string,
  keyEVUserId: string,
  lastName: string,
  mailingAddress1: string,
  mailingAddress2: string,
  mailingCity: string,
  mailingState: string,
  mailingZip: string,
  prefix: string,
  state: string,
  suffix: string,
  zip: string,
  createdAt: string,
  id: string,
  updatedAt: string,
  contactOrder: number,
  deleted: boolean,
  notes?: string,
  userInfo: Partial<UserInfo>,
}

export interface VotingLocationSchedule {
  electionInfo: ElectionInfo,
  endTime: string,
  keyElectionId: string,
  keyEVUserId: string,
  keyRoleId: string,
  keyVotingLocationId: string,
  locationInfo: VotingLocation,
  notes: string,
  pollworkerInfo: {
    address1: string,
    address2: string,
    cellPhone: string,
    city: string,
    emailAddress: string,
    employeeNumber: string,
    firstName: string,
    homePhone: string,
    keyCustomerId: string,
    lastName: string,
    middleName: string,
    prefix: string,
    state: string,
    suffix: string,
    userId: string,
    voterRegistrationNumber: string,
    zip: string,
    createdAt: string,
    id: string,
    updatedAt: string,
    okToSendSMS: boolean,
    deleted: boolean,
    loginAttemptCount: number,
    preferredContactMethod: number,
    status: number,
    userType: number,
  },
  pollworkerBaseInfo: {
    earlyVotingWorker: boolean,
    electionDayWorker: boolean,
    isAssigned: boolean,
    status: number,
    workerLevel: number,
    deleted: boolean,
    dateAddedToDocket: string,
    dateOfBirth: string,
    party: string,
    id: string,
  },
  roleInfo: RoleInfo,
  status: number,
  userConfirmationStatus: number,
  deleted: boolean,
  startTime: string,
  workDate: string,
  createdAt: string,
  id: string,
  updatedAt: string,
}

export interface Schedule {
  assignedClassReportList: string,
  electionInfo: any,
  startTime: string,
  createdAt: string,
  endTime: string,
  keyElectionId: string,
  keyEVUserId: string,
  keyRoleId: string,
  keyPrecinctId?: string,
  keyVotingLocationId?: string,
  locationInfo: VotingLocation,
  notes: string,
  status: number,
  workDate: string,
  id: string,
  pollworkerInfo: PollworkerWorkHistory,
  pollworkerBaseInfo: PollworkerWorkHistory,
  precinctInfo: Precinct,
  roleInfo: RoleInfo,
  userConfirmationStatusReason?: string,
  userConfirmationStatus?: number,
}

export enum PollworkerTimeClockActionType {
  ClockedOut = 0,
  ClockedIn = 1
}

export type AssignPollworkersToElectionPayload = {
  electionId: string
  pollworkerIdList: string[]
  schedules?: {
    defaultPrecinctSelected: boolean
    endTime: string
    precinctSelected: boolean
    selectedLocationId: string
    selectedPrecinctId?: string
    selectedRoleId?: string
    startTime: string
    userDefaultRole: boolean
    workDates: string[]
  }[]
  selectedLevel: string
  selectedLocationId: string
  selectedPollworkerRoleId: string
  selectedPrecinctId?: string
  sendEmailTemplateId?: string
  useDefaultPollworkerRole: boolean
  useDefaultPrecinct: boolean
}

export interface PollworkerTimeClockAction {
  actionDateTime: string,
  actionSourceType: 'SMS' | 'Android Mobile App' | 'App',
  actionSourceUserId: string,
  actionType: PollworkerTimeClockActionType,
  actionUserInfo: {
    loginAttemptCount: number,
    preferredContactMethod: number,
    status: number,
    userType: number,
    okToSendSMS: boolean,
    deleted: boolean,
  },
  electionInfo: ElectionInfo,
  electionId: string,
  evUserId: string,
  pollWorkerHistoryId: string,
  createdAt: string,
  id: string,
  updatedAt: string,
  userInfo: Partial<UserInfo>,
  deleted: false,
}

export interface PollworkerTimeEntry {
  clockInAction: PollworkerTimeClockAction,
  clockOutAction?: PollworkerTimeClockAction,
  electionId: string,
  electionInfo: ElectionInfo,
  elapsedTime: string,
  workDate: string,
}

export interface PollworkerWorkAssignmentConfirmationsForElection {
  id: string,
  createdAt: Date,
  deleted: boolean,
  electionId: string,
  pollworkerWorkHistoryId: string,
  status: number,
  value: number,
  updatedAt: Date,
}

export interface PollworkerApiResponse {
  workerList: PollworkerWorkHistory[],
  precinctList: Schedule[],
  locationList: Schedule[],
}

export interface PollworkerWorkHistory {
  _customFields: any,
  _workAssignmentConfirmation: PollworkerWorkAssignmentConfirmationsForElection,
  _reportsTo: UserInfo,
  electionDayEndTime?: Date,
  electionDayLocation?: VotingLocation,
  electionDayPrecinct?: Precinct,
  electionDayRole?: RoleInfo,
  electionDaySchedule?: any,
  electionDayStartTime?: Date,
  reportsToId: string,
  assignedToClass: boolean,
  dateAdded: string,
  dateFinalized: string,
  keyElectionId: string,
  keyEVUserId: string,
  keyPollworkerId: string,
  keyPrecinctId?: string,
  keyRoleId: string,
  status: number,
  timeClockStatus: number,
  workerLevel: number,
  electionInfo: ElectionInfo,
  pollworkerInfo: {
    "dateAddedToDocket": string,
    "keyRoleId": string,
    "dateOfBirth": string,
    "earlyVotingWorker": boolean,
    "electionDayWorker": boolean,
    "isAssigned": boolean,
    "gender": string,
    "keyEVUserId": string,
    displayStateId: string,
    "keySecurityLevelId": string,
    "onlineLearningId": string,
    "party": string,
    "title": string,
    "notes": string,
    "status": number,
    "workerLevel": number,
    "userInfo": UserInfo,
    "deleted": boolean,
    "id": string,
    "registeredPrecinctInfo"?: Precinct | any,
  },
  precinctInfo: {
    baseId: string,
    isTemporary: boolean,
    keyCustomerId: string,
    keyElectionId: string,
    keyVotingLocationId: string,
    pollworkerManagerEVUserId: string,
    precinctCode: string,
    precinctName: string,
    precinctType: string,
    voterCount: number,
    votersInLineCount: number,
    waitTimePerMachine: number,
    deleted: boolean,
    id: string,
    defaultAssignedLocationId?: string,
  },
  locationInfo?: VotingLocation,
  roleInfo: RoleInfo,
  userInfo: UserInfo,
  deleted: boolean,
  id: string,
  updatedAt: string,
}

export type ElectionInfo = {
  "actualElectionDate": string,
  "advancedVotingStartDate": string,
  "appCaption": string,
  "currentElection": boolean,
  "dateEnd": string,
  "dateStart": string,
  "electionDate": string,
  "electionName": string,
  "electionTypeId": string,
  "keyCustomerId": string,
  "keyJurisdictionId": string,
  "pollworkerCandidateSubmissionDeadline": string,
  "registrationCutOff": string,
  "deleted": boolean,
  "id": string,
}
