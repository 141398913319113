import {hideModal, MODALS} from "admin/src/actions/modal";
import {AppState} from "admin/src/reducers";
import {useDispatch, useSelector} from "react-redux";
import {
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import React, {Fragment, useEffect, useState} from "react";
import { Flexor } from "shared/src/components"
import { Listbox, Transition } from "@headlessui/react";
import {classNames} from "shared/src/utils/classNames";
import {patchBulkUpdatePollworkerDisplayState} from "admin/src/fetchers";
import {Button} from "shared/src/components/ui";
import { Dialog, DialogTitle, DialogContent, DialogFooter } from 'shared/src/components/ui/Dialog';
import Spinner from "shared/src/components/Spinner";
import {displayStatesByIdSelector, getSelectedPollworkers} from "admin/src/selectors/pollworker/grid";
import {PollworkerWorkHistory} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import {ModuleInfoDisplayState} from "admin/src/types/Pollworker/PollworkerModuleInfo";

export default function DialogPollworkerSetDisplayFlags({onSave}: {onSave: () => void}) {
  const open = useSelector((state: AppState) => state.modals.open === MODALS.SET_DISPLAY_FLAGS);
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<PollworkerWorkHistory[]>([]);
  const [selected, setSelected] = useState<string>();
  const [saving, setSaving] = useState<boolean>(false);
  const selectedPollworkers = useSelector(getSelectedPollworkers);
  const displayStates = useSelector((state: AppState) => state.pollworker.moduleInfo?.DisplayStates);
  const displayStatesById = useSelector(displayStatesByIdSelector) || {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) return;

    setFilteredSelectedRows([]);
    setSelected(undefined);
  }, [open]);

  useEffect(() => {
    setFilteredSelectedRows([...selectedPollworkers]);
  }, [selectedPollworkers, open]);

  function removeIndexFromSelectedRows(idx: number) {
    const newFiltered = [...filteredSelectedRows];
    newFiltered.splice(idx, 1);
    setFilteredSelectedRows(newFiltered);
  }

  function hide() {
    dispatch(hideModal(MODALS.SET_DISPLAY_FLAGS));
  }

  function saveDisplayStates() {
    if (!selected) return;

    const pollworkerIds = filteredSelectedRows.map((row) => row.keyPollworkerId);

    setSaving(true);

    patchBulkUpdatePollworkerDisplayState(pollworkerIds, selected)
      .then(({data, success}) => {
        if (success) {
          onSave();
          hide();
        }
      })
      .finally(() => setSaving(false));
  }

  return (
    <Dialog open={open} onOpenChange={hide}>
      <DialogContent className='overflow-visible'>
        <DialogTitle>
          Set Display Flags
        </DialogTitle>
        <div className="mt-5 overflow-visible">
          <Listbox value={selected} onChange={(value) => setSelected(value)}>
            {({ open }) => {
              const {BackColor: backColor, StateName: stateName} = selected ? displayStatesById[selected] : {BackColor: '', StateName: ''};

              return (
                <>
                  <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                    <Flexor>
                      <div>Display States</div>
                      <div onClick={() => setSelected(undefined)}>Clear</div>
                    </Flexor>
                  </Listbox.Label>
                  <div className="relative mt-2">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-ev-red sm:text-sm sm:leading-6">
                      <span className="flex items-center">
                        {
                          selected ? (
                            <div className="flex items-center">
                              <div style={{backgroundColor: `#${backColor}`}} className="p-2"></div>
                              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'capitalize ml-3 block truncate')}>
                                {stateName}
                              </span>
                            </div>
                          ) : (
                            <span className={classNames(selected ? 'ml-3': '', "block truncate")}>Select a Display State</span>
                          )
                        }
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {
                          displayStates?.map((displayState: ModuleInfoDisplayState) => {
                            const {id: key, BackColor: backColor, StateName: stateName} = displayState;

                            return (
                              <Listbox.Option
                                key={key}
                                className={({active}) =>
                                  classNames(
                                    active ? 'bg-ev-red text-white' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                  )
                                }
                                value={key}
                              >
                                {({selected, active}) => (
                                  <>
                                    <div className="flex items-center">
                                      <div style={{backgroundColor: `#${backColor}`}} className="p-4"></div>
                                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'capitalize ml-3 block truncate')}>
                                        {stateName}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-ev-red',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            )
                          }
                        )}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )
            }}
          </Listbox>
        </div>
        <ul className="divide-y divide-gray-100 overflow-y-auto max-h-80">
          <div className="mt-5 text-sm font-medium leading-6 text-gray-900">
            <Flexor>
              <div>Pollworkers</div>
              <div>{filteredSelectedRows.length}</div>
            </Flexor>
          </div>
          {
            filteredSelectedRows.map((row, idx) => {

              const {BackColor: backColor, StateName: stateName} = displayStatesById[row.pollworkerInfo.displayStateId] || {};

              return (
                <li key={`modal:${row.id}`} className="flex items-center justify-between gap-x-6 py-3">
                  <div className="flex min-w-0 gap-x-4">
                    <div className="h-12 w-12 flex-none border border-gray-200 bg-gray-50 flex-col" style={{backgroundColor: `#${backColor}`}}></div>
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {row.pollworkerInfo.userInfo.firstName}
                        {' '}
                        {row.pollworkerInfo.userInfo.lastName}
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">{stateName || 'Unspecified'}</p>
                    </div>
                  </div>
                  <Button onClick={() => removeIndexFromSelectedRows(idx)} className="shadow-none" variant='tertiary'>Remove</Button>
                </li>
              )
            })
          }
        </ul>
        <DialogFooter>
          <Button variant='primary' onClick={saveDisplayStates} disabled={saving}>
            <div className="mx-auto">
              <Flexor justify="center" className="space-x-2">
                <Spinner show={saving} />
                <div className="w-full text-center">Save</div>
              </Flexor>
            </div>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
