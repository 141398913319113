import React, {Suspense, useState} from 'react';
import {SectionHeading, SectionSubHeading, SectionSubSubHeading} from "shared/src/components/SectionHeading";
import {CartItem} from "../../types/CartItem";
import {removeFromCart, submitOrder} from "../../fetchers";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../reducers";
import DateFormatter from "../../../../shared/src/components/DateFormatter";
import {
  ArchiveBoxIcon,
  CalculatorIcon, CalendarDaysIcon, CheckIcon,
  PencilSquareIcon, PhoneIcon,
  ShoppingCartIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { Flexor } from "shared/src/components"
import StoreItemImage, {StoreItemImageFallback} from "../store/StoreItemImage";
import {Button} from "shared/src/components/ui";
import {OrderItem} from "../../types/Order";
import StoreItemAddToCartForm from "../store/StoreItemAddToCartForm";
import {Link} from "wouter";
import ButtonGoToStore from "../../components/ButtonGoToStore";
import {showToast} from "../../actions/toast";
import {classNames} from "shared/src/utils/classNames";
import {OrderConfig} from "../../types/User";

export default function Checkout() {
  const cartItems = useSelector((state: AppState) => state.cart);
  const parentAccount = useSelector((state: AppState) => state.user?.parentAccount);
  const orderConfig = useSelector((state: AppState) => state.user?.orderConfigs);
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<CartItem>();
  const dispatch = useDispatch();

  async function handleSubmitOrder() {
    setLoading(true);
    // @ts-ignore
    await dispatch(submitOrder(notes));
    dispatch(showToast({ content: 'Your order has been submitted!', undoAction: undefined }));
  }

  function printShippingAddress(orderConfig: OrderConfig) {
    return [
      orderConfig.shippingAddress,
      orderConfig.shippingAddress2,
      orderConfig.shippingCity,
      orderConfig.shippingState,
      orderConfig.shippingZipCode,
    ]
    .filter(p => !!p)
    .join(', ');
  }

  function renderCheckout() {
    return (
      <Flexor className="space-x-5 divide-x-2" items="start">
        <div className="w-2/3 overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            { cartItems.map((item: CartItem | OrderItem) => (
              <li key={item.id}>
                <div className="block hover:bg-gray-50">
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="flex min-w-0 flex-1 items-center">
                      <div className="flex-shrink-0">
                        <Suspense fallback={<StoreItemImageFallback />}>
                          <StoreItemImage item={item.inventoryEquipType} className="h-12 w-12 rounded-full" />
                        </Suspense>
                      </div>
                      <div className="min-w-0 flex-1 px-4 grid w-3/4 grid-cols-3 space-x-5 text-md font-bold text-gray-900">
                        <Flexor justify="start" className="text-md text-gray-800">
                          <ArchiveBoxIcon className="mr-1.5 h-6 w-6 flex-shrink-0" aria-hidden="true"/>
                          <span className="truncate">{item.inventoryEquipType.equipTypeName}</span>
                        </Flexor>
                        <Flexor>
                          {
                            itemToEdit && itemToEdit.id === item.id ? (
                              <Flexor justify="start" className="space-x-1">
                                <StoreItemAddToCartForm onItemUpdated={() => setItemToEdit(undefined)} item={itemToEdit.inventoryEquipType} mini />
                                {itemToEdit && <CheckIcon onClick={() => setItemToEdit(undefined)} className="hover:text-green-500 rounded-full active:p-1 active:ring-1 ring-green-500 h-6 w-6 text-gray-400 hover:text-ev-blue" aria-hidden="true" />}
                              </Flexor>
                            ) : (
                              <p onDoubleClick={() => setItemToEdit(item)} className="flex items-center text-md text-gray-800">
                                <CalculatorIcon className="mr-1.5 h-6 w-6 flex-shrink-0" aria-hidden="true" />
                                {item.quantity}
                              </p>
                            )
                          }
                        </Flexor>
                        <Flexor className="hidden md:block">
                          <p title={item.updatedAt} className="text-md flex items-center space-x-1 text-gray-900">
                            <CalendarDaysIcon className="h-6 w-6" />
                            <time dateTime={item.updatedAt}>
                              <DateFormatter dateFormats={{
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit"
                              }} withTime={false} dateString={item.updatedAt} />
                            </time>
                          </p>
                        </Flexor>
                      </div>
                    </div>
                    <Flexor className="space-x-2">
                      {!itemToEdit && <PencilSquareIcon onClick={() => setItemToEdit(item)} className="cursor-pointer h-6 w-6 text-gray-400 hover:text-ev-blue" aria-hidden="true" />}
                      {/* @ts-ignore */}
                      <TrashIcon onClick={() => dispatch(removeFromCart(item))} className="cursor-pointer h-6 w-6 text-gray-400 hover:text-ev-red" aria-hidden="true" />
                    </Flexor>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <Flexor items="end" justify="start" className="flex-col space-y-3 w-1/3 pl-5">
          <div className="w-full space-y-2">
            <SectionSubSubHeading>Submit your order</SectionSubSubHeading>
            <div className="text-sm space-y-2">
              <p>Upon submitting your order, it will be sent to fulfillment. As it progresses from being fulfilled to shipping out you will recieve email notifications about its status.</p>
              <ul className="list-disc ml-4 space-y-2">
                <li>If you need to make changes yourself you can view the order on the <Link className="underline" to="/orders">Orders</Link> page.</li>
                <li>If there are any changes that are made to your order will be notified via email.</li>
              </ul>
            </div>
          </div>
          <textarea
            rows={4}
            name="comment"
            id="comment"
            placeholder="Optional notes about this order"
            className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
            defaultValue={''}
            onChange={({target: {value}}) => setNotes(value)}
          />
          <div className="w-full">
            {
              !!orderConfig ? (
                <div className="space-y-2">
                  <Flexor>
                    <SectionSubSubHeading>Shipping</SectionSubSubHeading>
                    <span className="text-xs text-gray-400">You cannot change the address</span>
                  </Flexor>
                  <div className="text-sm">
                    {printShippingAddress(orderConfig[0])}
                  </div>
                </div>
              ) : (
                <div className="text-ev-red">A shipping address has not been specified. Please enter one in the text box above.</div>
              )
            }
          </div>
          <Flexor justify="start" className="text-xs text-gray-500">
            If you need to contact {parentAccount?.customerName} directly, you may do so via or applicable email
            <PhoneIcon className="h-4 w-4 text-gray-700 mr-1" />
            <a href={`tel:${parentAccount?.phone}`}>{parentAccount?.phone}</a>
          </Flexor>
          <Button disabled={loading} className={classNames(loading ? "bg-gradient-to-br from-ev-red to-ev-blue animate-pulse bg-opacity-75" : "bg-ev-blue", 'text-white w-full')} onClick={handleSubmitOrder}>
            <span className="w-full text-center">{loading ? 'Submitting...' :'Submit'}</span>
          </Button>
        </Flexor>
      </Flexor>
    )
  }

  return (
    <>
      <SectionHeading>Checkout</SectionHeading>
      <br />
      {
        cartItems.length ? renderCheckout() : (
          <div className="space-y-2 rounded-lg border-2 border-dashed border-gray-500 h-96 w-full flex flex-col items-center justify-center">
            <ShoppingCartIcon className="h-12 w-12" />
            <SectionSubHeading>No cart items</SectionSubHeading>
            <p>You don't have any items in your cart yet.</p>
            <ButtonGoToStore />
          </div>
        )
      }
    </>
  )
}
